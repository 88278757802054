import React, { useContext, useEffect } from 'react';
import { DBContext } from 'hooks/FirebaseContext';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { styled } from '@mui/material/styles';
import { getResultBasedOnSelection } from 'util/calculator';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import './StepFour.css';
import { Box } from '@material-ui/core';

const StepFour = (props) => {
    const theme = useTheme();

    const localFirebaseContext = useContext(DBContext);
    const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const [value, setValue] = React.useState('all');

    useEffect(() => {
        if (value !== localFirebaseContext.resultType) setValue(localFirebaseContext.resultType);
        if (!props.isPlayground && matchesMdDown && value !== 'all') {
            props.smallerMargins(true);
        } else if (!props.isPlayground && matchesMdDown && value === 'all') {
            props.smallerMargins(false);
        }
    }, [localFirebaseContext.resultType]);

    useEffect(() => {
        runCalculations();
    }, [
        localFirebaseContext.resultType,
        localFirebaseContext.selectedTestSpecificity,
        localFirebaseContext.selectedTestSensitivity,
        localFirebaseContext.beforeTestProbability,
        localFirebaseContext.selectedTestId
    ]);
    useEffect(() => {
        if (!props.isPlayground) {
            setValue('');
        }
    }, [localFirebaseContext.diseaseId]);

    const handleChange = (event) => {
        setValue(event.target.value);
        localFirebaseContext.setResultType(event.target.value);
    };
    const runCalculations = () => {
        let localCalculationResults = '';
        const beforeTestProbability = localFirebaseContext.beforeTestProbability;
        const resultType = localFirebaseContext.resultType;
        let test = {
            beforeTestProbability: beforeTestProbability,
            sensitivity: localFirebaseContext.selectedTestSensitivity,
            specificity: localFirebaseContext.selectedTestSpecificity
        };
        console.log('StepFour runCalculations.js', getResultBasedOnSelection);

        localCalculationResults = getResultBasedOnSelection({
            resultType,
            beforeTestProbability,
            ...test
        });
        console.log('test', test);

        console.log('localCalculationResults', localCalculationResults);
        if (!!localCalculationResults.resultProbability) {
            console.log(
                'localCalculationResults.resultProbability',
                localCalculationResults.resultProbability
            );
            localFirebaseContext.setAfterTestProbability(
                Number(localCalculationResults.resultProbability)
            );
        }
    };

    return (
        <Box className='posNegContainer'>
            <ResultTextPropertiesStyling className='resultText'>RESULT</ResultTextPropertiesStyling>
            <FormControlStyling component='fieldset' className='FormControlStyling'>
                <FormLabel component='legend'></FormLabel>
                <RadioGroupStyling
                    aria-label='testResults'
                    name='testResults1'
                    value={value}
                    onChange={handleChange}
                    className='RadioGroupStyling'
                >
                    <FormControlLabelStyling
                        value='positive'
                        className='FormControlRootStyling'
                        control={
                            <RadioRootStyling
                                className='RadioRootStyling'
                                disableRipple
                                color='default'
                                checkedIcon={
                                    <RadioCheckedIconStyling className='RadioCheckedIconStyling-positive' />
                                }
                                icon={<RadioIconStyling className='RadioIconStyling-positive' />}
                            />
                        }
                        label={
                            <TextPropertiesStyling className='textProperties'>
                                If positive
                            </TextPropertiesStyling>
                        }
                    />
                    <FormControlLabelStyling
                        className='formControl'
                        value='negative'
                        control={
                            <RadioRootStyling
                                className='RadioRootStyling'
                                disableRipple
                                color='default'
                                checkedIcon={
                                    <RadioCheckedIconStyling className='RadioCheckedIconStyling-negative' />
                                }
                                icon={<RadioIconStyling className='RadioIconStyling-negative' />}
                            />
                        }
                        label={
                            <TextPropertiesStyling className='textProperties'>
                                If negative
                            </TextPropertiesStyling>
                        }
                    />
                    <Box sx={{ display: props.isPlayground ? 'none' : 'block' }}>
                        <FormControlLabelStyling
                            className='formControl'
                            value='all'
                            control={
                                <RadioRootStyling
                                    className='RadioRootStyling'
                                    disableRipple
                                    color='default'
                                    checkedIcon={
                                        <RadioCheckedIconStyling className='RadioCheckedIconStyling-all' />
                                    }
                                    icon={<RadioIconStyling className='RadioIconStyling-all ' />}
                                />
                            }
                            label={
                                <TextPropertiesStyling className='textProperties'>
                                    if tested 100 patients
                                </TextPropertiesStyling>
                            }
                        />
                    </Box>
                </RadioGroupStyling>
            </FormControlStyling>
        </Box>
    );
};

export default StepFour;

const ResultTextPropertiesStyling = styled('p')(({ theme }) => ({
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 1.4,
    marginBottom: 0,
    paddingLeft: 10,
    color: '#FFFFFF',
    [theme.breakpoints.down('xl')]: {
        fontSize: 14
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: 12,
        fontWeight: 500,
        marginTop: 8
    }
}));
const TextPropertiesStyling = styled('span')(({ theme }) => ({
    color: '#FFFFFF',
    [theme.breakpoints.down('xl')]: {
        fontSize: 16
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: 11,
        marginBottom: 0
    },
    [theme.breakpoints.down('md')]: {
        fontSize: 12,
        marginBottom: 0
    }
}));

const FormControlLabelStyling = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: 20,
    [theme.breakpoints.down('xl')]: {
        marginLeft: 10
    },
    [theme.breakpoints.down('lg')]: {
        marginLeft: 10
    }
}));

const RadioGroupStyling = styled(RadioGroup)(({ theme }) => ({
    flexDirection: 'row',
    height: 26,

    [theme.breakpoints.down('lg')]: {
        marginLeft: 0,
        width: 346
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        height: 26
    }
}));
const FormControlStyling = styled(FormControl)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        width: 346
    }
}));

const RadioIconStyling = styled('span')(({ theme }) => ({
    borderRadius: '50%',

    width: 13,
    height: 13,

    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#FFFFFF',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2
    },
    'input:hover ~ &': {
        backgroundColor: '#98B4B6'
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)'
    }
}));
const RadioCheckedIconStyling = styled('span')(({ theme }) => ({
    backgroundColor: '#00FFF0',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',

        width: 13,
        height: 13,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""'
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3'
    },
    borderRadius: '50%',

    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',

    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)'
    }
}));
const RadioRootStyling = styled(Radio)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('xl')]: {
        padding: 4
    },
    [theme.breakpoints.down('lg')]: {
        padding: 2
    }
}));
const FormControlRootStyling = styled(FormControl)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('xl')]: {
        padding: 4
    },
    [theme.breakpoints.down('lg')]: {
        padding: 2
    }
}));
