import MuiButton from './MuiButton';
import MuiInputBase from './MuiInputBase';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiTouchRipple from './MuiTouchRipple';
import MuiSelect from './MuiSelect';
import MuiCheckbox from './MuiCheckbox';
import MuiTabs from './MuiTabs';
import MuiInputLabel from './MuiInputLabel';
import MuiSlider from './MuiSlider';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiGrid from './MuiGrid';

export default {
    MuiButton,
    MuiInputBase,
    MuiOutlinedInput,
    MuiTouchRipple,
    MuiSelect,
    MuiCheckbox,
    MuiTabs,
    MuiInputLabel,
    MuiSlider,
    MuiFormControlLabel,
    MuiGrid,
};
