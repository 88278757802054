import { Grid, Box } from "@mui/material";
import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import SeparatorLine from "../SeperatorLine/SeparatorLine";
import MediaFloatingBoxTabs from "../../CalculatorComponents/FloaterBoxTabs/MediaFloatingBoxTabs";
import StepFour from "../../CalculatorComponents/CalculatorSteps/StepFour";
import DashboardMatrixIpod from "components/DashboardComponents/DashboardMatrix/DashboardMatrixIpad";
import { DBContext } from "hooks/FirebaseContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const TopSideFloaterMobile = (props) => {
  const theme = useTheme();
  const matchesLgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const localFirebaseContext = useContext(DBContext);

  let imageSize = "21px";
  if (matchesLgDown) {
    imageSize = "14px";
  }
  const matrixText = "IF 100 PATIENTS WERE TESTED";

  return (
    <BarChartsOuterBoxStyled className="BarChartsOuterBoxStyled">
      <Grid>
        <TopLeftSectionBoxStyled className="TopLeftSectionBoxStyled">
          <FloatingTabsBoxStyled>
            <Grid item lg={12} md={12} sm={12}>
              <MediaFloatingBoxTabs
                smallTitle=""
                diseaseNameOrLargeTitle="Chance of Disease"
              ></MediaFloatingBoxTabs>
            </Grid>
          </FloatingTabsBoxStyled>
          <Grid item lg={12} md={12} sm={12}>
            <SeparatorLine marginLeft={0} marginRight={0}></SeparatorLine>
          </Grid>
        </TopLeftSectionBoxStyled>
        <Grid item container lg={12} md={12}>
          <ResultsBoxStyled>
            <Grid item lg={12} md={12} sm={12}>
              <StepFour isPlayground={true}></StepFour>
            </Grid>
          </ResultsBoxStyled>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <SeparatorLine marginLeft={0} marginRight={0}></SeparatorLine>
        </Grid>
        <Grid>
          <DashboardMatrixIpod
            beforeTestProbability={localFirebaseContext.beforeTestProbability}
            sensitivity={localFirebaseContext.selectedTestSensitivity}
            specificity={localFirebaseContext.selectedTestSpecificity}
            imageHeightAndWidth={imageSize}
            isPlayground={props.isPlayground}
            matrixText={matrixText}
            smallerMargins={props.smallerMargins}
          ></DashboardMatrixIpod>
        </Grid>
      </Grid>
    </BarChartsOuterBoxStyled>
  );
};

export default TopSideFloaterMobile;

const BarChartsOuterBoxStyled = styled(Box)(({ theme }) => ({
  width: 390,
  marginRight: "auto",
  marginLeft: "auto",
  height: "100%",
  backgroundColor: "#707070",
}));
const FloatingTabsBoxStyled = styled(Box)(({ theme }) => ({
  width: 390,

  backgroundColor: "#46443D",
  marginRight: "auto",
  marginLeft: "auto",
  borderRadius: "5px 5px 0px 0px",
  marginTop: 10,
  [theme.breakpoints.down("md")]: {
    marginTop: 0,
  },
}));
const TopLeftSectionBoxStyled = styled(Box)(({ theme }) => ({
  marginRight: "auto",
  marginLeft: "auto",
  width: 390,
}));

const ResultsBoxStyled = styled(Box)(({ theme }) => ({
  maxWidth: 480,
  minWidth: 360,
  backgroundColor: "#46443D",
  marginRight: "auto",
  marginLeft: "auto",
  width: "100%",
}));
