import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import {
    PlaygroundDesktop,
    PlaygroundIpad,
    PlaygroundMobile
} from 'components/PlaygroundComponents';
import { useEffect } from 'react';
import { useState } from 'react';

const Playground = (props) => {
    const isXlOnly = useMediaQuery((theme) => theme.breakpoints.only('xl'));
    const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const isMdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [currentPlayground, setCurrentPlayground] = useState(<div />);

    const selectPlayground = () => {
        if (isXlOnly) {
            return (
                <Box
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'none',
                            lg: 'none',
                            xl: 'block'
                        }
                    }}
                >
                    <PlaygroundDesktop {...props} isPlayground={true}></PlaygroundDesktop>
                </Box>
            );
        } else if (isMdUp) {
            return (
                <Box
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'block',
                            lg: 'block',
                            xl: 'none'
                        }
                    }}
                >
                    <PlaygroundIpad {...props} isPlayground={true}></PlaygroundIpad>
                </Box>
            );
        } else if (isMdDown) {
            return (
                <Box
                    sx={{
                        display: {
                            xs: 'block',
                            sm: 'block',
                            md: 'none',
                            lg: 'none',
                            xl: 'none'
                        }
                    }}
                >
                    <PlaygroundMobile {...props} isPlayground={true}></PlaygroundMobile>
                </Box>
            );
        }
    };
    useEffect(() => {
        props.setPageName('Playground');

        setCurrentPlayground(selectPlayground());
    }, [isXlOnly, isMdUp, isMdDown]);
    return <Box>{currentPlayground}</Box>;
};
export default Playground;
