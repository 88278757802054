
export default {
    root: {
        backgroundColor: '#3F3E3B',
    },
    indicator: {
        height: "7px",

    },

};