import './StepPattern.css';
import React, { useEffect, useState, useContext } from 'react';
import { DBContext } from '../../hooks/FirebaseContext';
import StepOne from '../../components/CalculatorComponents/CalculatorSteps/StepOne/StepOne';
import StepTwo from '../../components/CalculatorComponents/CalculatorSteps/StepTwo/StepTwo';

import StepThree from '../../components/CalculatorComponents/CalculatorSteps/StepThree/StepThree';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import AdvancedAndVaccinated from '../../components/CalculatorComponents/AdvancedAndVaccinated/AdvancedAndVaccinated';
import { styled } from '@mui/material/styles';
import LeftSideIntermediate from '../../components/CalculatorComponents/LeftSideIntermediate/LeftSideItermediate';
import Copywrite from 'components/GenericComponents/Copywrite/Copywrite';
const StepPattern = (props) => {
    const localFirebaseContext = useContext(DBContext);
    const theme = useTheme();

    const [isTestSelected, setIsTestSelected] = useState(false);
    const [currentTestName, setCurrentTestName] = useState('');
    const [smallerMargins, setSmallerMargins] = useState(true);

    useEffect(() => {
        if (localFirebaseContext.manualInput) {
            clearListGracefully();
            localFirebaseContext.setStateAndCountyProb(0);
            const checkItem = {
                mathElement: 'Man',
                value: localFirebaseContext.beforeTestProbability,
                id: 'manualInsertion'
            };
            handleSubstractionChange(checkItem);
            handleAdditionChange(checkItem);
        }
    }, [localFirebaseContext.manualInput, localFirebaseContext.beforeTestProbability]);

    const matchesXlDown = useMediaQuery(theme.breakpoints.down('xl'));

    const matchesLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        props.setPageName('Calculator');

        localFirebaseContext.setProbMultiplicationDivisionList([]);
        localFirebaseContext.setProbAdditionSubtractionList([]);
        localFirebaseContext.setStateAndCountyProb(0);
        localFirebaseContext.probAdditionList.current = [];
        localFirebaseContext.probMultiplicationList.current = [];
        setSmallerMargins(true);
    }, [localFirebaseContext.diseaseId]);

    const checkForDuplicateValues = (incomingItem) => {
        let duplicate = false;
        let additionList = localFirebaseContext.probAdditionList.current;
        let multiplicationList = localFirebaseContext.probMultiplicationList.current;

        _.forEach(additionList, (value) => {
            if (value.id === incomingItem.id) {
                duplicate = true;
            }
        });
        _.forEach(multiplicationList, (value) => {
            if (value.id === incomingItem.id) {
                duplicate = true;
            }
        });

        return duplicate;
    };
    const clearListGracefully = () => {
        console.log('clearing list gracefully');
        if (localFirebaseContext.probAdditionList.current !== undefined) {
            let currentProbAdditionListList = localFirebaseContext.probAdditionList.current;
            _.remove(currentProbAdditionListList, (value) => {
                return value.id;
            });
            const newCurrentProbAdditionListListList = [...currentProbAdditionListList];
            localFirebaseContext.setProbAdditionSubtractionList(newCurrentProbAdditionListListList);
        }
        if (localFirebaseContext.probMultiplicationList.current !== undefined) {
            let currentProbMultiplicationListList =
                localFirebaseContext.probMultiplicationList.current;
            _.remove(currentProbMultiplicationListList, (value) => {
                return value.id;
            });
            const newCurrentProbMultiplicationListListList = [...currentProbMultiplicationListList];

            localFirebaseContext.setProbMultiplicationDivisionList(
                newCurrentProbMultiplicationListListList
            );
        }
    };
    const handleAdditionChange = (incomingValue) => {
        if (incomingValue.mathElement !== 'Man') {
            localFirebaseContext.setManualInput(false);
        }

        if (localFirebaseContext.manualInput && incomingValue.mathElement === 'ST') {
            localFirebaseContext.setManualInput(false);
            const checkItem = { mathElement: 'Man', value: 0, id: 'manualInsertion' };
            handleSubstractionChange(checkItem);
        }

        if (checkForDuplicateValues(incomingValue)) {
            handleSubstractionChange(incomingValue);
        }

        let currentList = localFirebaseContext.probAdditionList.current;
        if (currentList !== undefined) {
            currentList = [...currentList, incomingValue];
        } else {
            currentList = incomingValue;
        }
        localFirebaseContext.setProbAdditionSubtractionList(currentList);
    };
    const handleSubstractionChange = (incomingValue) => {
        if (incomingValue.mathElement !== 'Man') {
            localFirebaseContext.setManualInput(false);
        }

        let currentList = localFirebaseContext.probAdditionList.current;
        _.remove(currentList, (value) => {
            return value.id === incomingValue.id;
        });
        const newList = [...currentList];
        localFirebaseContext.setProbAdditionSubtractionList(newList);
    };
    const handleMultiplucationChange = (incomingValue) => {
        localFirebaseContext.setManualInput(false);
        if (checkForDuplicateValues(incomingValue)) {
            handleDivisionChange(incomingValue);
        }
        let currentList = localFirebaseContext.probMultiplicationList.current;

        if (currentList !== undefined) {
            currentList = [...currentList, incomingValue];
        } else {
            currentList = incomingValue;
        }
        localFirebaseContext.setProbMultiplicationDivisionList(currentList);
    };
    const handleDivisionChange = (incomingValue) => {
        localFirebaseContext.setManualInput(false);

        let currentList = localFirebaseContext.probMultiplicationList.current;

        _.remove(currentList, (value) => {
            return value.id === incomingValue.id;
        });

        const newList = [...currentList];

        localFirebaseContext.setProbMultiplicationDivisionList(newList);
    };
    const setTopMargins = () => {
        let margin = '110px';
        if (isTestSelected && smallerMargins) {
            margin = '170px';
        }
        if (isTestSelected && smallerMargins && localFirebaseContext.resultType === 'all') {
            margin = '200px';
        }
        if (isTestSelected && !smallerMargins) {
            margin = '440px';
        }
        return margin;
    };
    const showPosNegBar = (testIsSelected) => {
        setIsTestSelected(testIsSelected);
    };

    const setTestName = (name) => {
        setCurrentTestName(name);
    };

    return (
        <OuterBoxStyling className='OuterBoxStyling'>
            <LeftSideBoxStyling className='LeftSideBoxStyling'>
                <Grid container item className='leftGrid'>
                    <LeftSideIntermediate
                        smallerMargins={setSmallerMargins}
                        diseaseId={localFirebaseContext.diseaseId}
                    ></LeftSideIntermediate>
                </Grid>
            </LeftSideBoxStyling>
            <InnerRightBoxStyling
                className='InnerRightBoxStyling'
                sx={{
                    [theme.breakpoints.down('xl')]: { marginTop: '408px' },
                    [theme.breakpoints.down('lg')]: { marginTop: '330px' },
                    [theme.breakpoints.down('md')]: { marginTop: setTopMargins() }
                }}
            >
                <MainContentGridStyling
                    container
                    item
                    flexirection='row'
                    lg={6}
                    className='MainContentGridStyling'
                >
                    {!matchesXlDown && (
                        <Grid item lg={12} xl={12}>
                            <AdvancedAndVaccinated
                                advanced={localFirebaseContext.showAdvancedButton}
                                vaccinated={localFirebaseContext.showVaccinatedButton}
                            ></AdvancedAndVaccinated>
                        </Grid>
                    )}

                    <XsWidthSettingsBoxStyling item md={12} className='XsWidthSettingsBoxStyling'>
                        <StepOneDivStyling className='StepOneDivStyling'>
                            <StepOne />
                        </StepOneDivStyling>

                        <div className='stepTwo'>
                            <StepTwo
                                currentProb={localFirebaseContext.covidProb}
                                checkedPercentBox={handleAdditionChange}
                                uncheckedPercentBox={handleSubstractionChange}
                                checkedORBox={handleMultiplucationChange}
                                uncheckedORBox={handleDivisionChange}
                                updateStateAndCounty={handleAdditionChange}
                                changedStateAndCounty={handleSubstractionChange}
                            ></StepTwo>
                        </div>
                        <div className='stepThree'>
                            <StepThree
                                showPosNegBar={showPosNegBar}
                                setTestName={setTestName}
                            ></StepThree>
                        </div>
                    </XsWidthSettingsBoxStyling>
                </MainContentGridStyling>
                <CopywriteStyling item container xl={12} lg={12} md={12} sm={12}>
                    <Copywrite longMessage={!matchesXlDown} />
                </CopywriteStyling>
            </InnerRightBoxStyling>
        </OuterBoxStyling>
    );
};

export default StepPattern;
const LeftSideBoxStyling = styled(Box)(({ theme }) => ({
    width: 505,
    [theme.breakpoints.down('xl')]: {
        width: 0
    }
}));
const OuterBoxStyling = styled(Box)(({ theme }) => ({
    width: 1366,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',

    [theme.breakpoints.down('xl')]: {
        width: 820,
        flexDirection: 'column'
    },
    [theme.breakpoints.down('lg')]: {
        width: 734,

        flexDirection: 'column'
    },
    [theme.breakpoints.down('md')]: {
        width: 390,
        minWidth: 348,
        flexDirection: 'column'
    }
}));
const XsWidthSettingsBoxStyling = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: 348
    }
}));
const InnerLeftBoxStyling = styled(Box)(({ theme }) => ({
    maxWidth: '505px',

    marginRight: '10px',

    [theme.breakpoints.down('md')]: {
        position: 'fixed',
        zIndex: 1000,
        width: 390,
        marginRight: 0
    }
}));
const InnerRightBoxStyling = styled(Box)(({ theme }) => ({
    maxWidth: '820px',
    width: '100%',
    flex: 1,
    background: '#FFFFFF',
    marginLeft: '30px',
    marginTop: 20,
    zIndex: 10,
    [theme.breakpoints.down('xl')]: {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    [theme.breakpoints.down('lg')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 734
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 348
    }
}));

const MainContentGridStyling = styled(Grid)(({ theme }) => ({
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.down('xl')]: {
        maxWidth: 740,

        flexWrap: 'unset',
        marginTop: 10
    },
    [theme.breakpoints.down('lg')]: {
        maxWidth: 700,

        flexWrap: 'unset',
        marginTop: 20
    },
    [theme.breakpoints.down('md')]: {
        flexWrap: 'unset',
        marginTop: 20,
        width: 348,
        maxWidth: 348
    }
}));
const StepOneDivStyling = styled('div')(({ theme }) => ({
    paddingBottom: 30,
    marginTop: 50,

    [theme.breakpoints.down('xl')]: {
        marginTop: 10
    },
    [theme.breakpoints.down('md')]: {
        marginTop: 10
    }
}));

const CopywriteStyling = styled(Grid)(({ theme }) => ({
    backgroundColor: '#dce3e3',

    [theme.breakpoints.down('xl')]: {
        height: 20,
        marginTop: 5
    },

    [theme.breakpoints.down('md')]: {
        marginTop: 0,
        height: 30
    }
}));
