import { Grid, Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import SeparatorLine from '../../PlaygroundComponents/SeperatorLine/SeparatorLine';
import MediaFloatingBoxTabs from '../../CalculatorComponents/FloaterBoxTabs/MediaFloatingBoxTabs';
import StepFour from '../../CalculatorComponents/CalculatorSteps/StepFour';
import DashboardMatrixIpod from 'components/DashboardComponents/DashboardMatrix/DashboardMatrixIpad';
import { DBContext } from 'hooks/FirebaseContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const TopSideMobileCalc = (props) => {
    const theme = useTheme();
    const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const localFirebaseContext = useContext(DBContext);

    let imageSize = '21px';
    if (matchesLgDown) {
        imageSize = '14px';
    }
    const matrixText = 'IF 100 PATIENTS WERE TESTED';

    return (
        <BarChartsOuterBoxStyled
            className='BarChartsOuterBoxStyled'
            sx={{
                height:
                    !!localFirebaseContext.selectedTestId &&
                    localFirebaseContext.selectedTestId !== ''
                        ? '172px'
                        : '112px'
            }}
        >
            <Grid>
                <TopLeftSectionBoxStyled className='TopLeftSectionBoxStyled'>
                    <FloatingTabsBoxStyled>
                        <Grid item lg={12} md={12} sm={12}>
                            <MediaFloatingBoxTabs
                                smallTitle='CHANCE OF'
                                diseaseNameOrLargeTitle={props.diseaseName}
                            ></MediaFloatingBoxTabs>
                        </Grid>
                    </FloatingTabsBoxStyled>
                    <Grid item lg={12} md={12} sm={12}>
                        <Box
                            sx={{
                                display:
                                    props.isPlayground ||
                                    (!!localFirebaseContext.selectedTestId &&
                                        localFirebaseContext.selectedTestId !== '')
                                        ? 'block'
                                        : 'none'
                            }}
                        >
                            <SeparatorLine marginLeft={0} marginRight={0}></SeparatorLine>
                        </Box>
                    </Grid>
                </TopLeftSectionBoxStyled>
                <Grid item container lg={12} md={12}>
                    <ResultsBoxStyled>
                        <Grid item lg={12} md={12} sm={12}>
                            <Box
                                sx={{
                                    display:
                                        props.isPlayground ||
                                        (!!localFirebaseContext.selectedTestId &&
                                            localFirebaseContext.selectedTestId !== '')
                                            ? 'block'
                                            : 'none'
                                }}
                            >
                                <StepFour
                                    isPlayground={props.isPlayground}
                                    smallerMargins={props.smallerMargins}
                                ></StepFour>
                            </Box>
                        </Grid>
                    </ResultsBoxStyled>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <SeparatorLine marginLeft={0} marginRight={0}></SeparatorLine>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <Box
                        sx={{
                            display:
                                !!localFirebaseContext.resultType &&
                                localFirebaseContext.resultType === 'all'
                                    ? 'block'
                                    : 'none'
                        }}
                    >
                        <DashboardMatrixIpod
                            beforeTestProbability={localFirebaseContext.beforeTestProbability}
                            sensitivity={localFirebaseContext.selectedTestSensitivity}
                            specificity={localFirebaseContext.selectedTestSpecificity}
                            imageHeightAndWidth={imageSize}
                            isPlayground={props.isPlayground}
                            matrixText={matrixText}
                            smallerMargins={props.smallerMargins}
                            diseaseId={props.diseaseId}
                        ></DashboardMatrixIpod>
                    </Box>
                </Grid>
            </Grid>
        </BarChartsOuterBoxStyled>
    );
};

export default TopSideMobileCalc;

const BarChartsOuterBoxStyled = styled(Box)(({ theme }) => ({
    position: 'fixed',
    left: 0,
    top: 55,
    right: 0,
    width: 390,
    margin: '0 auto',
    zIndex: 1000,
    backgroundColor: '#707070'
}));
const FloatingTabsBoxStyled = styled(Box)(({ theme }) => ({
    width: 390,
    backgroundColor: '#46443D',
    marginRight: 'auto',
    marginLeft: 'auto',
    borderRadius: '5px 5px 0px 0px',
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
        marginTop: 0
    }
}));
const TopLeftSectionBoxStyled = styled(Box)(({ theme }) => ({
    marginRight: 'auto',
    marginLeft: 'auto',
    width: 390
}));

const ResultsBoxStyled = styled(Box)(({ theme }) => ({
    maxWidth: 480,
    minWidth: 360,
    backgroundColor: '#46443D',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%'
}));
