import { Box, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import MatrixGrid from "components/DashboardComponents/MatrixGrid/MatrixGrid";
import MatrixExplanationSectionIpad from "components/PlaygroundComponents/MatrixExplanationSection/MatrixExplantionSectionIpad";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const DashboardMatrixIpod = (props) => {
  const theme = useTheme();
  const matchesLgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);

    props.smallerMargins(expanded);
  };
  let imagePaddingLeft = 6;
  let imagePaddingTop = 2;
  if (matchesLgDown && !matchesMdDown) {
    imagePaddingLeft = 5;
    imagePaddingTop = 1;
  }
  if (matchesMdDown) {
    imagePaddingLeft = 4;
    imagePaddingTop = 1;
  }
  useEffect(() => {
    if (!props.isPlayground && matchesMdDown) {
      setExpanded(true);
    }
  }, [props.diseaseId]);
  return (
    <OuterMatrixBoxStyled className="OuterMatrixBoxStyled">
      <OuterMatrixInnerBoxStyled className="OuterMatrixInnerBoxStyled">
        <Grid item container xl={12} lg={12} md={12} sm={12}>
          <Grid item container xl={12} lg={12} md={12} sm={12}>
            <TitleGridStyled item xl={11} lg={11} md={11} sm={10}>
              <LabelPStyled>{props.matrixText}</LabelPStyled>
            </TitleGridStyled>
            <Box
              className="Arrow"
              sx={{ marginLeft: 37 }}
              display={{
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
                xl: "none",
              }}
            >
              <Grid item xl={1} lg={1} md={1} sm={2}>
                <KeyboardArrowDownIconStyled
                  onClick={handleExpandClick}
                ></KeyboardArrowDownIconStyled>
              </Grid>
            </Box>
          </Grid>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid item container direction="row" xl={12} lg={12} md={12} sm={12}>
              <MatrixExplanationSectionIpad
                {...props}
                className="MatrixExplanationSectionIpad"
              ></MatrixExplanationSectionIpad>
              <MatrixGridBoxStyled className="MatrixGridBoxStyled">
                <MatrixGrid
                  {...props}
                  imagePaddingLeft={imagePaddingLeft}
                  imagePaddingTop={imagePaddingTop}
                ></MatrixGrid>
              </MatrixGridBoxStyled>
            </Grid>
          </Collapse>
        </Grid>
      </OuterMatrixInnerBoxStyled>
    </OuterMatrixBoxStyled>
  );
};

export default DashboardMatrixIpod;
const OuterMatrixBoxStyled = styled(Box)(({ theme }, props) => ({
  backgroundColor: "#46443D",
  maxwidth: 484,
  minWidth: 360,
  borderRadius: 5,

  width: 484,
  height: 334,
  marginTop: 10,
  [theme.breakpoints.down("lg")]: {
    height: 278,
    width: 360,
    marginLeft: 10,
  },
  [theme.breakpoints.down("md")]: {
    width: 390,
    height: "100%",
    marginTop: 0,
    borderRadius: 0,
    marginLeft: 0,
  },
}));
const MatrixGridBoxStyled = styled(Box)(({ theme }) => ({
  marginTop: 8,
  width: 280,
  [theme.breakpoints.down("lg")]: {
    width: 195,
    marginLeft: 10,
    marginTop: 2,
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 0,
  },
}));
const TitleGridStyled = styled(Grid)(({ theme }) => ({
  marginTop: 0,
  marginLeft: 25,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 10,
    marginTop: "-5px",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 5,
    width: 345,
    marginTop: 0,
  },
}));
const KeyboardArrowDownIconStyled = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  color: "#FFFFFF",
  marginTop: 5,
  fontSize: 23,
  float: "right",
}));
const LabelPStyled = styled("p")(({ theme }) => ({
  marginTop: 10,
  marginLeft: 5,
  marginBottom: 0,
  width: 280,
  fontSize: 12,
  fontWeight: 500,
  color: "#FFFFFF",
  [theme.breakpoints.down("lg")]: {
    width: 195,
    marginTop: 10,
    marginLeft: 20,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginTop: 10,
    marginLeft: 10,
  },
}));

const OuterMatrixInnerBoxStyled = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {},
}));
