import React, { useContext } from "react";
import { Box, Grid } from "@material-ui/core";
import ExplanationFragmentPlayground from "components/DashboardComponents/ExplanationFragments/ExplanationFragmentPlayground";
import { getResultBasedOnSelection } from "util/calculator";
import { DBContext } from "hooks/FirebaseContext";
import { styled } from "@mui/material/styles";

import {
  truePositiveImage,
  trueNegativeImage,
  falsePositiveImage,
  falseNegativeImage,
} from "assets";

const MatrixExplanationSectionIpad = (props) => {
  const localFirebaseContext = useContext(DBContext);
  let resultType = localFirebaseContext.resultType;

  const beforeTestProbability = localFirebaseContext.beforeTestProbability;
  let numTruePos = 0;
  let numFalsePos = 0;
  let numTrueNeg = 0;
  let numFalseNeg = 0;
  let test = {
    beforeTestProbability: localFirebaseContext.beforeTestProbability,
    sensitivity: localFirebaseContext.selectedTestSensitivity,
    specificity: localFirebaseContext.selectedTestSpecificity,
  };
  console.log("MatrixExplanationSectiontest", test);

  const getAllResults = () => {
    resultType = "all";
    const allResults = getResultBasedOnSelection({
      resultType,
      beforeTestProbability,
      ...test,
    });

    numTruePos = allResults.numTruePos;
    numFalsePos = allResults.numFalsePos;
    numTrueNeg = allResults.numTrueNeg;
    numFalseNeg = allResults.numFalseNeg;
  };

  const getPosNegResults = () => {
    const negPosValues = getResultBasedOnSelection({
      resultType,
      beforeTestProbability,
      ...test,
    });

    if (resultType === "positive") {
      numTruePos = negPosValues.numTrue;
      numFalsePos = 100 - negPosValues.numTrue;
    } else {
      numTrueNeg = negPosValues.numTrue;
      numFalseNeg = 100 - negPosValues.numTrue;
    }
  };
  if (resultType === "all" || props.isPlayground) {
    getAllResults();
  } else {
    getPosNegResults();
  }

  return (
    <MatrixExplanationSectionOuterBox className="MatrixExplanationSectionOuterBox">
      <Grid item container>
        <ExplanationFragmentPlayground
          number={numTruePos}
          source={truePositiveImage}
          alt={"True Positive"}
          explanation={"Positive with disease"}
          backgroundColor={"numTruePos"}
          underline={true}
        ></ExplanationFragmentPlayground>
      </Grid>
      <Grid item container>
        <ExplanationFragmentPlayground
          number={numFalsePos}
          source={falsePositiveImage}
          alt={"False Positive"}
          explanation={"False Positive"}
          backgroundColor={"numFalsePos"}
          underline={true}
        ></ExplanationFragmentPlayground>
      </Grid>
      <Grid item container>
        <ExplanationFragmentPlayground
          number={numFalseNeg}
          source={falseNegativeImage}
          alt={"False Negative"}
          explanation={"With disease missed"}
          backgroundColor={"numFalseNeg"}
          underline={true}
        ></ExplanationFragmentPlayground>
      </Grid>
      <Grid item container>
        <ExplanationFragmentPlayground
          number={numTrueNeg}
          source={trueNegativeImage}
          alt={"True Negative"}
          explanation={"True negative"}
          backgroundColor={"numTrueNeg"}
          underline={true}
        ></ExplanationFragmentPlayground>
      </Grid>
    </MatrixExplanationSectionOuterBox>
  );
};

export default MatrixExplanationSectionIpad;
const MatrixExplanationSectionOuterBox = styled(Box)(({ theme }) => ({
  marginTop: 10,
  marginBottom: 10,
  width: 188,
  marginLeft: 10,
  [theme.breakpoints.down("xl")]: {
    maxWidth: 188,
    minWidth: 136,
    width: 164,
    marginLeft: 20,
  },
  [theme.breakpoints.down("lg")]: {
    width: 137,
    marginTop: 5,
    marginLeft: 10,
  },
  [theme.breakpoints.down("md")]: {
    width: 160,
    marginTop: 5,
  },
}));
