/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import "../FloaterBox/FloaterBox.css";
import "./FloatingBoxTabs.css";
import { styled } from "@mui/material/styles";

import { DBContext } from "../../../hooks/FirebaseContext";
import { Grid } from "@material-ui/core";

const MediaFloatingBoxTabs = (props) => {
  const localFirebaseContext = useContext(DBContext);

  const [afterTest, setAfterTest] = useState(0);
  const [beforeTest, setBeforeTest] = useState(0);

  useEffect(() => {
    console.log("MediaFloatingBoxTabs: localFirebaseContext.afterTestProbability");

    if (localFirebaseContext.afterTestProbability !== undefined) {
      setAfterTest(
        Number(localFirebaseContext.convertToPercentage(localFirebaseContext.afterTestProbability))
      );
    }
  }, [localFirebaseContext.afterTestProbability]);
  useEffect(() => {
    console.log("MediaFloatingBoxTabs: localFirebaseContext.beforeTestProbability");

    if (localFirebaseContext.beforeTestProbability !== undefined) {
      setBeforeTest(
        Number(localFirebaseContext.convertToPercentage(localFirebaseContext.beforeTestProbability))
      );
    }
  }, [localFirebaseContext.beforeTestProbability]);

  function NameBeforeAndAfterMedia() {
    return (
      <NameBeforeAfterGridStyling
        container
        item
        md={12}
        sm={12}
        xs={12}
        className="NameBeforeAfterGridStyling"
      >
        <Grid item md={6} sm={6} xs={6}>
          <div className="diseaseChanceMobile">
            <LabelTextStyling className="LabelTextStyling">{props.smallTitle}</LabelTextStyling>
            <DiseaseStyling className="DiseaseStyling">
              {props.diseaseNameOrLargeTitle}
            </DiseaseStyling>
          </div>
        </Grid>
        <BothNumbersGridStyling
          container
          item
          md={6}
          sm={6}
          xs={6}
          className="BothNumbersGridStyling"
        >
          <Grid item md={6} sm={6} xs={6}>
            <LeftLineDivStyling className="LeftLineDivStyling"></LeftLineDivStyling>
            <LabelTextStyling className="LabelTextStyling">BEFORE TEST</LabelTextStyling>
            <BeforeAfterNumbersPStyling className="BeforeAfterNumbersPStyling">
              {beforeTest}%
            </BeforeAfterNumbersPStyling>
          </Grid>

          {localFirebaseContext.resultType !== undefined &&
            localFirebaseContext.resultType !== "none" &&
            localFirebaseContext.resultType !== "" &&
            localFirebaseContext.resultType !== "all" && (
              <Grid item md={6} sm={6} xs={6}>
                <LeftLineDivStyling className="LeftLineDivStyling"></LeftLineDivStyling>
                <LabelTextStyling className="LabelTextStyling">AFTER TEST</LabelTextStyling>
                <BeforeAfterNumbersPStyling className="BeforeAfterNumbersPStyling">
                  {afterTest}%
                </BeforeAfterNumbersPStyling>
              </Grid>
            )}
        </BothNumbersGridStyling>
      </NameBeforeAfterGridStyling>
    );
  }

  return <NameBeforeAndAfterMedia></NameBeforeAndAfterMedia>;
};
export default MediaFloatingBoxTabs;
const NameBeforeAfterGridStyling = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  minWidth: "100%",
  backgroundColor: "#46443D",
  height: 110,
  color: "#FFFFFF",
  [theme.breakpoints.down("sm")]: {
    height: "fit-content",
  },
}));
const LabelTextStyling = styled("p")(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Open Sans",
  marginLeft: 10,
  fontWeight: 700,
  marginBottom: 10,
  marginTop: 15,
  [theme.breakpoints.down("md")]: {
    fontWeight: 500,
    marginBottom: 5,
  },
}));
const DiseaseStyling = styled("p")(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 900,
  fontFamily: "Open Sans",
  marginLeft: 10,
  marginTop: 0,
  [theme.breakpoints.down("md")]: {
    fontWeight: 600,
    marginBottom: 5,
  },
}));

const BothNumbersGridStyling = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    height: 82,
  },
}));

const BeforeAfterNumbersPStyling = styled("p")(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 700,
  fontFamily: "Open Sans",
  marginLeft: 10,
  marginTop: 0,
  [theme.breakpoints.down("md")]: {
    fontWeight: 600,
    fontSize: "23px",
  },
}));
const LeftLineDivStyling = styled("div")(({ theme }) => ({
  borderStyle: "none none none solid",
  borderColor: "#C0BFB3",
  borderWidth: 2,
  height: "62px",
  marginTop: 10,
  float: "left",
  marginLeft: 0,
  marginRight: 0,
}));
