import { Grid, Box } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";

import { styled } from "@mui/material/styles";
import PlaygroundBarChartSection from "../PlaygroundBarChartSection/PlaygroundBarChartSection";
import FloatingBoxTabs from "../../CalculatorComponents/FloaterBoxTabs/FloatingBoxTabs";
import SeparatorLine from "../SeperatorLine/SeparatorLine";
import StepFour from "../../CalculatorComponents/CalculatorSteps/StepFour";
import DashboardMatrixIpod from "components/DashboardComponents/DashboardMatrix/DashboardMatrixIpad";
import { DBContext } from "hooks/FirebaseContext";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const TopSideFloater = (props) => {
  const theme = useTheme();
  const matchesLgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  let imageSize = "21px";
  if (matchesLgDown) {
    imageSize = "14px";
  }
  if (matchesMdDown) {
    imageSize = "140px";
  }

  const localFirebaseContext = useContext(DBContext);

  const matrixText = "IF 100 PATIENTS WERE TESTED";

  return (
    <BarChartsOuterBoxStyled className="BarChartsOuterBoxStyled">
      <Grid container direction={"row"} className="OutsideGrid">
        <Grid container item lg={6} md={6} className="LeftSideGrid">
          <LeftSectionBoxStyled className="LeftSectionBoxStyled">
            <Grid container item lg={12}>
              <Grid
                item
                container
                lg={12}
                md={12}
                direction={"column"}
                className="BarChartsLayerThree"
              >
                <FloatingTabsBoxStyled>
                  <Grid item xl={12}>
                    <FloatingBoxTabs
                      smallTitle={props.smallTitle}
                      diseaseNameOrLargeTitle={props.diseaseName}
                      barChartFontSize={props.barChartFontSize}
                    ></FloatingBoxTabs>
                  </Grid>
                  <Grid item xl={12}>
                    <SeparatorLine marginLeft={0} marginRight={0} />
                  </Grid>
                </FloatingTabsBoxStyled>
                <BarGraphBoxStyled className="BarGraphBoxStyled">
                  <Grid item xl={12} className="BarGraphBoxStyledInnerGrid">
                    <PlaygroundBarChartSection
                      className="PlaygroundBarChartSection"
                      barChartFontSize={props.barChartFontSize}
                    ></PlaygroundBarChartSection>
                  </Grid>
                </BarGraphBoxStyled>
              </Grid>

              <Grid item container xl={12} lg={12} md={12}>
                <ResultsBoxStyled
                  sx={{
                    display:
                      props.isPlayground ||
                      (!!localFirebaseContext.selectedTestId &&
                        localFirebaseContext.selectedTestId !== "")
                        ? "block"
                        : "none",
                  }}
                >
                  <StepFour isPlayground={props.isPlayground}></StepFour>
                </ResultsBoxStyled>
              </Grid>
            </Grid>
          </LeftSectionBoxStyled>
        </Grid>
        <Grid item container lg={6} md={6} className="RightSideGrid">
          <RightSectionBoxStyled
            className="RightSectionBoxStyled"
            sx={{
              display:
                props.isPlayground ||
                (!!localFirebaseContext.resultType && localFirebaseContext.resultType === "all")
                  ? "block"
                  : "none",
            }}
          >
            <Grid item container lg={12} md={12} className="GridItemContainer">
              <DashboardMatrixIpod
                beforeTestProbability={localFirebaseContext.beforeTestProbability}
                sensitivity={localFirebaseContext.selectedTestSensitivity}
                specificity={localFirebaseContext.selectedTestSpecificity}
                imageHeightAndWidth={imageSize}
                matrixText={matrixText}
                isPlayground={props.isPlayground}
              ></DashboardMatrixIpod>
            </Grid>
          </RightSectionBoxStyled>
        </Grid>
      </Grid>
    </BarChartsOuterBoxStyled>
  );
};

export default TopSideFloater;

const BarChartsOuterBoxStyled = styled(Box)(({ theme }) => ({
  width: 1024,
  backgroundColor: "#707070",
  marginRight: "auto",
  marginLeft: "auto",
  height: "100%",
  paddingBottom: 20,

  [theme.breakpoints.down("lg")]: {
    width: 764,
    paddingBottom: 0,
  },
}));
const FloatingTabsBoxStyled = styled(Box)(({ theme }) => ({
  width: 480,
  backgroundColor: "#46443D",
  borderRadius: "5px 5px 0px 0px",
  marginTop: 10,

  [theme.breakpoints.down("lg")]: {
    width: 360,
  },
}));
const LeftSectionBoxStyled = styled(Box)(({ theme }) => ({
  marginRight: "auto",
  marginLeft: "auto",
  maxWidth: 482,
  minWidth: 360,
  backgroundColor: "#707070",

  [theme.breakpoints.down("xl")]: {
    width: 480,
    height: 368,
    marginLeft: 10,
  },
  [theme.breakpoints.down("lg")]: {
    height: 310,
    width: 360,

    marginLeft: 10,
  },
}));
const RightSectionBoxStyled = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    height: 368,
    width: 484,
    paddingBottom: 0,
  },
  [theme.breakpoints.down("lg")]: {
    height: 310,
    width: 360,
    paddingBottom: 0,
  },
}));
const BarGraphBoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: "#DCE3E3",
  marginRight: "auto",
  marginLeft: "auto",
  borderRadius: "0px 0px 5px 5px",

  [theme.breakpoints.down("xl")]: {
    maxWidth: 480,
    minWidth: 360,
  },
}));

const ResultsBoxStyled = styled(Box)(({ theme }) => ({
  maxWidth: 480,
  minWidth: 360,
  backgroundColor: "#46443D",
  marginRight: "auto",
  marginLeft: "auto",
  borderRadius: 5,
  marginBottom: 10,
  marginTop: 10,
  width: "100%",
  [theme.breakpoints.down("xl")]: {
    width: 480,
    marginRight: 0,
    marginLeft: 0,
  },
}));
/*

minWidth: 764,
  maxWidth: 1024,

 */
