import React, { useState } from "react";
import { Avatar, Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Typography } from "@material-ui/core";

const StepTitle = (props) => {
  return (
    <GridStepStyling
      container
      sx={{ width: "100%" }}
      className="StepTitleGridWrapper"
    >
      <Grid item lg={1} md={1}>
        <AvatarStyling>{props.number}</AvatarStyling>
      </Grid>
      <MediaMarginsStyling className="mediaMargins">
        <RightSideStyling
          item
          container
          sm={12}
          md={12}
          lg={11}
          className="rightSideTitle"
        >
          <Grid item sm={12} md={12} lg={12}>
            <TopLineStyling className="topLine"></TopLineStyling>
          </Grid>
          <MediaGridOneHundredStyling
            item
            md={12}
            lg={12}
            className="mediaGridOneHundred"
          >
            <StepTitleDivWrapperStyling className="stepOneTitle">
              <TitleStyling className="stepOneTitleP">
                {props.title}
              </TitleStyling>
            </StepTitleDivWrapperStyling>
          </MediaGridOneHundredStyling>
          <Grid item sm={12} md={12} lg={12}>
            <TypographyMessageStyling>{props.message}</TypographyMessageStyling>
          </Grid>
        </RightSideStyling>
      </MediaMarginsStyling>
    </GridStepStyling>
  );
};

export default StepTitle;

const AvatarStyling = styled(Avatar)(({ theme }) => ({
  width: "46px",
  height: "46px",
  float: "left",
  backgroundColor: "#81A2A4",
  fontWeight: 900,
  marginTop: "-5px",
  [theme.breakpoints.down("lg")]: {
    width: "34px",
    height: "34px",
    fontSize: 18,
  },
  [theme.breakpoints.down("md")]: {
    width: "28px",
    height: "28px",
    fontSize: 14,
  },
}));

const TitleStyling = styled("p")(({ theme }) => ({
  marginBottom: 0,
  color: "#262420",
  fontSize: 24,
  marginTop: 5,
  fontWeight: 700,
  float: "left",
  [theme.breakpoints.down("md")]: { fontSize: 14, marginTop: 5 },
}));
const TopLineStyling = styled("div")(({ theme }) => ({
  borderStyle: "solid none none none",
  borderWidth: 2,
  minWidth: "100%",
  [theme.breakpoints.down("sm")]: {
    width: 290,
    marginLeft: 5,
  },
}));

const MediaGridOneHundredStyling = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    minWidth: "100%",
  },
}));

const RightSideStyling = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginRight: 0,
    minWidth: "100%",
  },
}));

const MediaMarginsStyling = styled(Box)(({ theme }) => ({
  width: "100%",
  marginLeft: 20,

  [theme.breakpoints.down("lg")]: {
    marginLeft: 0,
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 8,
    width: 496,
  },
  [theme.breakpoints.down("sm")]: {
    width: 304,
  },
}));
const StepTitleDivWrapperStyling = styled("div")(({ theme }) => ({
  marginBottom: 0,
  color: "#262420",
  fontSize: 24,
  marginTop: 5,
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
    height: 30,
    width: 290,
    marginLeft: 5,
    marginTop: 0,
  },
}));
const GridStepStyling = styled(Grid)(({ theme }) => ({
  flexFlow: "row",

  [theme.breakpoints.down("lg")]: {
    minWidth: "100%",
  },

  [theme.breakpoints.down("sm")]: {
    marginLeft: 8,
  },
}));

const TypographyMessageStyling = styled(Typography)(({ theme }) => ({
  fontSize: 19,
  marginTop: 7,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));
