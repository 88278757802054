const NAN_STRING = '-';

/**
 * Removes trailing zeros from the provided string and returns.
 *
 * @param {string} numStr string to trim zeros from
 * @returns trimmed string
 */


export function trimTrailingZeros(numStr) {
    if (numStr && numStr.endsWith('0') && numStr.indexOf('.') !== -1) {
        let lastIndex = numStr.length - 1;
        while (lastIndex >= 0 && (numStr[lastIndex] === '0' || numStr[lastIndex] === '.')) {
            lastIndex--;
        }
        return lastIndex < 0 ? '0' : numStr.slice(0, lastIndex + 1);
    } else {
        return numStr;
    }
}

export function formatNumber(num, precision) {
    const parsed = parseFloat(num);

    if (!isNumeric(parsed)) {
        return NAN_STRING;
    }

    if (!Number.isInteger(precision)) {
        precision = 2;
        // Prevent >99 from displaying as 1.0e+2
        if (parsed === 100) {
            precision = 3;
        } else if (parsed > 99.9) {
            precision = 4;
        } else if (parsed > 99) {
            precision = 3;
        } else if (parsed < 0.1) {
            precision = 1;
        }
    }

    const prec = Number.parseFloat(parsed).toPrecision(precision);
    console.log("prob formatNumber", num, prec, trimTrailingZeros(prec));

    return trimTrailingZeros(prec);

}


// Can be a string or a number. Prob is a number between 0 and 1
export function formatPct(prob, precision = 3) {

    let num = parseFloat(prob);

    if (Number.isNaN(num)) {
        return `${NAN_STRING}%`;
    } else {
        num = Math.round(num * 100) / 100
    }

    return `${num}%`;
}

export function formatProb(prob, precision = 3) {
    return formatPct(parseFloat(prob) * 100, precision);
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

const PLURALS = {
    'does not have': 'do not have',
    has: 'have',
    is: 'are',
    other: 'others',
    person: 'people',
    tests: 'test',
};

export function pluralise(phrase, num) {
    return num === 1 ? phrase : PLURALS[phrase];
}
