import { ClickAwayListener, Dialog, Menu, MenuItem, Button, Box } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

const LinksMenu = (props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickAway = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <WrapperBoxStyling className="WrapperBoxStyling">
      <ClickAwayListener onClickAway={handleClickAway}>
        <IconButtonStyling
          edge="end"
          aria-label="menu"
          aria-controls="menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MenuIcon />
          {open ? (
            <Dialog open={Boolean(anchorEl)} onClose={handleClose}>
              <Menu
                id="menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getcontentanchorel={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <ButtonStyling onClick={props.clearData} color="primary" href="/">
                    Calculator
                  </ButtonStyling>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ButtonStyling onClick={props.clearData} color="primary" href="/playground">
                    Playground
                  </ButtonStyling>
                </MenuItem>
              </Menu>
            </Dialog>
          ) : null}
        </IconButtonStyling>
      </ClickAwayListener>
    </WrapperBoxStyling>
  );
};

export default LinksMenu;

const IconButtonStyling = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(7),
  color: theme.palette.primary.dark,
  height: 50,
}));
const WrapperBoxStyling = styled(Box)(({ theme }) => ({
  float: "right",
}));

const ButtonStyling = styled(Button)(({ theme }) => ({
  borderWidth: 1,
  borderStyle: " none none solid none",
  borderRadius: 0,
  color: "#3F3E3B",
  fontWeight: 700,
  "&:focus": {
    backgroundColor: theme.palette.primary.main,
  },
  "&:hover": {
    borderRadius: 0,
    boxShadow: "none",

    borderWidth: 5,
    color: theme.palette.secondary.main,
  },
}));
