export default {
    mark: {
        backgroundColor: 'unset',

    },
    markActive: {
        backgroundColor: 'unset',
    },
    markLabel: {
        marginTop: 10,
    }

};
