import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const MessageOnHover = (props) => {
  return (
    <Tooltip disableFocusListener disableTouchListener title={props.title} placement="top">
      <ButtonStyling>{props.shortMessage}</ButtonStyling>
    </Tooltip>
  );
};
export default MessageOnHover;

const ButtonStyling = styled(Button)(({ theme }) => ({
  fontSize: 12,
  color: "#707070",
  fontWeight: 100,
  marginBottom: 0,
  textAlign: "start",
  textTransform: "none",
  [theme.breakpoints.down("md")]: {
    width: 348,
    textAlign: "end",
    padding: 0,
  },
}));
