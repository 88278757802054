import React, { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import ExplanationFragment from "components/DashboardComponents/ExplanationFragments/ExplanationFragment";
import { getResultBasedOnSelection } from "util/calculator";
import { DBContext } from "hooks/FirebaseContext";
import { styled } from "@mui/material/styles";

import {
  truePositiveImage,
  trueNegativeImage,
  falsePositiveImage,
  falseNegativeImage,
} from "assets";

const MatrixExplanationSection = (props) => {
  const localFirebaseContext = useContext(DBContext);
  let resultType = localFirebaseContext.resultType;

  const [beforeTestProbability, setBeforeTestProbability] = useState(
    localFirebaseContext.beforeTestProbability
  );

  useEffect(() => {
    console.log(
      "MatrixExplanationSection: localFirebaseContext.beforeTestProbability"
    );

    setBeforeTestProbability(localFirebaseContext.beforeTestProbability);
  }, [localFirebaseContext.beforeTestProbability]);
  let numTruePos = 0;
  let numFalsePos = 0;
  let numTrueNeg = 0;
  let numFalseNeg = 0;
  let test = {
    beforeTestProbability: beforeTestProbability,
    sensitivity: localFirebaseContext.selectedTestSensitivity,
    specificity: localFirebaseContext.selectedTestSpecificity,
  };

  const getAllResults = () => {
    resultType = "all";
    console.log("forceAllResults", resultType);

    const allResults = getResultBasedOnSelection({
      resultType,
      beforeTestProbability,
      ...test,
    });

    numTruePos = allResults.numTruePos;
    numFalsePos = allResults.numFalsePos;
    numTrueNeg = allResults.numTrueNeg;
    numFalseNeg = allResults.numFalseNeg;
  };

  const getPosNegResults = () => {
    const negPosValues = getResultBasedOnSelection({
      resultType,
      beforeTestProbability,
      ...test,
    });

    if (resultType === "positive") {
      numTruePos = negPosValues.numTrue;
      numFalsePos = 100 - negPosValues.numTrue;
    } else {
      numTrueNeg = negPosValues.numTrue;
      numFalseNeg = 100 - negPosValues.numTrue;
    }
  };

  if (resultType === "all" || props.isPlayground) {
    console.log("Forcing all results", props.isPlayground);
    getAllResults();
  } else {
    console.log("posNeg results", props.isPlayground);

    getPosNegResults();
  }

  return (
    <MatrixExplanationSectionOuterBox className="MatrixExplanationSectionOuterBox">
      <Grid item container>
        <ExplanationFragment
          number={numTruePos}
          source={truePositiveImage}
          alt={"True Positive"}
          explanation={"Positive with disease"}
          backgroundColor={"numTruePos"}
          underline={true}
        ></ExplanationFragment>
      </Grid>
      <Grid item container>
        <ExplanationFragment
          number={numFalsePos}
          source={falsePositiveImage}
          alt={"False Positive"}
          explanation={"False Positive"}
          backgroundColor={"numFalsePos"}
          underline={true}
        ></ExplanationFragment>
      </Grid>
      <Grid item container>
        <ExplanationFragment
          number={numFalseNeg}
          source={falseNegativeImage}
          alt={"False Negative"}
          explanation={"With disease are missed"}
          backgroundColor={"numFalseNeg"}
          underline={true}
        ></ExplanationFragment>
      </Grid>
      <Grid item container>
        <ExplanationFragment
          number={numTrueNeg}
          source={trueNegativeImage}
          alt={"True Negative"}
          explanation={"True negative"}
          backgroundColor={"numTrueNeg"}
          underline={true}
        ></ExplanationFragment>
      </Grid>
    </MatrixExplanationSectionOuterBox>
  );
};

export default MatrixExplanationSection;
const MatrixExplanationSectionOuterBox = styled(Box)(({ theme }) => ({
  marginTop: 10,
  marginBottom: 10,
  width: 188,
  marginLeft: 10,
  [theme.breakpoints.down("xl")]: {
    maxWidth: 188,
    minWidth: 136,
    width: 188,
  },
}));
