export default {
  outlined: {
  },
  select: {
    '&:focus': {
      backgroundColor: 'unset',

    },
  },
};
