import React, { useEffect, useContext, useState } from "react";
import { DBContext } from "../../../../hooks/FirebaseContext";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse, FormControl, Select, MenuItem, OutlinedInput } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";

const DiseaseSelector = () => {
  const localFirebaseContext = useContext(DBContext);
  const [diseaseByGroup, setDiseaseByGroup] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [subheaderOpen, setSubheaderOpen] = useState([]);

  useEffect(() => {
    console.log("DiseaseSelector: localFirebaseContext.diseases");

    if (localFirebaseContext.diseases !== undefined && localFirebaseContext.diseases.length > 0) {
      sortDisease();
    }
  }, [localFirebaseContext.diseases, localFirebaseContext.isReset]);

  const sortDisease = () => {
    let diseaseListByGroup = [];
    let subHeaders = [];
    localFirebaseContext.diseases.forEach((element) => {
      let diseaseList = [];
      diseaseList =
        diseaseListByGroup[element.group] !== undefined ? diseaseListByGroup[element.group] : [];
      diseaseList = [...diseaseList, element];
      diseaseListByGroup[element.group] = diseaseList;
      subHeaders[element.group] = true;
    });

    setSubheaderOpen(subHeaders);
    setDiseaseByGroup(diseaseListByGroup);
    console.log("diseaseListByGroup: ", diseaseListByGroup);
  };

  const selectDisease = (name, id) => {
    localFirebaseContext.selectDisease(name, id);
    setIsOpen(false);
  };

  const openSelector = () => {
    setIsOpen(true);
  };

  const openSubheader = (props) => {
    let selectedSubheader = { ...subheaderOpen };

    let currestSubheaderState = selectedSubheader[props];

    selectedSubheader[props] = !currestSubheaderState;

    setSubheaderOpen(selectedSubheader);
  };

  return (
    <DiseasePickerWrapperDivStyling className="DiseasePickerWrapperDivStyling">
      <FormControlStyling
        variant="outlined"
        className="FormControlStyling"
        sx={{ m: 1, maxHeight: 400 }}
      >
        <Select
          multiple
          displayEmpty
          value={[localFirebaseContext.diseaseName]}
          input={<OutlinedInput />}
          onOpen={openSelector}
          onClose={() => {
            setIsOpen(false);
          }}
          open={isOpen}
          MenuProps={{
            PaperProps: { sx: { maxHeight: 400 } },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getcontentanchorel: null,
          }}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Select Disease</em>;
            }

            return selected.join(", ");
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="" key="SelectDisease">
            <em>Select Disease</em>
          </MenuItem>
          {Object.keys(diseaseByGroup).map((k) => (
            // eslint-disable-next-line react/jsx-key
            <Box key={"" + k + "box"}>
              <MenuItem
                value={k}
                key={k}
                onClick={() => {
                  openSubheader(k);
                }}
              >
                <CatergoriesPStyling className="CatergoriesPStyling">
                  {k.toUpperCase()}
                </CatergoriesPStyling>
                {subheaderOpen[k] ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              {Object.values(diseaseByGroup[k]).map((v) => (
                // eslint-disable-next-line react/jsx-key
                <Collapse
                  in={subheaderOpen[k]}
                  timeout="auto"
                  unmountOnExit
                  key={"" + k + "collapse" + v.id}
                >
                  <MenuItem
                    key={v.id}
                    value={v.name}
                    onClick={() => {
                      selectDisease(v.name, v.id);
                    }}
                  >
                    {v.name}
                  </MenuItem>
                </Collapse>
              ))}
            </Box>
          ))}
        </Select>
      </FormControlStyling>
    </DiseasePickerWrapperDivStyling>
  );
};

const DiseasePickerWrapperDivStyling = styled("div")(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: "bold",
}));

const FormControlStyling = styled(FormControl)(({ theme }) => ({
  maxHeight: 400,
}));

const CatergoriesPStyling = styled("p")(({ theme }) => ({
  margin: "0px",
  fontSize: "14px",
  fontWeight: 900,
  color: "#2B4F53",
}));

export default DiseaseSelector;
