import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import { DBContext } from "../../../hooks/FirebaseContext";
import { Box } from "@material-ui/core";

const ProbabilityBox = () => {
  const localFirebaseContext = useContext(DBContext);
  const [localValue, setLocalValue] = useState(0);
  const [showProbabiltyBox, setShowProbabilityBox] = useState(false);

  const updateValue = (event) => {
    let manualValue = event.target.value;
    if (manualValue === "") {
      setLocalValue("");
    } else if (manualValue % 1 !== 0) {
      var re = manualValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      manualValue = parseFloat(re);
    } else {
      manualValue = parseInt(manualValue);
    }

    if ((manualValue <= 101 && manualValue >= 0) || manualValue === "") {
      setLocalValue(manualValue);
      if (manualValue !== "") {
        localFirebaseContext.inputManualProbability(manualValue / 100);
      } else {
        localFirebaseContext.inputManualProbability(0);
      }
    }
    localFirebaseContext.setManualInput(true);
  };

  useEffect(() => {
    console.log("ProbabilityBox: localFirebaseContext.diseaseId");

    if (localFirebaseContext.diseaseId != undefined) {
      setShowProbabilityBox(true);
    }
  }, [localFirebaseContext.diseaseId]);

  useEffect(() => {
    console.log("ProbabilityBox: localFirebaseContext.beforeTestProbability");

    if (
      localFirebaseContext.beforeTestProbability != undefined &&
      localFirebaseContext.convertToPercentage(localFirebaseContext.beforeTestProbability) !=
        localValue
    ) {
      setLocalValue(
        localFirebaseContext.convertToPercentage(localFirebaseContext.beforeTestProbability)
      );
    }
  }, [localFirebaseContext.beforeTestProbability]);

  return (
    <div>
      {showProbabiltyBox ? (
        <ProbabilityBoxStyling className="ProbabilityBoxStyling">
          <TextFieldStyling
            id="standard-number"
            type="number"
            className="TextFieldStyling"
            InputLabelProps={{
              shrink: true,
            }}
            value={localValue}
            onInput={updateValue}
            variant="outlined"
            size="small"
          />
          <TextCSSStyling className="TextCSSStyling">
            *Estimated pre-test probability
          </TextCSSStyling>
        </ProbabilityBoxStyling>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ProbabilityBox;

const TextFieldStyling = styled(TextField)(({ theme }) => ({
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  width: "70px",
  float: "left",
}));

const TextCSSStyling = styled("p")(({ theme }) => ({
  height: "14px",
  marginTop: "10px",
  marginBottom: "0px",
  marginLeft: "5px",
  fontSize: 14,
  float: "left",
}));

const ProbabilityBoxStyling = styled(Box)(({ theme }) => ({
  paddingLeft: 10,
}));
