import { Box, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import MatrixGrid from "components/DashboardComponents/MatrixGrid/MatrixGrid";
import MatrixExplanationSection from "components/PlaygroundComponents/MatrixExplanationSection/MatrixExplantionSection";
import { styled } from "@mui/material/styles";

const DashboardMatrixDesktop = (props) => {
  return (
    <OuterMatrixBoxStyled>
      <Grid item container xl={12} lg={12} md={12} sm={12}>
        <Grid item container xl={12} lg={12} md={12} sm={12}>
          <TitleGridStyled item xl={11} lg={11} md={11} sm={10}>
            <LabelPStyled>{props.matrixText}</LabelPStyled>
          </TitleGridStyled>
        </Grid>

        <MatrixExplanationSection {...props}></MatrixExplanationSection>
        <MatrixGridBoxStyled>
          <MatrixGrid {...props} imagePaddingLeft={5} imagePaddingTop={1}></MatrixGrid>
        </MatrixGridBoxStyled>
      </Grid>
    </OuterMatrixBoxStyled>
  );
};

export default DashboardMatrixDesktop;
const MatrixGridBoxStyled = styled(Box)(({ theme }) => ({
  marginTop: 8,
  marginLeft: 18,
  width: 230,
}));

const OuterMatrixBoxStyled = styled(Box)(({ theme }) => ({
  width: 462,
}));
const TitleGridStyled = styled(Grid)(({ theme }) => ({
  marginTop: 0,
  marginLeft: 20,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 10,
    marginTop: "-5px",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 5,
    width: 345,
    marginTop: 0,
  },
}));

const LabelPStyled = styled("p")(({ theme }) => ({
  marginTop: 10,
  marginLeft: 5,
  marginBottom: 0,
  width: 280,
  fontSize: 12,
  fontWeight: 500,
  color: "#FFFFFF",
  [theme.breakpoints.down("lg")]: {
    width: 195,
    marginTop: 10,
    marginLeft: 20,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginTop: 10,
    marginLeft: 10,
  },
}));
