import { Box, AppBar, Typography, Grid } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const HeaderTitle = (props) => {
  return (
    <HeaderOuterBoxStyling className="TitleBoxWrapper">
      <GridWrapperStyling item container sm={6} md={6} lg={6} xl={6} className="GridWrapper">
        <Grid item sm={12} md={12} lg={12} xl={12} className="topTitleGrid">
          <TopTypographyStyling className="title" noWrap>
            <a
              style={{ textDecoration: "none", color: "#EA5A2B" }}
              href="https://www.testingwisely.com/"
            >
              Testing Wisely
            </a>
          </TopTypographyStyling>
        </Grid>
        <Box style={{ display: props.shouldDisplay ? "block" : "none" }}>
          <GridWrapperStyling item sm={12} md={12} lg={12} xl={12} className="bottomTitleGrid">
            <BottomTypographyStyling className="bottomTitle" noWrap>
              <a
                style={{ textDecoration: "none", color: "#46443D", marginBottom: "-10px" }}
                href="https://www.testingwisely.com/"
              >
                Disease Risk {props.page}
              </a>
            </BottomTypographyStyling>
          </GridWrapperStyling>
        </Box>
      </GridWrapperStyling>
    </HeaderOuterBoxStyling>
  );
};

export default HeaderTitle;

const HeaderOuterBoxStyling = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#FFFFFF",
  height: 95,
  position: "relative",
  [theme.breakpoints.down("xl")]: {
    height: 85,
  },
  [theme.breakpoints.down("lg")]: {
    height: 65,
  },
  [theme.breakpoints.down("md")]: {
    height: 55,
  },
}));
const GridWrapperStyling = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));
const TopTypographyStyling = styled(Typography)(({ theme }) => ({
  fontSize: 26,
  height: "100%",
  fontWeight: 900,
  marginTop: 16,
  marginBottom: "-10px",
  marginLeft: 20,
  color: theme.palette.secondary.main,
  overflow: "visible",
  [theme.breakpoints.down("xl")]: {
    marginTop: 10,
    marginLeft: 10,
    fontSize: 26,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 26,
    height: 40,
    marginLeft: 5,

    marginTop: 20,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 23,
    height: 40,
    marginLeft: 10,
    marginTop: 15,
  },
}));

const BottomTypographyStyling = styled(Typography)(({ theme }) => ({
  fontSize: 26,
  height: "100%",
  fontWeight: 900,
  marginLeft: 20,
  color: "#46443D",
  overflow: "visible",
  [theme.breakpoints.down("xl")]: {
    marginLeft: 10,
    fontSize: 26,
  },
}));
