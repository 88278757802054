import * as React from "react";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

const HelpTooltip = (props) => {
  const theme = useTheme();

  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const placement = matchesMdDown ? "top" : "bottom-end";
  return (
    <TooltipStyling title={props.toolTitle} placement={placement}>
      <IconButton>
        <HelpIcon fontSize="small" sx={{ color: "#2B4F53" }} />
      </IconButton>
    </TooltipStyling>
  );
};
export default HelpTooltip;
const TooltipStyling = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 175,
    color: "#46443D",
    backgroundColor: "rgba(130, 162, 164, .95)",
  },
});
