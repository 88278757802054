import { Box, AppBar, Typography, Grid, useScrollTrigger } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import LinksMenu from "components/GenericComponents/Header/LinksMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBarLinks from "components/GenericComponents/Header/AppBarLinks";

import { useTheme } from "@mui/material/styles";
import HeaderTitle from "components/GenericComponents/Header/HeaderTitle";

const Header = (props) => {
  const theme = useTheme();
  const matchesLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <HeaderOuterBoxStyling>
      <AppBarStyling className="appBar">
        <HeaderInnerBoxStyling>
          <GridWrapperHeaderStyling container className="GridWrapperHeader">
            <Grid item container sm={6} md={6} lg={6} xl={6}>
              <HeaderTitle shouldDisplay={matchesLgUp} page={props.pageName} />
            </Grid>
            <Grid item container sm={6} md={6} lg={6} xl={6}>
              <AppBarLinks shouldDisplay={matchesLgUp} />
            </Grid>
          </GridWrapperHeaderStyling>
        </HeaderInnerBoxStyling>
      </AppBarStyling>
    </HeaderOuterBoxStyling>
  );
};

export default Header;

const HeaderOuterBoxStyling = styled(Box)(({ theme }) => ({
  width: "100%",
  height: 95,
  position: "relative",
  [theme.breakpoints.down("xl")]: {
    height: 85,
  },
  [theme.breakpoints.down("lg")]: {
    height: 65,
  },
  [theme.breakpoints.down("md")]: {
    height: 55,
  },
}));
const GridWrapperHeaderStyling = styled(Grid)(({ theme }) => ({
  width: "100%",
}));

const HeaderInnerBoxStyling = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: 1366,
  backgroundColor: "#FFFFFF",
  height: 95,
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {
    maxWidth: 1024,
  },
  [theme.breakpoints.down("lg")]: {
    height: 65,
    maxWidth: 740,
  },
  [theme.breakpoints.down("md")]: {
    height: 55,
    maxWidth: 390,
  },
}));

const AppBarStyling = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  height: 95,
  [theme.breakpoints.down("xl")]: {
    height: 85,
  },
  [theme.breakpoints.down("lg")]: {
    height: 65,
  },
  [theme.breakpoints.down("md")]: {
    height: 55,
  },
}));
