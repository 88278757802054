import { Grid, Box } from "@mui/material";
import React from "react";
import Leftside from "components/PlaygroundComponents/Leftside/Leftside";
import Rightside from "components/PlaygroundComponents/RightSide/Rightside";
import { styled } from "@mui/material/styles";
import Copywrite from "components/GenericComponents/Copywrite/Copywrite";
const PlaygroundDesktop = (props) => {
  return (
    <PlaygroundWrapperStyling container className="outerGrid">
      <LeftSideOuterBox className="leftSideBox">
        <Grid container item lg={5} className="leftSide">
          <Leftside {...props} isPlayground={true} barChartFontSize="26px"></Leftside>
        </Grid>
      </LeftSideOuterBox>
      <RightSideOuterBox className="rightSideBox">
        <RightSideInnerGrid container item lg={7} className="rightSide">
          <Rightside></Rightside>
        </RightSideInnerGrid>
        <CopywriteStyling item container xl={12} lg={12} md={12} sm={12}>
          <Copywrite longMessage={true} />
        </CopywriteStyling>
      </RightSideOuterBox>
    </PlaygroundWrapperStyling>
  );
};

export default PlaygroundDesktop;

const PlaygroundWrapperStyling = styled(Grid)(({ theme }) => ({
  minWidth: 1366,
}));
const CopywriteStyling = styled(Grid)(({ theme }) => ({
  backgroundColor: "#dce3e3",
}));

const LeftSideOuterBox = styled(Box)(({ theme }) => ({
  width: 505,
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    maxWidth: 1024,
    minWidth: 764,
    marginRight: "auto",
    marginLeft: "auto",
  },
}));
const RightSideOuterBox = styled(Box)(({ theme }) => ({
  width: 820,
  backgroundColor: "#FFFFFF",
  marginLeft: 20,
  marginTop: 20,
}));

const RightSideInnerGrid = styled(Grid)(({ theme }) => ({
  minWidth: 820,
}));
