import { Grid, Box } from "@mui/material";
import React, { useContext } from "react";

import { styled } from "@mui/material/styles";
import FloatingBoxTabs from "../../CalculatorComponents/FloaterBoxTabs/FloatingBoxTabs";
import PlaygroundBarChartSection from "../../PlaygroundComponents/PlaygroundBarChartSection/PlaygroundBarChartSection";
import StepFour from "../../CalculatorComponents/CalculatorSteps/StepFour";
import { DBContext } from "hooks/FirebaseContext";
import DashboardMatrixDesktop from "components/DashboardComponents/DashboardMatrix/DashboardMatrixDesktop";

const LeftsideCalculator = (props) => {
  const localFirebaseContext = useContext(DBContext);
  return (
    <Box>
      <BarChartsOuterBoxStyled className="BarChartsOuterBoxStyled">
        <Grid container direction={"column"}>
          <FloatingTabsBoxStyled className="FloatingTabsBoxStyled">
            <Grid item xl={12} className="FloatingTabsBoxStyledGrid">
              <FloatingBoxTabs
                smallTitle="CHANCE OF"
                diseaseNameOrLargeTitle={props.diseaseName}
                className="FloatingBoxTabs"
              ></FloatingBoxTabs>
            </Grid>
          </FloatingTabsBoxStyled>
          <BarGraphBoxStyled className="BarGraphBoxStyled">
            <Grid item xl={12}>
              <Grid xl={12}>
                <PlaygroundBarChartSection
                  barChartFontSize={props.barChartFontSize}
                ></PlaygroundBarChartSection>
              </Grid>
            </Grid>
          </BarGraphBoxStyled>
          <Grid item xl={12} lg={12}>
            <ResultsMatrixBoxStyled
              className="ResultsMatrixBoxStyled"
              sx={{
                display:
                  !!localFirebaseContext.resultType &&
                  localFirebaseContext.resultType !== "none" &&
                  localFirebaseContext.resultType === "all"
                    ? "block"
                    : "none",
              }}
            >
              <DashboardMatrixDesktop
                beforeTestProbability={localFirebaseContext.beforeTestProbability}
                sensitivity={localFirebaseContext.selectedTestSensitivity}
                specificity={localFirebaseContext.selectedTestSpecificity}
                imageHeightAndWidth="17px"
                isPlayground={props.isPlayground}
                matrixText="IF 100 PATIENTS WERE TESTED"
              ></DashboardMatrixDesktop>
            </ResultsMatrixBoxStyled>
          </Grid>
          <ResultsBoxStyled
            sx={{
              display:
                !!localFirebaseContext.selectedTestId && localFirebaseContext.selectedTestId !== ""
                  ? "block"
                  : "none",
            }}
          >
            <Grid item xl={12}>
              <StepFour isPlayground={props.isPlayground}></StepFour>
            </Grid>
          </ResultsBoxStyled>
        </Grid>
      </BarChartsOuterBoxStyled>
    </Box>
  );
};

export default LeftsideCalculator;

const BarChartsOuterBoxStyled = styled(Box)(({ theme }) => ({
  width: 505,
  height: "100vh",
  position: "fixed",
  paddingBottom: 20,
  backgroundColor: "#707070",
  marginRight: "auto",
  marginLeft: "auto",
  zIndex: 1000,
  [theme.breakpoints.down("lg")]: {
    paddingBottom: 30,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 768,
    minWidth: 390,
    marginRight: "auto",
    marginLeft: "auto",
  },
}));
const FloatingTabsBoxStyled = styled(Box)(({ theme }) => ({
  maxWidth: 462,
  width: "100%",
  backgroundColor: "#DCE3E3",
  marginRight: "auto",
  marginLeft: "auto",
  borderRadius: "5px 5px 0px 0px",
  marginTop: 20,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 734,
    minWidth: 734,
    marginRight: "auto",
    marginLeft: "auto",
  },
}));

const BarGraphBoxStyled = styled(Box)(({ theme }) => ({
  width: 462,
  backgroundColor: "#DCE3E3",
  marginRight: "auto",
  marginLeft: "auto",
  borderRadius: "0px 0px 5px 5px",
  [theme.breakpoints.down("lg")]: {
    width: 734,

    marginRight: "auto",
    marginLeft: "auto",
  },
}));
const ResultsMatrixBoxStyled = styled(Box)(({ theme }) => ({
  maxWidth: 462,
  width: "100%",
  backgroundColor: "#46443D",
  marginRight: "auto",
  marginLeft: "auto",
  borderRadius: "5px 5px 5px 5px",
  marginTop: 10,
  marginBottom: 10,
}));

const ResultsBoxStyled = styled(Box)(({ theme }) => ({
  maxWidth: 462,
  width: "100%",
  backgroundColor: "#46443D",
  marginRight: "auto",
  marginLeft: "auto",
  borderRadius: 5,
  marginBottom: 10,
  marginTop: 10,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 734,
    minWidth: 734,
    marginRight: "auto",
    marginLeft: "auto",
  },
}));
/*

                   
minWidth: 764,
  maxWidth: 1024,

 */
