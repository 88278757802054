import { Grid, Box } from "@mui/material";
import React from "react";
import Rightside from "../RightSide/Rightside";
import TopSideFloater from "../TopSideFloater/TopSideFloater";
import { styled } from "@mui/material/styles";
import Copywrite from "../../GenericComponents/Copywrite/Copywrite";

const PlaygroundIpad = (props) => {
  return (
    <PlaygroundWrapperStyling container className="outerGrid">
      <TopOuterBox className="TopOuterBox">
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
              xl: "none",
            },
          }}
        >
          <TopSideFloater
            {...props}
            isPlayground={true}
            barChartFontSize="26px"
            diseaseName="Chance of Disease"
            smallTitle=""
          ></TopSideFloater>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <TopSideFloater
            {...props}
            barChartFontSize="26px"
            isPlayground={true}
            diseaseName="Chance of Disease"
            smallTitle=""
          ></TopSideFloater>
        </Box>
      </TopOuterBox>
      <BottomOuterBox className="wrapperBoxToProvideMargin">
        <Rightside></Rightside>
        <CopywriteStyling item container xl={12} lg={12} md={12} sm={12}>
          <Copywrite longMessage={false} />
        </CopywriteStyling>
      </BottomOuterBox>
    </PlaygroundWrapperStyling>
  );
};

export default PlaygroundIpad;
const CopywriteStyling = styled(Grid)(({ theme }) => ({
  backgroundColor: "#dce3e3",
}));
const PlaygroundWrapperStyling = styled(Grid)(({ theme }) => ({
  minWidth: 768,
  width: "100%",
  marginRight: "auto",
  marginLeft: "auto",
}));

const TopOuterBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  right: 0,
  zIndex: 1000,
  minWidth: 764,
  width: "100%",
  marginRight: "auto",
  marginLeft: "auto",
  backgroundColor: "#707070",
}));
const BottomOuterBox = styled(Box)(({ theme }) => ({
  minWidth: 768,
  width: "100%",
  backgroundColor: "#DCE3E3",
  zIndex: 10,
  marginTop: 408,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 734,
    minWidth: 734,
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 310,
  },
}));
