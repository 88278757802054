import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import React, { useState } from 'react';

import StepPattern from './containers/StepPattern/StepPattern';
import 'regenerator-runtime/runtime';
import { Box, Grid, MuiThemeProvider } from '@material-ui/core';

import Playground from './containers/Playground/Playground';
import Header from 'components/GenericComponents/Header/Header';
import { styled } from '@mui/material/styles';
import theme from 'theme';

/**
 * Main app / entry point.
 */

const App = () => {
    const [pageName, setPageName] = useState('Calculator');

    return (
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                <Grid container direction='column'>
                    <Grid item>
                        <Header pageName={pageName} />
                    </Grid>
                    <MaxHeightBoxStyling className='{maxHeight}'>
                        <Grid container item lg={12} md={12}>
                            <main className='main content '>
                                <Routes>
                                    <Route path='/calculator' element={<Navigate to='/' />} />

                                    <Route
                                        path='/'
                                        element={<StepPattern setPageName={setPageName} />}
                                    />

                                    <Route
                                        path='/playground'
                                        element={<Playground setPageName={setPageName} />}
                                    />
                                </Routes>
                            </main>
                        </Grid>
                    </MaxHeightBoxStyling>
                </Grid>
            </BrowserRouter>
        </MuiThemeProvider>
    );
};

export default App;
const MaxHeightBoxStyling = styled(Box)(({ theme }) => ({
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column'
}));
