import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@material-ui/core";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MessageOnHover from "./MessageOnHover";

const Copywrite = (props) => {
  const copywriteMessage = "Copyright 2021 Daniel Morgan, all rights reserved.";
  const medicalMessage =
    "Note: The content of this website does not constitute medical advice. This calculator was created for your own personal use and educational purposes but is not a substitute for medical advice, diagnosis, or treatment. Always seek the advice of your own physician or other qualified health care professional regarding any medical questions or conditions.";
  const shortMedicalMessage =
    "Note: The content of this website does not constitute medical advice.";
  const theme = useTheme();

  const matchesLgDown = useMediaQuery(theme.breakpoints.down("xl"));
  console.log("matchesLgDown matchesLgDown matchesLgDown:", matchesLgDown);

  return (
    <OutsideWrapperCopywriteStyling className="OutsideWrapperCopywriteStyling">
      <WrapperGridStyling container sx={{ width: "100%" }} className="copywriteNotice">
        <Grid flexDirection="row" item xl={5} lg={5} md={5} sm={12}>
          <CopywriteStyling>
            <CopyWriteTypographyStyling>{copywriteMessage}</CopyWriteTypographyStyling>
          </CopywriteStyling>
        </Grid>
        <Grid item xl={7} lg={7} md={7} sm={12}>
          <Box>
            <TypographyStyling className="TypographyStyling">
              <MessageOnHover shortMessage={shortMedicalMessage} title={medicalMessage} />
            </TypographyStyling>
          </Box>
        </Grid>
      </WrapperGridStyling>
    </OutsideWrapperCopywriteStyling>
  );
};

export default Copywrite;

const TypographyStyling = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: "#707070",
  fontWeight: 100,
  marginBottom: 0,
  textAlign: "end",

  [theme.breakpoints.down("lg")]: {
    textAlign: "end",
  },
  [theme.breakpoints.down("md")]: {
    width: 348,
    textAlign: "end",
  },
}));

const CopyWriteTypographyStyling = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: "#707070",
  fontWeight: 100,
  marginBottom: 0,
  textAlign: "start",
  [theme.breakpoints.down("xl")]: {
    textAlign: "start",
  },
  [theme.breakpoints.down("lg")]: {
    textAlign: "start",
  },

  [theme.breakpoints.down("md")]: {
    width: 348,
    textAlign: "end",
  },
}));

const OutsideWrapperCopywriteStyling = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  width: 720,

  [theme.breakpoints.down("xl")]: {
    width: 780,
    height: 20,
  },
  [theme.breakpoints.down("lg")]: {
    width: 734,
    height: 20,
  },
  [theme.breakpoints.down("md")]: {
    width: 348,
    height: 80,
  },
}));

const CopywriteStyling = styled(Box)(({ theme }) => ({
  marginTop: 5,
  marginLeft: 20,
  [theme.breakpoints.down("xl")]: {
    height: 20,
    marginTop: 5,
  },
  [theme.breakpoints.down("xl")]: {
    height: 20,
    marginTop: 5,
    marginLeft: 5,
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 0,
    height: 30,
    marginLeft: 0,
  },
}));
const WrapperGridStyling = styled(Grid)(({ theme }) => ({
  marginTop: 20,

  [theme.breakpoints.down("xl")]: {
    height: 20,
    marginTop: 0,
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
    height: 80,
  },
}));
