import React from "react";
import { styled } from "@mui/material/styles";

const SeparatorLine = (props) => {
  return (
    <DivBorderStyled
      sx={{ marginLeft: props.marginLeft, marginRight: props.marginRight }}
    ></DivBorderStyled>
  );
};

export default SeparatorLine;
const DivBorderStyled = styled("div")(({ theme }) => ({
  borderStyle: "none none solid none",
  borderWidth: 1,
  borderColor: "#B7B7B3",
  height: 1,
  width: "100%",
}));
