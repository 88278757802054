import { Grid, Box } from "@mui/material";
import React, { useContext } from "react";
import { DBContext } from "../../../hooks/FirebaseContext";
import SeparatorLine from "components/PlaygroundComponents/SeperatorLine/SeparatorLine";
import { styled } from "@mui/material/styles";
import SingleBarChart from "../SingleBarChart/SingleBarChart";

const SingleBarChartSection = (props) => {
  const localFirebaseContext = useContext(DBContext);

  const getBarValue = (value) => {
    if (value !== undefined) {
      const barvalue = Number(localFirebaseContext.convertToPercentage(value));
      return barvalue;
    } else return 0;
  };

  return (
    <BarChartsOuterBoxStyled>
      <Grid item container>
        <Grid item xl={12}>
          <TopBarChartBoxStyled>
            <SingleBarChart
              uniqueClassname="topChart"
              fontSize={props.barChartFontSize}
              barValue={getBarValue(localFirebaseContext.beforeTestProbability)}
              barChartFontSize={props.barChartFontSize}
              titleText="BEFORE TEST"
            ></SingleBarChart>
          </TopBarChartBoxStyled>
        </Grid>
        <Grid item xl={12} lg={12} md={12}>
          <Box
            sx={{
              display:
                !!localFirebaseContext.resultType &&
                localFirebaseContext.resultType !== "none" &&
                localFirebaseContext.resultType !== "all"
                  ? "block"
                  : "none",
            }}
          >
            <SeparatorLine marginLeft={0} marginRight={0}></SeparatorLine>
          </Box>
        </Grid>
        <Grid item xl={12}>
          <BottomBarChartBoxStyled
            sx={{
              display:
                !!localFirebaseContext.resultType &&
                localFirebaseContext.resultType !== "none" &&
                localFirebaseContext.resultType !== "all"
                  ? "block"
                  : "none",
            }}
          >
            <SingleBarChart
              uniqueClassname="bottomChart"
              fontSize={props.barChartFontSize}
              barValue={getBarValue(localFirebaseContext.afterTestProbability)}
              barChartFontSize={props.barChartFontSize}
              titleText="AFTER TEST"
            ></SingleBarChart>
          </BottomBarChartBoxStyled>
        </Grid>
      </Grid>
    </BarChartsOuterBoxStyled>
  );
};

export default SingleBarChartSection;

const BarChartsOuterBoxStyled = styled(Box)(({ theme }) => ({
  width: 462,
  backgroundColor: "#46443D",
  borderRadius: "0px 0px 5px 5px",

  [theme.breakpoints.down("xl")]: {
    width: 480,
  },
  [theme.breakpoints.down("lg")]: {
    width: 360,
  },
}));

const TopBarChartBoxStyled = styled(Box)(({ theme }) => ({
  width: 462,
  [theme.breakpoints.down("xl")]: {
    width: 480,
  },
  [theme.breakpoints.down("lg")]: {
    width: 360,
  },
}));

const BottomBarChartBoxStyled = styled(Box)(({ theme }) => ({
  width: 462,
  borderRadius: "0px 0px 5px 5px",
  [theme.breakpoints.down("xl")]: {
    width: 480,
  },
  [theme.breakpoints.down("lg")]: {
    width: 360,
  },
}));
