import { Grid, Box } from "@mui/material";
import React from "react";

import TopSideFloater from "../../PlaygroundComponents/TopSideFloater/TopSideFloater";
import { styled } from "@mui/material/styles";

const PlaygroundIpad = (props) => {
  return (
    <TopOuterBox className="TopOuterBox">
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
            xl: "none",
          },
        }}
      >
        <TopSideFloater
          {...props}
          barChartFontSize="26px"
          isPlayground={props.isPlayground}
        ></TopSideFloater>
      </Box>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <TopSideFloater
          {...props}
          barChartFontSize="26px"
          isPlayground={props.isPlayground}
          diseaseId={props.diseaseId}
        ></TopSideFloater>
      </Box>
    </TopOuterBox>
  );
};

export default PlaygroundIpad;

const PlaygroundWrapperStyling = styled(Grid)(({ theme }) => ({
  minWidth: 768,
  width: "100%",
  marginRight: "auto",
  marginLeft: "auto",
}));

const TopOuterBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  right: 0,
  zIndex: 1000,
  minWidth: 764,
  width: "100%",
  marginLeft: "-100px",
  backgroundColor: "#707070",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "-15px",
  },
}));
const BottomOuterBox = styled(Box)(({ theme }) => ({
  minWidth: 768,
  width: "100%",
  backgroundColor: "#DCE3E3",
  [theme.breakpoints.down("lg")]: {
    maxWidth: 734,
    minWidth: 734,
    marginRight: "auto",
    marginLeft: "auto",
  },
}));
