import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import LinksMenu from "components/GenericComponents/Header/LinksMenu";
import { Grid } from "@mui/material";
import { DBContext } from "hooks/FirebaseContext";

const AppBarLinks = (props) => {
  const localFirebaseContext = useContext(DBContext);

  const clearData = () => {
    localFirebaseContext.setReset(!localFirebaseContext.isReset);
    localFirebaseContext.clearDiseaseId();
  };
  const pickHeaderLinks = (shouldDisplay) => {
    if (shouldDisplay) {
      return (
        <WrapperBoxStyling>
          <NavLinkStyling to="/" onClick={clearData} style={{ textDecoration: "none" }}>
            Calculator
          </NavLinkStyling>
          <NavLinkStyling onClick={clearData} style={{ textDecoration: "none" }} to="/playground">
            Playground
          </NavLinkStyling>
        </WrapperBoxStyling>
      );
    } else {
      return (
        <WrapperBoxStyling>
          <LinksMenu clearData={clearData} />
        </WrapperBoxStyling>
      );
    }
  };

  return pickHeaderLinks(props.shouldDisplay);
};

export default AppBarLinks;
const NavLinkStyling = styled(NavLink)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 900,
  marginLeft: 20,
  color: "#000000",
  overflow: "visible",
  float: "right",
  textDecoration: "none",
  "&:hover": {
    color: "#EA5A2B",
  },

  "&.active": {
    color: "#EA5A2B",
  },
}));
const WrapperBoxStyling = styled(Box)(({ theme }) => ({
  marginTop: 30,
  width: "100%",
  marginRight: 80,
  [theme.breakpoints.down("xl")]: {
    marginTop: 25,
    marginRight: 10,
  },
  [theme.breakpoints.down("lg")]: {
    marginTop: 8,
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 0,
    marginRight: 10,
  },
}));
