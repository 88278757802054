import React, { useEffect, useState, createContext, useRef } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

import Papa from 'papaparse';
import _ from 'lodash';

import { isNumeric } from '../util/utils';
//import results from '../data/results';

export const DBContext = createContext({
    contextGetDiseases: () => {},
    diseases: [],
    results: [],
    definitions: [],
    diseaseGroups: [],
    covidData: [],
    stateAndCountyData: [],
    additionMathList: [],
    multiplicationList: [],
    stateProbability: 0,
    loaded: false,
    totalProbability: 0,
    vaccinatedSelected: false,
    infectionSelected: false
});

const DBContextProvider = (props) => {
    //list of diseases, results,definition for the diseases, and disease groups from the DB
    const [DBDiseases, setDBDiseases] = useState([]);
    const [DBResults, setDBResults] = useState([]);
    const [DBDefinitions, setDBDefinitions] = useState([]);
    const [DBDiseaseGroups, setDBDiseaseGroups] = useState([]);

    const [isReset, setReset] = useState(false);

    const [covidData, setCovidData] = useState([]);
    const [stateAndCountyData, setStateAndCountyData] = useState([]);
    const [haveCovidData, setHaveCovidData] = useState(false);
    const [sevenDayCovidData, setSevenDayCovidData] = useState([]);
    const [haveSevenDayCovidData, setHaveSevenDayCovidData] = useState(false);

    const [probUseStateAndCounty, setProbUseStateAndCounty] = useState(0);
    const [probabilityAdditionList, setProbabilityAdditionList] = useState([]);
    const [probabilityMultiplicationList, setProbabilityMultiplicationList] = useState([]);

    const [chartResults, setChartResults] = useState([]);
    const [matrixResult, setMatrixResult] = useState([]);
    const [advanced, setAdvanced] = useState(false);

    const [selectedTestId, setSelectedTestId] = useState('');
    const [selectedTestSpecificity, setSelectedTestSpecificity] = useState(0);
    const [selectedTestSensitivity, setSelectedTestSensitivity] = useState(0);

    const [currentTestValues, setCurrentTestValues] = useState([]);

    const [infectionSelected, setInfectionSelected] = useState('');
    const [vaccinatedSelected, setVaccinatedSelected] = useState(false);

    const [showVaccinatedButton, setShowVaccinatedButton] = useState(false);
    const [showAdvancedButton, setShowAdvancedButton] = useState(false);

    //before and after test probablity of disease
    const [totalProbability, setTotalProbability] = useState(0);
    const [beforeTestProbability, setBeforeTestProbability] = useState(0);
    const [afterTestProbability, setAfterTestProbability] = useState(0);

    //before and after test probablity of infectious
    const [covidInfectionProb, setCovidInfectionProb] = useState(0);

    const stateCountyProbability = useRef(0);
    const probAdditionRef = useRef([]);
    const probMultiplicationRef = useRef([]);

    //Current Selected Disease Data//

    //Disease name and if they are infectious stub, hard coded with intent to change as I refactor code.
    const [diseaseName, setDiseaseName] = useState('Select a Disease');
    const [isDiseaseInfectious, setIsDiseaseInfectious] = useState(false);
    const [diseaseId, setDieaseId] = useState();
    const [selectedDisease, setSelectedDisease] = useState();
    const [resultType, setResultType] = useState('');
    const [manualInput, setManualInput] = useState(false);

    //If a disease is infectious and contagouse the radio buttons might appear. This is the current selected radio button.
    const [infectiousOrDiseaseSelection, setInfectiousOrDiseaseSelection] = useState('infectious');

    //End Current Selected Disease Data//

    useEffect(() => {
        getStateAndCountyPopulationList();
    }, [DBContext.stateAndCountyData]);

    useEffect(() => {
        checkCurrentDateDB(0, false, false);
    }, [DBContext.covidData]);

    useEffect(() => {
        getDiseaseGroups();
    }, [DBContext.diseaseGroups]);

    useEffect(() => {
        getDefinitions();
    }, [DBContext.definitions]);

    useEffect(() => {
        getResults();
    }, [DBContext.results]);

    useEffect(() => {
        getDiseases();
    }, [DBContext.diseases]);

    useEffect(() => {
        stateCountyProbability.current = probUseStateAndCounty;
    }, [DBContext.stateProbability]);

    useEffect(() => {
        probAdditionRef.current = probabilityAdditionList;
    }, [DBContext.additionMathList]);

    useEffect(() => {
        probMultiplicationRef.current = probabilityMultiplicationList;
    }, [DBContext.multiplicationList]);

    useEffect(() => {
        if (totalProbability.isNaN) {
            setTotalProbability('');
        }
    }, [beforeTestProbability]);

    useEffect(() => {
        updateProbability();
    }, [
        DBContext.additionMathList,
        DBContext.multiplicationList,
        DBContext.stateProbability,
        DBContext.vaccinatedSelected,
        DBContext.infectionSelected,
        diseaseId
    ]);
    //Configuration to connect to firebase database//
    const firebaseConfig = {
        //Real DB connection
        apiKey: 'AIzaSyDsLdBwPvz_XvDwZ1dGQQn-v1OkoIdhbZ0',
        authDomain: 'calculator.testingwisely.com',
        databaseURL: 'https://testingwisley.firebaseio.com',
        projectId: 'testingwisley',
        storageBucket: 'testingwisley.appspot.com',
        messagingSenderId: '369435458674',
        appId: '1:369435458674:web:403174c812da67076896eb'

        /*
    //Dev DB connection
    apiKey: "AIzaSyCb4Nr35-yyj4abTkYJecAuuCSOzCmjICU",
    authDomain: "testingwisely-dev.firebaseapp.com",
    databaseURL: "https://testingwisely-dev-default-rtdb.firebaseio.com",
    projectId: "testingwisely-dev",
    storageBucket: "testingwisely-dev.appspot.com",
    messagingSenderId: "859540188396",
    appId: "1:859540188396:web:4ea04a212fb050addec9c9",
    measurementId: "G-ZCW5672GY7",
      */
    };

    let fireDatabase = null;

    if (firebase.apps.length === 0) {
        fireDatabase = firebase.initializeApp(firebaseConfig);
    } else {
        fireDatabase = firebase.app();
    }

    const updateSelectedTestId = (testId) => {
        setSelectedTestId(testId);
    };
    const clearDiseaseId = () => {
        setDieaseId(undefined);
        setTotalProbability(0);
        updateMultiplicationAndDivisionProbability([]);
        updateAdditionAndSubtractionProbability([]);
        updateStateAndCountyProbability(0);
        probAdditionRef.current = [];
        probMultiplicationRef.current = [];
        updateBeforeTestProbability(0);
        setSelectedDisease('');
        setBeforeTestProbability(0);
        setShowAdvancedButton(false);
        setShowVaccinatedButton(false);
        setResultType('');
        setSelectedTestId('');
        setDiseaseName('Select a Disease');
    };
    //A test should have infectious and covid specificity and sensitivity
    const updateTestValues = (newTest) => {
        setCurrentTestValues([]);
        setCurrentTestValues(newTest);
    };

    const inputManualProbability = (input) => {
        setBeforeTestProbability(input);
        setTotalProbability(input);
    };

    const updateSelectedTestSpecificity = (newNumber) => {
        if (newNumber !== selectedTestSpecificity) {
            setSelectedTestSpecificity(newNumber);
        }
    };
    const updateSelectedTestSensitivity = (newNumber) => {
        if (newNumber !== selectedTestSensitivity) {
            setSelectedTestSensitivity(newNumber);
        }
    };
    const updateBeforeTestProbability = (newNumber) => {
        if (newNumber !== beforeTestProbability) {
            setBeforeTestProbability(newNumber);
        }
    };
    const updateProbability = () => {
        let multi = 1;
        let addition = 0;

        if (probabilityMultiplicationList.length === 0 && probabilityAdditionList.length === 0) {
            setTotalProbability(0);
            updateBeforeTestProbability(0);
            DBContext.totalProbablity = 0;
        } else {
            _.forEach(probabilityMultiplicationList, (value) => {
                multi *= Number(value.value);
            });
            _.forEach(probabilityAdditionList, (value) => {
                addition += Number(value.value);
            });

            let convertProbabilityToOdds = 0;
            let converOddsToProbability = 0;
            let currentProbability = 0;

            if (addition !== 0) {
                convertProbabilityToOdds = addition / (1 - addition);
            }

            if (convertProbabilityToOdds !== 0 || probabilityMultiplicationList.length > 0) {
                currentProbability = convertProbabilityToOdds * multi;
                converOddsToProbability = currentProbability / (1 + currentProbability);
            }

            if (!isNaN(converOddsToProbability) && converOddsToProbability >= 0) {
                converOddsToProbability = applyContagiousMath(converOddsToProbability);
                converOddsToProbability = applyVaccinatedMath(converOddsToProbability);
                const truncateToTenPastDecimal = Number(converOddsToProbability.toFixed(10));
                updateBeforeTestProbability(truncateToTenPastDecimal);
                setTotalProbability(truncateToTenPastDecimal);
            } else {
                updateBeforeTestProbability(0);
                setTotalProbability(0);
            }
        }
    };

    const convertToPercentage = (rawNumber) => {
        let numberTimeOneHundred = rawNumber * 100;
        if (rawNumber !== 0 && rawNumber !== '') {
            if (numberTimeOneHundred > 1) {
                numberTimeOneHundred = numberTimeOneHundred.toFixed(1);
            } else {
                numberTimeOneHundred = numberTimeOneHundred.toFixed(2);
            }
        }

        return numberTimeOneHundred;
    };

    const applyVaccinatedMath = (number) => {
        console.log('applyVaccinatedMath');

        if (vaccinatedSelected) {
            const vaccinationRate = Number(selectedDisease.vaccinatedPriorPobabiltyModifier);
            const subtractThis = number * vaccinationRate;
            const total = number - subtractThis;
            return total;
        }
        return number;
    };
    const applyContagiousMath = (number) => {
        console.log('applyContagiousMath');
        console.log('infectionSelected', infectionSelected);

        if (infectionSelected === 'contagious') {
            const contagiousRate = Number(selectedDisease.contagiousRate);
            const subtractThis = number * contagiousRate;
            const total = number - subtractThis;
            return total;
        }
        return number;
    };
    const updateAdditionAndSubtractionProbability = (newList) => {
        setProbabilityAdditionList(newList);
        DBContext.additionMathList = newList;
    };

    const updateMultiplicationAndDivisionProbability = (newList) => {
        setProbabilityMultiplicationList(newList);
        DBContext.multiplicationList = newList;
    };

    const updateStateAndCountyProbability = (value) => {
        setProbUseStateAndCounty(value);
        DBContext.stateProbability = value;
    };
    const getDiseaseGroups = () => {
        if (DBContext.diseaseGroups === undefined) {
            if (fireDatabase !== null) {
                //console.log(fireDatabase);
                firebase
                    .database()
                    .ref('diseaseGroups')
                    .once('value')
                    .then(function (snap) {
                        // console.log('snap value for definitions', snap.val());
                        setDBDiseaseGroups(snap.val());
                        DBContext.diseaseGroups = snap.val();
                    })
                    .catch((err) => alert(err));
            }
        }
    };

    const getDiseases = () => {
        if (DBContext.diseases === undefined) {
            if (fireDatabase !== null) {
                // console.log(fireDatabase);
                firebase
                    .database()
                    .ref('diseases')
                    .once('value')
                    .then(function (snap) {
                        setDBDiseases(snap.val());
                        DBContext.diseases = snap.val();
                    })
                    .catch((err) => alert(err));
            }
        }
    };

    const getResults = () => {
        if (DBContext.results === undefined) {
            if (fireDatabase !== null) {
                //console.log(fireDatabase);
                firebase
                    .database()
                    .ref('results')
                    .once('value')
                    .then(function (snap) {
                        setDBResults(snap.val());
                        DBContext.results = snap.val();
                    })
                    .catch((err) => alert(err));
            }
        }
    };

    const getDefinitions = () => {
        if (DBContext.definitions === undefined) {
            if (fireDatabase !== null) {
                // console.log(fireDatabase);
                firebase
                    .database()
                    .ref('definitions')
                    .once('value')
                    .then(function (snap) {
                        // console.log('snap value for definitions', snap.val());
                        setDBDefinitions(snap.val());
                        DBContext.definitions = snap.val();
                    })
                    .catch((err) => alert(err));
            }
        }
    };

    const getStateAndCountyPopulationList = () => {
        if (fireDatabase !== null) {
            firebase
                .database()
                .ref('stateAndCountyData')
                .once('value')
                .then(function (snapshot) {
                    setStateAndCountyData(snapshot.val());
                })
                .catch((err) => alert(err));
        }
    };

    const checkCurrentDateDB = async (subtraction, secondayCheck, isSevenDay) => {
        let snapShot = null;
        //  let count = subtraction;
        //do {
        //    currentDate = getCurrentDate(count);

        if (fireDatabase !== null) {
            let currentDate = getCurrentDate(subtraction);
            for (var i = 0; i < 4; i++) {
                //console.log(fireDatabase);
                currentDate = getCurrentDate(i + subtraction);
                await firebase
                    .database()
                    .ref(currentDate)
                    .once('value')
                    .then(async function (snapshot) {
                        snapShot = snapshot.val();

                        if (snapShot !== null) {
                            if (isSevenDay) {
                                setSevenDayCovidData(snapshot.val());
                                setHaveSevenDayCovidData(true);
                                i = 20;
                            } else {
                                setCovidData(snapshot.val());
                                setHaveCovidData(true);
                                await checkCurrentDateDB(i + 7, false, true);
                                i = 20;
                            }
                        }
                        if (snapShot === null && !secondayCheck) {
                            await checkGithubForData(i + subtraction, isSevenDay);
                        }
                    })
                    .catch((err) => alert(err));
            }
        }
        //  } while (snapShot === null)
    };

    const checkGithubForData = async (subtraction, isSevenDay) => {
        // const [jsonTest, setJsonTest] = useState(JSON);
        let currentDate = getCurrentDate(subtraction);

        const response = await fetch(
            'https://raw.githubusercontent.com/CSSEGISandData/COVID-19/master/csse_covid_19_data/csse_covid_19_daily_reports/' +
                currentDate +
                '.csv'
        );
        if (response.ok) {
            const body = await response.text(); //.json();

            const results = Papa.parse(body, { header: true }); // object with { data, errors, meta }
            const rows = results.data; // array of objects

            setDBData(rows, currentDate);

            await checkCurrentDateDB(subtraction, true, isSevenDay);
        }
    };

    const setDBData = (jsonResults, currentDate) => {
        let fireDatabase = null;
        if (firebase.apps.length === 0) {
            fireDatabase = firebase.initializeApp(firebaseConfig);
        } else {
            fireDatabase = firebase.app();
        }

        if (jsonResults !== undefined) {
            if (fireDatabase !== null) {
                //console.log(fireDatabase);
                firebase
                    .database()
                    .ref(currentDate)
                    .set(jsonResults)
                    .catch((err) => alert(err));
            }
        }
    };

    const getCurrentDate = (subtraction) => {
        const fullCurrentDate = new Date();
        if (subtraction != 0) {
            fullCurrentDate.setDate(fullCurrentDate.getDate() - subtraction);
        }
        const month =
            (fullCurrentDate.getUTCMonth() + 1 < 10 ? '0' : '') +
            (fullCurrentDate.getUTCMonth() + 1);
        const day = (fullCurrentDate.getUTCDate() < 10 ? '0' : '') + fullCurrentDate.getUTCDate();

        const currentDate = '' + month + '-' + day + '-' + fullCurrentDate.getFullYear();

        return currentDate;
    };
    const getCurrentMinusSevenDate = (subtraction) => {
        let fullCurrentDateMinusSeven = new Date();
        fullCurrentDateMinusSeven.setDate(fullCurrentDateMinusSeven.getDate() - (7 + subtraction));
        const month =
            (fullCurrentDateMinusSeven.getUTCMonth() + 1 < 10 ? '0' : '') +
            (fullCurrentDateMinusSeven.getUTCMonth() + 1);
        const day =
            (fullCurrentDateMinusSeven.getUTCDate() < 10 ? '0' : '') +
            fullCurrentDateMinusSeven.getUTCDate();

        const currentDateMinusSeven =
            '' + month + '-' + day + '-' + fullCurrentDateMinusSeven.getFullYear();
        return currentDateMinusSeven;
    };

    /**
     * Returns true if the id is valid. Id is valid if it is the id of one of the known diseases.
     *
     * @param {string} diseaseId id of a disease
     * @returns true if diseaseId is valid, otherwise false
     */
    const isValidDiseaseId = (diseaseId) => {
        const diseaseIds = DBDiseases.map(({ id }) => id);
        const isValid = Boolean(diseaseId && diseaseIds.includes(diseaseId));

        return isValid;
    };
    /**
     * Returns true if the prior percentage value is valid. The value is valid if it is numeric and in the range 0 - 100 (inclusive).
     *
     * @param {string|number} priorPct prior probability value as a percent
     * @returns true if priorPct is valid, otherwise false
     */
    const isValidPriorPct = (priorPct) => {
        const prior = parseFloat(priorPct);
        return isNumeric(priorPct) && prior >= 0 && prior <= 100;
    };

    /**
     * Returns true if the id is valid. Id is valid if it is the id of one of the known results.
     *
     * @param {string} resultId id of a result
     * @returns true if resultId is valid, otherwise false
     */
    const isValidResultId = (resultId) => {
        const resultIds = DBResults.map(({ id }) => id);

        return Boolean(resultId && resultIds.includes(resultId));
    };

    /**
     * Returns true if the testId is valid. testId is valid if diseaseId is valid and testId is the id of one of the tests belonging to the disease with id diseaseId.
     *
     * @param {string} testId id of a test to check
     * @param {string} diseaseId id of the disease the test is for
     * @returns true if testId is valid, otherwise false
     */
    /*
   
    */
    const isValidTestId = (testId, diseaseId) => {
        if (!isValidDiseaseId(diseaseId) || !testId) {
            return false;
        }

        const activeDisease = DBDiseases.find(({ id }) => id === diseaseId);
        const testIds = activeDisease.tests.map(({ id }) => id);
        return testIds.includes(testId);
    };

    const isValidPct = (possiblePct) => {
        const pct = parseFloat(possiblePct);
        return isNumeric(possiblePct) && pct >= 0 && pct <= 100;
    };
    /**
     * Accepts an object representing the four parts of url state and returns a clean version with any invalid values stripped out.
     *
     * Properties are parsed in order:
     * - diseaseId
     * - priorPct
     * - testId
     * - resultId
     *
     * If a property is found to be invalid, any further property will be discarded, even if valid.
     *
     * @param {object} urlState parsed urlState object, with (potentially) diseaseId, priorPct, testId, resultId properties
     *
     * @returns cleaned object with diseaseId, priorPct, testId, resultId properties
     */
    const cleanUpUrlState = (urlState) => {
        const { diseaseId, priorPct, testId, resultId } = urlState;

        const cleaned = {
            diseaseId: '',
            priorPct: '',
            testId: '',
            resultId: ''
        };

        // Check each property in order, setting if valid and bailing out if not
        if (isValidDiseaseId(diseaseId)) {
            cleaned.diseaseId = diseaseId;
        } else {
            return cleaned;
        }

        if (isValidPriorPct(priorPct)) {
            cleaned.priorPct = priorPct;
        } else {
            return cleaned;
        }

        if (isValidTestId(testId, diseaseId)) {
            cleaned.testId = testId;
        } else {
            return cleaned;
        }

        if (isValidResultId(resultId)) {
            cleaned.resultId = resultId;
        }

        return cleaned;
    };

    /**
     * Navigates to the URL defined by the state object. Properties (if present) are expected to be valid.
     *
     * @param {object} state object with (optionally) diseaseId, priorPct, testId, resultId properties
     * @param {object} routerHistory react-router history object
     * @param {string} [basePath=''] base route path. Should be in the format /basePath
     *
     */
    const syncUpState = (state, routerHistory, basePath = '') => {
        const { diseaseId, priorPct, testId, resultId } = state;

        const pieces = [basePath];

        if (diseaseId) {
            pieces.push(`/${diseaseId}`);

            if (isNumeric(priorPct)) {
                pieces.push(`/${priorPct}`);

                if (testId) {
                    pieces.push(`/${testId}`);

                    if (resultId) {
                        pieces.push(`/${resultId}`);
                    }
                }
            }
        }

        const path = pieces.join('') || '/';

        // Only navigate if we're not already at that path
        if (path !== routerHistory.location.pathname) {
            routerHistory.push(path);
        }
    };

    /**
     * Given a state object (presumed to have only valid properties), will infer which step should be active (next to be filled in). 5 implies all selections are complete.
     *
     * @param {object} state object with (optionally) diseaseId, priorPct, testId, resultId properties
     *
     * @returns a number from 1 to 5
     */
    const checkActiveStep = ({ diseaseId, priorPct, testId, resultId }) => {
        if (!diseaseId) {
            return 1;
        }

        if (!isNumeric(priorPct)) {
            return 2;
        }

        if (!testId) {
            return 3;
        }

        if (!resultId) {
            return 4;
        }

        return 5;
    };

    /**
     * Given a state object (presumed to have only valid properties), will infer how many steps remain.
     *
     * @param {object} state object with (optionally) diseaseId, priorPct, testId, resultId properties
     * @returns a number between 4 and 0
     */
    const returnRemainingSteps = ({ diseaseId, priorPct, testId, resultId }) => {
        let stepsRemaining = 4;

        if (diseaseId) {
            stepsRemaining--;
        }
        if (isNumeric(priorPct)) {
            stepsRemaining--;
        }
        if (testId) {
            stepsRemaining--;
        }
        if (resultId) {
            stepsRemaining--;
        }
        return stepsRemaining;
    };

    const cleanUpUrlStatePlayground = (urlState) => {
        const { priorPct, sens, spec, resultId } = urlState;

        const cleaned = {
            priorPct: '',
            sens: '',
            spec: '',
            resultId: ''
        };

        if (isValidPct(priorPct)) {
            cleaned.priorPct = priorPct;
        } else {
            return cleaned;
        }

        if (isValidPct(sens)) {
            cleaned.sens = sens;
        } else {
            return cleaned;
        }

        if (isValidPct(spec)) {
            cleaned.spec = spec;
        } else {
            return cleaned;
        }

        if (isValidResultId(resultId)) {
            cleaned.resultId = resultId;
        }

        return cleaned;
    };

    const setSyncStatePlayground = (state, routerHistory, basePath = '') => {
        const { priorPct, sens, spec, resultId } = state;

        const pieces = [basePath];

        if (isNumeric(priorPct)) {
            pieces.push(`/${priorPct}`);

            if (isNumeric(sens)) {
                pieces.push(`/${sens}`);

                if (isNumeric(spec)) {
                    pieces.push(`/${spec}`);

                    if (resultId) {
                        pieces.push(`/${resultId}`);
                    }
                }
            }
        }

        const path = pieces.join('') || '/';

        routerHistory.replace(path);
    };
    const updateInfectiousProb = (newValue) => {
        setCovidInfectionProb(newValue);
    };

    const updateChartResults = (results) => {
        setChartResults(results);
    };
    const updateMatrixDots = (results) => {
        setMatrixResult(results);
    };
    const updateAdvanced = (newValue) => {
        setAdvanced(newValue);
    };
    const updateVaccinated = (newValue) => {
        setVaccinatedSelected(newValue);
        DBContext.vaccinatedSelected = newValue;
    };
    const updateInfectionSelected = (newValue) => {
        console.log('updateInfectionSelected', newValue);
        setInfectionSelected(newValue);
        DBContext.infectionSelected = newValue;
    };

    const selectDisease = (name, id) => {
        setDiseaseName(name);
        setDieaseId(id);
        setResultType('none');
        _.forEach(DBDiseases, (disease) => {
            if (id === disease.id) {
                if (disease.testLabels === 'basic') {
                    setIsDiseaseInfectious(false);
                } else {
                    setIsDiseaseInfectious(true);
                }
                setSelectedDisease(disease);
                setBeforeTestProbability(0);
                setShowAdvancedButton(disease.advancedButtonOption);
                setShowVaccinatedButton(disease.vaccinatedButtonOption);
                setResultType('');
                setSelectedTestId('');
            }
        });
    };
    return (
        <DBContext.Provider
            value={{
                diseaseGroups: DBDiseaseGroups,
                //     isLoaded: isLoaded,
                results: DBResults,
                definitions: DBDefinitions,
                diseases: DBDiseases,
                currentDataCovidData: covidData,
                getStateAndCountyData: stateAndCountyData,
                stateAndCountyProb: stateCountyProbability,
                selectedTestId: selectedTestId,
                afterTestProbability: afterTestProbability,
                hasSevenDayData: haveSevenDayCovidData,
                hasCovidData: haveCovidData,
                sevenDayData: sevenDayCovidData,
                matrixResult: matrixResult,
                totalProbability: totalProbability,
                probAdditionList: probAdditionRef,
                probMultiplicationList: probMultiplicationRef,
                currentCovidInfectionProb: covidInfectionProb,
                getCurrentTestValues: currentTestValues,
                chartResults: chartResults,
                advanced: advanced,
                vaccinatedSelected: vaccinatedSelected,
                infectionSelected: infectionSelected,
                isDiseaseInfectious: isDiseaseInfectious,
                diseaseName: diseaseName,
                selectedDisease: selectedDisease,
                infectiousOrDiseaseSelection: infectiousOrDiseaseSelection,
                resultType: resultType,
                diseaseId: diseaseId,
                manualInput: manualInput,
                beforeTestProbability: beforeTestProbability,
                selectedTestSpecificity: selectedTestSpecificity,
                selectedTestSensitivity: selectedTestSensitivity,
                showAdvancedButton: showAdvancedButton,
                showVaccinatedButton: showVaccinatedButton,
                isReset: isReset,
                setSelectedTestSpecificity: updateSelectedTestSpecificity,
                setSelectedTestSensitivity: updateSelectedTestSensitivity,
                inputManualProbability: inputManualProbability,
                setManualInput: setManualInput,
                setResultType: setResultType,
                setInfectiousOrDiseaseSelection: setInfectiousOrDiseaseSelection,
                validDiseaseId: isValidDiseaseId,
                validPriorPct: isValidPriorPct,
                validResultId: isValidResultId,
                syncStatePlayground: setSyncStatePlayground,
                setProbAdditionSubtractionList: updateAdditionAndSubtractionProbability,
                setProbMultiplicationDivisionList: updateMultiplicationAndDivisionProbability,
                cleanUrlStatePlayground: cleanUpUrlStatePlayground,
                getRemainingSteps: returnRemainingSteps,
                inferActiveStep: checkActiveStep,
                syncState: syncUpState,
                cleanUrlState: cleanUpUrlState,
                setStateAndCountyProb: updateStateAndCountyProbability,
                validTestId: isValidTestId,
                validPct: isValidPct,
                setUpdateProb: updateProbability,
                updateInfectiousProb: updateInfectiousProb,
                updateSelectedTestId: updateSelectedTestId,
                setAfterTestProbability: setAfterTestProbability,
                updateTestValues: updateTestValues,
                updateChartResults: updateChartResults,
                setMatrixResult: setMatrixResult,
                updateAdvanced: updateAdvanced,
                updateInfectionSelected: updateInfectionSelected,
                updateVaccinated: updateVaccinated,
                selectDisease: selectDisease,
                convertToPercentage: convertToPercentage,
                setReset: setReset,
                clearDiseaseId: clearDiseaseId
            }}
        >
            {props.children}
        </DBContext.Provider>
    );
};

export default DBContextProvider;
