import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import StepTitle from "../StepTitle/StepTitle";
import { styled } from "@mui/material/styles";
import SliderElement from "../SliderElement/SliderElement";
import { DBContext } from "../../../hooks/FirebaseContext";
import PosNegRatios from "../PosNegRatios/PosNegRatios";
import ResetButton from "../../CalculatorComponents/ResetButton/ResetButton";

const Rightside = (props) => {
  const localFirebaseContext = useContext(DBContext);
  const clinicalProbabilityToolTipMessage = (<ToolTipMassageFontSizeStyling> <SpanBoldedStyling>Clinical Probability (pretest probability) </SpanBoldedStyling>is how likely an individual is to have a disease before testing.This can be determined by a few factors including <SpanBoldedStyling>incidence</SpanBoldedStyling> of disease in the population, risk factors, symptoms and physical exam findings.</ToolTipMassageFontSizeStyling>)
  const sensitivityToolTipMessage = (<ToolTipMassageFontSizeStyling> <SpanBoldedStyling>Sensitivity </SpanBoldedStyling>is the ability of a test to correctly detect when someone has a disease. 100% sensitivity means everyone with the disease will test positive. If sensitivity is below 100%, some people with the disease will have an incorrect negative test result (known as a "false-negative").</ToolTipMassageFontSizeStyling>)
  const specificityToolTipMessage = (<ToolTipMassageFontSizeStyling> <SpanBoldedStyling>Specificity </SpanBoldedStyling>is the ability of a test to correctly detect when someone does not have a disease. 100% specificity means everyone without the disease will test negative. If specificity is below 100%, some people without the disease will have an incorrect positive test result (known as a "false-positive"). This is often reported as the "false-positive rate", which is 1-specificity (e.g. 1-90% specificity = 10% false-positive rate).</ToolTipMassageFontSizeStyling>)

  return (
    <RightSideBoxMarginsWrapperStyling>
      <RightSideBoxWrapperStyling>
        <Grid container className="SliderElementOuterGrid">
          <Grid item container lg={12} md={12} className="StepTitleGrid">
            <StepTitle
              number="1"
              title={"What is the pre-test probability?"}
              message={
                "Based on signs and symptoms, how likely is this patient to have the disease before testing?"
              }
            ></StepTitle>
          </Grid>
          <Grid item container lg={12} className="SliderElementGrid">
            <SliderElement
              valueInput={localFirebaseContext.inputManualProbability}
              textBoxLabel="Clinical Probability"
              defaultValue={5}
              toolTitle={clinicalProbabilityToolTipMessage}

            ></SliderElement>
          </Grid>
        </Grid>
        <Grid container className="SliderElementOuterGridTwo">
          <Grid item container lg={12} className="StepTitleGridTwo">
            <StepTitle
              className="sectionTwo"
              number="2"
              title={"What are the characteristics of the test?"}
              message={""}
            ></StepTitle>
          </Grid>
          <Grid item container lg={12}>
            <SliderElement
              valueInput={localFirebaseContext.setSelectedTestSensitivity}
              textBoxLabel="SENSITIVITY"
              defaultValue={90}
              toolTitle={sensitivityToolTipMessage}

            ></SliderElement>
            <SliderElement
              valueInput={localFirebaseContext.setSelectedTestSpecificity}
              textBoxLabel="SPECIFICITY"
              defaultValue={90}
              toolTitle={specificityToolTipMessage}

            ></SliderElement>
          </Grid>
          <Grid item lg={12} className="PosNegElementGrid">
            <PosNegRatios></PosNegRatios>
          </Grid>
        </Grid>
        <Box>
          <ResetButton></ResetButton>
        </Box>
      </RightSideBoxWrapperStyling>
    </RightSideBoxMarginsWrapperStyling>
  );
};

export default Rightside;
const RightSideBoxMarginsWrapperStyling = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  backgroundColor: "#FFFFFF",
  maxWidth: 984,
  minWidth: 734,
  marginBottom: 28,
  [theme.breakpoints.down("md")]: {
    maxWidth: 348,
    minWidth: 348,
    borderRadius: 0,
  },
}));

const RightSideBoxWrapperStyling = styled(Box)(({ theme }) => ({
  marginLeft: 20,
  marginTop: 30,
  paddingTop: 30,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 20,
    marginRight: 20,
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 15,
  },
}));



const SpanBoldedStyling = styled('span')(({ theme }) => ({
  fontWeight: "bold",
}));

const ToolTipMassageFontSizeStyling = styled('p')(({ theme }) => ({
  fontSize: 14,
}));

