import "./StepOne.css";

import React, { useState } from "react";

import DiseaseSelector from "./DiseaseSelector";
import { Avatar, Box, Grid } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";

const StepOne = () => {
  const [hideOrReveal, setHideOrReveal] = useState(true);
  const hideReveal = () => {
    setHideOrReveal(!hideOrReveal);
  };

  return (
    <StepOneContainerGridStyling container className="StepOneContainerGridStyling">
      <Grid item lg={1}>
        <AvatarColorAndSizeStyling className="AvatarColorAndSizeStyling">
          1
        </AvatarColorAndSizeStyling>
      </Grid>
      <MediaMarginsBoxStyling className="MediaMarginsBoxStylingStepOne">
        <MediaMarginsGridStyling className="MediaMarginsGridStylingStepOne" item container sm={12} md={12} lg={11}>
          <Grid item sm={12} md={12} lg={12}>
            <TopLineDivStyling className="TopLineDivStyling" />
          </Grid>
          <MediaGridOneHundredStyling item md={12} lg={12} className="MediaGridOneHundredStyling">
            <StepOneTitleDivStyling className="StepOneTitleDivStyling">
              <StepOneTitlePStyling className="StepOneTitlePStyling">
                What disease are you Testing for?
              </StepOneTitlePStyling>
              {hideOrReveal ? (
                <ExpandLessStyling onClick={hideReveal} className="ExpandLessStyling" />
              ) : (
                <ExpandMoreStyling onClick={hideReveal} className="ExpandMoreStyling" />
              )}
            </StepOneTitleDivStyling>
          </MediaGridOneHundredStyling>
          <DiseaseSelectorStyling item lg={12} className="DiseaseSelectorStyling">
            <Collapse in={hideOrReveal} timeout={0} unmountOnExit>
              <DiseaseSelector />
            </Collapse>
          </DiseaseSelectorStyling>
        </MediaMarginsGridStyling>
      </MediaMarginsBoxStyling>
    </StepOneContainerGridStyling>
  );
};

export default StepOne;
const StepOneContainerGridStyling = styled(Grid)(({ theme }) => ({
  flexFlow: "row",
  [theme.breakpoints.down("md")]: {
    marginLeft: 8,
  },
}));
const MediaMarginsBoxStyling = styled(Box)(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.down("xl")]: {
    marginLeft: 0,
    width: 600,
  },
  [theme.breakpoints.down("lg")]: {
    marginLeft: 8,
    width: 496,
  },
  [theme.breakpoints.down("md")]: {
    width: 290,
  },
}));
const MediaMarginsGridStyling = styled(Grid)(({ theme }) => ({
  width: 646,
  marginLeft: 8,
  maxWidth: 646,
  [theme.breakpoints.down("xl")]: {
    marginLeft: "-8px",

  },
  [theme.breakpoints.down("lg")]: {
    marginLeft: 8,

  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 290,
    width: 290,
    marginLeft: 0,

  },


}));
const AvatarColorAndSizeStyling = styled(Avatar)(({ theme }) => ({
  width: "46px",
  height: "46px",
  float: "left",
  backgroundColor: "#81A2A4",
  fontWeight: 900,
  marginTop: "-5px",
  [theme.breakpoints.down("md")]: {
    width: "28px",
    height: "28px",
    fontSize: 18,
  },
}));
const TopLineDivStyling = styled("div")(({ theme }) => ({
  borderStyle: "solid none none none",
  borderWidth: 2,
  [theme.breakpoints.down("md")]: {
    width: 290,
    marginLeft: 5,
  },
}));

const StepOneTitleDivStyling = styled("div")(({ theme }) => ({
  marginBottom: 0,
  color: "#262420",
  fontSize: 24,
  marginTop: 5,
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    height: 30,
    width: 290,
    marginLeft: 5,
    marginTop: 0,
  },
}));

const StepOneTitlePStyling = styled("p")(({ theme }) => ({
  marginBottom: 0,
  color: "#262420",
  fontSize: 24,
  marginTop: 5,
  fontWeight: 700,
  float: "left",
  [theme.breakpoints.down("md")]: { marginLeft: 6, fontSize: 14, marginTop: 5 },
}));

const ExpandLessStyling = styled(ExpandLess)(({ theme }) => ({
  float: "right",
  fontSize: 40,
  [theme.breakpoints.down("md")]: { float: "right", fontSize: 24 },
}));
const ExpandMoreStyling = styled(ExpandMore)(({ theme }) => ({
  float: "right",
  fontSize: 40,
  [theme.breakpoints.down("md")]: { float: "right", fontSize: 24 },
}));
const DiseaseSelectorStyling = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: { marginLeft: 5 },
}));
const MediaGridOneHundredStyling = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    minWidth: "100%",
  },
  [theme.breakpoints.down("md")]: {
    minWidth: "100%",
  },
}));
