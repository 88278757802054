import React, { useContext, useState } from "react";
import "../FloaterBox/FloaterBox.css";

import { DBContext } from "../../../hooks/FirebaseContext";

import {
  Typography,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";

import "./FloatingBoxTabs.css";

const FloatingBoxTabs = (props) => {
  const localFirebaseContext = useContext(DBContext);
  const [value, setValue] = useState("infectious");

  const handleChange = (event) => {
    setValue(event.target.value);
    localFirebaseContext.updateInfectionSelected(event.target.value);
  };

  function InfectiousTabs() {
    if (localFirebaseContext.isDiseaseInfectious) {
      return (
        <GridWrapperShapeTopStyling container item className="GridWrapperShapeTopStyling">
          <Grid item md={6}>
            <DiseaseChanceStyling className="DiseaseChanceStyling">
              <ChanceOfPStyling className="ChanceOfPStyling">{props.smallTitle}</ChanceOfPStyling>
              <DiseasePStyling className="DiseasePStyling">
                {props.diseaseNameOrLargeTitle}
              </DiseasePStyling>
            </DiseaseChanceStyling>
          </Grid>
          <RadioButtonBoxWrapperStyling
            className="RadioButtonBoxWrapperStyling"
            display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
          >
            <RadioButtonGridWrapperStyling item md={6} lg={6} xl={6}>
              <RadioButtonPositionStyling className="RadioButtonPositionStyling">
                <InfectiousRadioGroupStyling
                  row
                  aria-label="infectiousContagious"
                  name="infectiousContagious"
                  value={value}
                  onChange={handleChange}
                  className="InfectiousRadioGroupStyling"
                >
                  <FormControlLabel
                    value="infectious"
                    control={
                      <RadioHoverStyling
                        className="RadioHoverStyling"
                        disableRipple
                        color="default"
                        checkedIcon={
                          <SpanRadioCheckedIconStyling className="SpanRadioCheckedIconStyling" />
                        }
                        icon={<SpanRadioIconStyling className="SpanRadioIconStyling" />}
                      />
                    }
                    color="#FFFFFF"
                    label={
                      <TextColorForRadioButtonsStyling className="TextColorForRadioButtonsStyling">
                        Infection
                      </TextColorForRadioButtonsStyling>
                    }
                  />

                  <FormControlLabel
                    className="FormControlLabel"
                    value="contagious"
                    control={
                      <RadioHoverStyling
                        className="RadioHoverStyling"
                        disableRipple
                        color="default"
                        checkedIcon={
                          <SpanRadioCheckedIconStyling className="SpanRadioCheckedIconStyling" />
                        }
                        icon={<SpanRadioIconStyling className="SpanRadioIconStyling" />}
                      />
                    }
                    label={
                      <TextColorForRadioButtonsStyling className="TextColorForRadioButtonsStyling">
                        Contagious
                      </TextColorForRadioButtonsStyling>
                    }
                  />
                </InfectiousRadioGroupStyling>
              </RadioButtonPositionStyling>
            </RadioButtonGridWrapperStyling>
          </RadioButtonBoxWrapperStyling>
        </GridWrapperShapeTopStyling>
      );
    }
    return (
      <GridWrapperShapeTopStyling container item className="GridWrapperShapeTopStyling">
        <Grid item md={12}>
          <DiseaseChanceStyling className="DiseaseChanceStyling">
            <ChanceOfPStyling className="ChanceOfPStyling">{props.smallTitle} </ChanceOfPStyling>
            <DiseasePStyling className="DiseasePStyling">
              {props.diseaseNameOrLargeTitle}
            </DiseasePStyling>
          </DiseaseChanceStyling>
        </Grid>
      </GridWrapperShapeTopStyling>
    );
  }

  return <InfectiousTabs></InfectiousTabs>;
};
export default FloatingBoxTabs;

const TextColorForRadioButtonsStyling = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
}));
const RadioButtonBoxWrapperStyling = styled(Box)(({ theme }) => ({
  width: "50%",
}));
const RadioButtonGridWrapperStyling = styled(Grid)(({ theme }) => ({
  float: "right",
  marginRight: 20,
  marginTop: 10,
}));
const GridWrapperShapeTopStyling = styled(Grid)(({ theme }) => ({
  backgroundColor: "#46443D",
  borderRadius: "5px 5px 0 0",
}));
const RadioButtonPositionStyling = styled(FormControl)(({ theme }) => ({
  backgroundColor: "#46443D",
  borderRadius: "5px 5px 0 0",
}));

const DiseaseChanceStyling = styled("div")(({ theme }) => ({
  backgroundColor: "#46443D",
}));

const DiseasePStyling = styled("p")(({ theme }) => ({
  color: "white",
  fontSize: 24,
  marginTop: 0,
  paddingLeft: 10,
  fontWeight: 700,
  zIndex: 1000,
  [theme.breakpoints.down("lg")]: {
    fontSize: 24,
    marginBottom: 10,
  },
}));
const ChanceOfPStyling = styled("p")(({ theme }) => ({
  paddingLeft: 10,
  marginBottom: 0,
  color: "white",
  fontSize: 12,
  fontWeight: 500,
  zIndex: 1000,
  [theme.breakpoints.down("lg")]: {
    fontSize: 10,
    marginTop: 5,
  },
}));
const InfectiousRadioGroupStyling = styled(RadioGroup)(({ theme }) => ({
  flexDirection: "column",
}));
const RadioHoverStyling = styled(Radio)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const SpanRadioIconStyling = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#FFFFFF",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#98B4B6",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const SpanRadioCheckedIconStyling = styled("span")(({ theme }) => ({
  backgroundColor: "#00FFF0",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },

  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));
