import React, { useContext, useEffect, useState } from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { DBContext } from "../../../hooks/FirebaseContext";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@material-ui/core";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import HelpTooltip from "components/CalculatorComponents/ToolTips/ToolTip";

const PosNegRatios = (props) => {
  const localFirebaseContext = useContext(DBContext);
  const [posLikelihoodRatio, setPosLikelihoodRatio] = useState(0);
  const [negLikelihoodRatio, setNegLikelihoodRatio] = useState(0);
  const PosRatio = "POSITIVE LIKELIHOOD RATIO";
  const NegRatio = "NEGATIVE LIKELIHOOD RATIO";

  useEffect(() => {
    console.log(
      "PosNegRatios:localFirebaseContext.selectedTestSpecificity,localFirebaseContext.selectedTestSensitivity,"
    );

    const specificityByOneHundred = localFirebaseContext.selectedTestSpecificity;
    const sensitivityByOneHundred = localFirebaseContext.selectedTestSensitivity;

    setPosLikelihood(specificityByOneHundred, sensitivityByOneHundred);
    setNegLikelihood(specificityByOneHundred, sensitivityByOneHundred);
  }, [localFirebaseContext.selectedTestSpecificity, localFirebaseContext.selectedTestSensitivity]);
  const toInfinityAndBeyond = (
    <TextFieldInputStyling
      id="standard-number"
      className="textFieldInput"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start">
            <AllInclusiveIcon />
          </InputAdornment>
        ),
      }}
      value={""}
      variant="outlined"
      size="small"
    />
  );

  const setPosLikelihood = (specificityByOneHundred, sensitivityByOneHundred) => {
    let posLikeihood;
    if (
      sensitivityByOneHundred !== undefined &&
      sensitivityByOneHundred !== 0 &&
      specificityByOneHundred !== 1
    ) {
      posLikeihood = sensitivityByOneHundred / (1 - specificityByOneHundred);
    } else setPosLikelihoodRatio("infinity");
    if (posLikeihood !== undefined && !isNaN(posLikeihood)) {
      setPosLikelihoodRatio(posLikeihood.toFixed(2));
    }
  };

  const setNegLikelihood = (specificityByOneHundred, sensitivityByOneHundred) => {
    let negLikeihood;
    if (!!specificityByOneHundred && !!sensitivityByOneHundred) {
      negLikeihood = (1 - sensitivityByOneHundred) / specificityByOneHundred;
    } else setNegLikelihoodRatio("infinity");

    if (negLikeihood !== undefined && !isNaN(negLikeihood) && isFinite(negLikeihood)) {
      setNegLikelihoodRatio(negLikeihood.toFixed(2));
    } else setNegLikelihoodRatio("infinity");
  };

  const setLabel = (posOrNegative) => {
    if (posOrNegative === "positive") {
      if (posLikelihoodRatio === "infinity") {
        return toInfinityAndBeyond;
      } else {
        return (
          <TextFieldInputStyling
            id="standard-number"
            className="textFieldInput"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            value={posLikelihoodRatio}
            variant="outlined"
            size="small"
          />
        );
      }
    } else {
      if (negLikelihoodRatio === "infinity") {
        return toInfinityAndBeyond;
      } else {
        return (
          <TextFieldInputStyling
            id="standard-number"
            className="textFieldInput"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            value={negLikelihoodRatio}
            variant="outlined"
            size="small"
          />
        );
      }
    }
  };
  const positiveLRToolTipMessage = (
    <ToolTipMassageFontSizeStyling>
      <SpanBoldedStyling>Positive likelihood ratio (+LR)</SpanBoldedStyling> is the likelihood that
      a positive test result increases the odds of disease. A ratio greater than 1 indicates a
      positive result increases the odds of disease. + LR = sensitivity/1-specificity.
    </ToolTipMassageFontSizeStyling>
  );

  const negativeLRToolTipMessage = (
    <ToolTipMassageFontSizeStyling>
      <SpanBoldedStyling>Negative likelihood ratio (-LR)</SpanBoldedStyling> is the likelihood that
      a negative test result decreases the odds of disease. A ratio less than 1 indicates a negative
      result decreases the odds of disease. -LR = 1-sensitivity/ specificity.
    </ToolTipMassageFontSizeStyling>
  );

  return (
    <WrapperGridStyling container sx={{ width: "100%" }} className="PosNegElement">
      <Grid item container lg={6} md={6} sm={12}>
        <Grid item xl={11} lg={11} md={11} sm={8}>
          <PosNegLableStyling>
            {PosRatio}
            <HelpTooltip toolTitle={positiveLRToolTipMessage}></HelpTooltip>
          </PosNegLableStyling>
        </Grid>
        <Grid item lg={12} md={12} sm={4}>
          {setLabel("positive")}
        </Grid>
      </Grid>
      <Grid item container lg={6} md={6} sm={12}>
        <Grid item lg={12} md={12} sm={8}>
          <PosNegLableStyling>
            {NegRatio}
            <HelpTooltip toolTitle={negativeLRToolTipMessage}></HelpTooltip>
          </PosNegLableStyling>
        </Grid>
        <Grid item lg={6} md={6} sm={4}>
          {setLabel("negative")}
        </Grid>
      </Grid>
    </WrapperGridStyling>
  );
};

export default PosNegRatios;

const TextFieldInputStyling = styled(TextField)(({ theme }) => ({
  width: "74px",
  height: "46px",
  fontWeight: 900,
  fontSize: 14,

  [theme.breakpoints.down("md")]: {
    width: "60px",
    height: "38px",
    fontSize: 14,
  },
}));

const PosNegLableStyling = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: "#2B4F53",
  fontWeight: 900,
  marginBottom: 10,
  [theme.breakpoints.down("md")]: {
    width: 196,
  },
}));

const WrapperGridStyling = styled(Grid)(({ theme }) => ({
  marginLeft: 80,
  marginTop: 20,
  marginBottom: 30,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 55,
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 35,
  },
}));
const SpanBoldedStyling = styled("span")(({ theme }) => ({
  fontWeight: "bold",
}));

const ToolTipMassageFontSizeStyling = styled("p")(({ theme }) => ({
  fontSize: 14,
}));
