import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { fontSize } from "@mui/system";
import "components/PlaygroundComponents/SingleBarChart/SingleBarChart.css";

const SingleBarChart = (props) => {
  const chartColors = {
    barColor: "#00FFF0",
    barBackgroundColor: "#ddd",
    chartBackgroundColor: "#46443D",
    lineAndTickColor: "#FFFFFF",
  };
  const [greyPortionOfBar, setGreyPortionOfBar] = useState(100);

  useEffect(() => {
    console.log("SingleBarChart: props.barValue");

    setGreyPortionOfBar(100 - props.barValue);
  }, [props.barValue]);

  const yAxis = {
    // Turn off grid
    gridLineWidth: 0,

    // Show the one bottom axis line, sets it color and the max value it should display
    lineColor: chartColors.lineAndTickColor,
    lineWidth: 1,
    fillOpacity: 0,
    max: 100,
    min: 0,

    // Show 6 ticks along the axis
    tickAmount: 6,
    tickColor: chartColors.lineAndTickColor,
    tickLength: 5,
    tickWidth: 1,
    title: {
      text: null,
    },
    labels: {
      style: {
        color: chartColors.lineAndTickColor,
      },
    },
  };
  const getChartSettings = () => {
    let chartSettings = {
      style: {
        // Unfortunately we can't easily override this in CSS, because Highcharts uses SVG,
        // and calculates sizes based off the font declare here. Same goes for font-size
        fontFamily: `'Open Sans',Courier,'Lato', sans-serif`,
      },
      type: "bar",
      backgroundColor: chartColors.chartBackgroundColor,
      borderRadius: 5,

      marginRight: 20,
      marginTop: 0,
      marginBottom: 30,
      height: "18%",
    };

    return chartSettings;
  };

  const getPlotOptions = () => {
    return {
      bar: {
        // Adjust space between bars
        groupPadding: 0.1,
        pointPadding: 0,
        pointWidth: 20,
      },
      series: {
        stacking: "normal",
      },
    };
  };

  const getLabelStyle = () => {
    const labelStyle = {
      fontSize: props.barChartFontSize,
      fontWeight: "900",
    };

    return labelStyle;
  };

  const ChartOptions = {
    chart: getChartSettings(),
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: getPlotOptions(),

    title: false,
    xAxis: {
      categories: ["" + props.barValue + " %"],
      title: {
        text: null,
      },
      lineWidth: 0,

      labels: {
        useHTML: true,
        style: getLabelStyle(),
        formatter() {
          return `<div class = "bar-label" >${this.value} </div>`;
        },
      },
    },
    yAxis: yAxis,

    tooltip: {
      enabled: false,
    },

    series: [
      {
        name: "Grey Fill",
        legendIndex: 1,
        color: chartColors.barBackgroundColor,
        data: [{ y: greyPortionOfBar }],
      },
      {
        name: "",
        data: [
          {
            y: props.barValue,
            color: chartColors.barColor,
          },
        ],
        borderColor: chartColors.barColor,
      },
    ],
  };

  return (
    <Grid>
      <Grid item xl={12}>
        <BarTitleTestStyled>{props.titleText}</BarTitleTestStyled>
      </Grid>
      <Grid item xl={12}>
        <HighchartsReact
          highcharts={Highcharts}
          options={ChartOptions}
          oneToOne={true}
          className={props.uniqueClassname}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(SingleBarChart);

const BarTitleTestStyled = styled("p")(({ theme }) => ({
  marginTop: 5,
  marginLeft: 10,
  marginBottom: 0,
  color: "#FFFFFF",
  fontSize: 12,
  height: 12,
}));
