import { createTheme } from "@mui/material/styles";
import * as colors from "@material-ui/core/colors";

const white = "#FFFFFF";

const palette = createTheme({
  white,

  primary: {
    contrastText: white,
    light: "#98B4B6",
    main: "#1B97A4",
    dark: "#20494C",
  },
  secondary: {
    contrastText: white,
    light: "#E0A689",
    main: "#EA5A2B",
    dark: "#7C4D3B",
  },

  disabled: {
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.grey[900],
    secondary: colors.grey[600],
    link: colors.blueGrey[600],
  },
  link: colors.blueGrey[900],
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
});
export default palette;
