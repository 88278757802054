import { FormControlLabel } from "@material-ui/core";
import { Switch } from "@mui/material";
import React, { useContext } from "react";
import { DBContext } from "../../../hooks/FirebaseContext";
import { styled } from "@mui/material/styles";

const AdvancedAndVaccinated = (props) => {
  const setAdvanced = () => {
    localFirebaseContext.updateAdvanced(!localFirebaseContext.advanced);
  };
  const setVaccinated = () => {
    localFirebaseContext.updateVaccinated(!localFirebaseContext.vaccinatedSelected);
  };
  const localFirebaseContext = useContext(DBContext);

  return (
    <div className="titles">
      <div>
        {props.advanced && (
          <AdvancedToggleFormControlLabelStyling
            label="Advanced"
            className="AdvancedToggleFormControlLabelStyling"
            control={
              <Switch
                checked={localFirebaseContext.advanced}
                onChange={setAdvanced}
                name="checkedB"
                color="primary"
              />
            }
          />
        )}
        {props.vaccinated && (
          <AdvancedToggleFormControlLabelStyling
            label="Vaccinated"
            className="AdvancedToggleFormControlLabelStyling"
            control={
              <Switch
                checked={localFirebaseContext.vaccinatedSelected}
                onChange={setVaccinated}
                name="checkedB"
                color="primary"
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default AdvancedAndVaccinated;

const AdvancedToggleFormControlLabelStyling = styled(FormControlLabel)(({ theme }) => ({
  float: "right",
  padding: 0,
}));
