import "../../Step.scss";
import "./StepThree.css";

import React, { useEffect, useContext, useState } from "react";
import { DBContext } from "../../../../hooks/FirebaseContext";
import _, { max } from "lodash";

import { useTheme } from "@mui/material/styles";

import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, Grid, RadioGroup } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import SliderTooltip from "components/CalculatorComponents/ToolTips/SliderToolTips";

const StepThree = (props) => {
  const localFirebaseContext = useContext(DBContext);
  const [diseaseTest, setDiseaseTests] = useState();
  const [sensitivityAndSpecificityLabel, setSensitivityAndSpecificityLabel] = useState();
  const [advancedLabel, setAdvancedLabel] = useState(false);

  const [currentSpecificityId, setCurrentSpecificityId] = useState([]);
  const [selectedTestSenstivity, setSelectedTestSenstivity] = useState(0);

  const [currentCovidTestSenstivity, setCurrentCovidTestSenstivity] = useState(0);
  const [currentTestSpecificity, setCurrentTestSpecificity] = useState(0);

  const [currentInfectiousTestSenstivity, setCurrentInfectiousTestSenstivity] = useState(0);
  const [currentInfectiousTestSpecificity, setCurrentInfectiousTestSpecificity] = useState(0);

  const [currentCovidTestSenstivityId, setCurrentCovidTestSenstivityId] = useState();
  const [referencesAndCitations, setRefrencesAndCitations] = useState("");
  const [changeColor, setChangeColor] = useState(false);
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    _.find(localFirebaseContext.diseases, (value) => {
      if (value.id === localFirebaseContext.diseaseId) {
        setDiseaseTests(value.tests);
        selectLabelForSensitivitySpecificity(value.testLabels);
        localFirebaseContext.setSelectedTestSensitivity(0);
        localFirebaseContext.setSelectedTestSpecificity(0);
        props.showPosNegBar(false);
        setRefrencesAndCitations(value.referenceLink);
      }
    });
  }, [localFirebaseContext.diseaseId]);

  const selectLabelForSensitivitySpecificity = (labelType) => {
    let tableLabel = "";
    if (labelType === "advanced") {
      console.log("labelType", labelType);

      setAdvancedLabel(true);
      const firstRow = (
        <TableHead>
          <TableRow className="FirstTableRow">
            <TableCellTestStyled align="left" className="TableCellTestStyled">
              TEST
              <SliderTooltip
                className="TooltipInfoIconStyled"
                toolTitle="Sensitivity of test depends on the day of sample collection relative to onset of symptoms."
              >
                <InfoIcon color="primary" fontSize="small"></InfoIcon>
              </SliderTooltip>
            </TableCellTestStyled>
            <DiseaseTableCellStyled align="left" colSpan={2} className="DiseaseTableCellStyled">
              to detect infection
            </DiseaseTableCellStyled>
            <InfectiousTableCellStyled
              align="left"
              colSpan={2}
              className="InfectiousTableCellStyled"
            >
              to detect contagious
            </InfectiousTableCellStyled>
          </TableRow>
          <TableRow className="TableRow">
            <SensTextTableCellStyled className="SensTextTableCellStyled"> </SensTextTableCellStyled>
            <SensTextTableCellStyled className="SensTextTableCellStyled">
              sensitivity
            </SensTextTableCellStyled>
            <SensTextTableCellStyled className="SensTextTableCellStyled">
              specificity
            </SensTextTableCellStyled>
            <SensTextTableCellStyled className="SensTextTableCellStyled">
              sensitivity
            </SensTextTableCellStyled>
            <SensTextTableCellStyled className="SensTextTableCellStyled">
              specificity
            </SensTextTableCellStyled>
          </TableRow>
        </TableHead>
      );

      tableLabel = { ...firstRow };
    } else {
      setAdvancedLabel(false);

      tableLabel = (
        <TableHead>
          <TableRow className="TableRow">
            <SensTextTableCellStyled className="SensTextTableCellStyled"> </SensTextTableCellStyled>
            <SensTextTableCellStyled className="SensTextTableCellStyled">
              sensitivity
            </SensTextTableCellStyled>
            <SensTextTableCellStyled className="SensTextTableCellStyled">
              specificity
            </SensTextTableCellStyled>
          </TableRow>
        </TableHead>
      );
    }
    console.log("tableLabel", tableLabel);
    setSensitivityAndSpecificityLabel(tableLabel);
  };

  const setTest = (event) => {
    props.showPosNegBar(true);

    const targetValue = event.target.value;
    const targetArray = targetValue.split(":");

    setSelectedTestSenstivity(Number(targetArray[0]));
    setCurrentCovidTestSenstivityId(targetArray[1]);

    localFirebaseContext.updateSelectedTestId(targetArray[1]);
    _.forEach(diseaseTest, (value) => {
      if (value.id === targetArray[1]) {
        props.setTestName(value.name);
        setCurrentInfectiousTestSenstivity(value.infectiousSensitivity);
        setCurrentInfectiousTestSpecificity(value.infectiousSpecificity);

        setCurrentSpecificityId("" + value.id + ":" + value.specificity);
        if (value.subSpecificity !== undefined) {
          let maxValue = 0;
          let minValue = 1000;

          _.forEach(value.subSpecificity, (subSpec) => {
            if (subSpec.value < minValue) {
              minValue = subSpec.value;
            }
            if (subSpec.value > maxValue) {
              maxValue = subSpec.value;
            }
            if (subSpec.defaultValue) {
              setCurrentSpecificityId("" + value.id + ":" + subSpec.id + ":" + subSpec.value);
              localFirebaseContext.setSelectedTestSpecificity(Number(subSpec.value));
            }
          });
          value.minValue = minValue;
          value.maxValue = maxValue;
          value.subSpecificityExists = true;
        } else {
          value.subSpecificityExists = false;
          if (localFirebaseContext.infectionSelected !== "contagious") {
            localFirebaseContext.setSelectedTestSpecificity(Number(value.specificity));
          } else {
            localFirebaseContext.setSelectedTestSpecificity(Number(value.infectiousSpecificity));
          }
        }

        if (value.subSpecificity !== undefined) {
          setChangeColor(true);
        } else {
          setChangeColor(false);
        }
        if (localFirebaseContext.infectionSelected !== "contagious") {
          localFirebaseContext.setSelectedTestSensitivity(Number(value.sensitivity));
        } else {
          localFirebaseContext.setSelectedTestSensitivity(Number(value.infectiousSensitivity));
        }
      }
    });
  };

  const handleClick = (event) => {
    console.log("Handle click is rinning");
    const targetValue = event.target.value;
    const targetArray = targetValue.split(":");
    localFirebaseContext.setSelectedTestSpecificity(Number(targetArray[2]));
    setCurrentSpecificityId(targetValue);
  };

  return (
    <Box>
      <StepThreeGridStyling container className="StepThreeGridStyling">
        <Grid item md={1} className="numberGrid">
          <AvatarColorAndSizeStyled className="AvatarColorAndSizeStyled">
            3
          </AvatarColorAndSizeStyled>
        </Grid>
        <MediaMarginsBoxStyling className="MediaMarginsBoxStyling">
          <StepThreeBodyGridStyling
            item
            container
            md={11}
            lg={11}
            className="StepThreeBodyGridStyling"
          >
            <StepThreeTitleGridStyling item md={12} lg={12} className="StepThreeTitleGridStyling">
              <Grid item md={12} lg={12}>
                <TopLineDivStyling className="TopLineDivStyling"></TopLineDivStyling>
              </Grid>
              <Grid item md={12} lg={12}>
                <div className="whatTest ">
                  <StepThreeTitlePStyled className="StepThreeTitlePStyled">
                    What Test will you order?
                  </StepThreeTitlePStyled>
                </div>
              </Grid>
              <Grid item md={12} lg={12}>
                <TableContainerRootStyling component={Paper} className="TableContainerRootStyling">
                  <Table>
                    {!matches && sensitivityAndSpecificityLabel}

                    <TableBody>
                      {diseaseTest != undefined &&
                        diseaseTest.map((value) => (
                          <TableRow key={value.name}>
                            <TableCellStyled className="TableCellStyled">
                              <FormControlLabel
                                control={
                                  <RadioHoverStyled
                                    className="RadioHoverStyled"
                                    disableRipple
                                    checkedIcon={
                                      <RadioCheckedButtonStyling className="RadioCheckedButtonStyling" />
                                    }
                                    icon={
                                      <RadioIconButtonStyling className="RadioIconButtonStyling" />
                                    }
                                    color="default"
                                    onChange={setTest}
                                    name="radio-button-tests"
                                    inputProps={{ "aria-label": "A" }}
                                    value={"" + value.sensitivity + ":" + value.id}
                                    checked={currentCovidTestSenstivityId === value.id}
                                    key={value.id}
                                  />
                                }
                                checked={currentCovidTestSenstivityId === value.id}
                                key={value.id}
                                tabIndex={-1}
                                label={value.name}
                              ></FormControlLabel>
                              <RadioGroupIndentStyled
                                row
                                className="RadioGroupIndentStyled"
                                style={{
                                  display: localFirebaseContext.advanced ? "flex" : "none",
                                }}
                              >
                                {value.subSpecificityExists == true &&
                                  value.subSpecificity.map(
                                    (spec) =>
                                      currentCovidTestSenstivityId === value.id && (
                                        <section
                                          key={
                                            "" + value.sensitivity + ":" + value.id + ":" + spec.id
                                          }
                                        >
                                          <FormControlLabel
                                            control={
                                              <RadioHoverStyled
                                                className="RadioHoverStyled"
                                                disableRipple
                                                checkedIcon={
                                                  <RadioCheckedButtonStyling className="RadioCheckedButtonStyling" />
                                                }
                                                icon={
                                                  <RadioIconButtonStyling className="RadioIconButtonStyling" />
                                                }
                                                color="default"
                                                onChange={handleClick}
                                                style={{
                                                  paddingLeft: 4,
                                                  paddingRight: 4,
                                                }}
                                                name={"radio-button-" + value.id + "" + spec.id}
                                                inputProps={{
                                                  "aria-label": "A",
                                                }}
                                                value={
                                                  "" + value.id + ":" + spec.id + ":" + spec.value
                                                }
                                                checked={
                                                  currentSpecificityId ===
                                                  "" + value.id + ":" + spec.id + ":" + spec.value
                                                }
                                                key={"" + value.sensitivity + ":" + value.id}
                                              />
                                            }
                                            checked={currentCovidTestSenstivityId === spec.id}
                                            key={"" + value.id + "" + spec.id}
                                            tabIndex={-1}
                                            label={
                                              <span
                                                style={{
                                                  color: "#20494C",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {spec.value * 100 + "%"}
                                              </span>
                                            }
                                          ></FormControlLabel>
                                        </section>
                                      )
                                  )}
                              </RadioGroupIndentStyled>
                            </TableCellStyled>
                            {!matches && (
                              <TableCellBorderSettingStyled className="TableCellBorderSettingStyled">
                                <RadioPickerHintStyled className="RadioPickerHintStyled">
                                  {Number(value.sensitivity) * 100}%
                                </RadioPickerHintStyled>
                              </TableCellBorderSettingStyled>
                            )}
                            {!matches && (
                              <TableCellBorderSettingStyled className="TableCellBorderSettingStyled">
                                <RadioPickerHintStyled
                                  className="RadioPickerHintStyled"
                                  style={
                                    value.subSpecificityExists &&
                                    localFirebaseContext.advanced &&
                                    changeColor
                                      ? { color: "#20494C", fontWeight: 900 }
                                      : { color: "#000000" }
                                  }
                                >
                                  {value.subSpecificityExists && localFirebaseContext.advanced
                                    ? value.minValue * 100 + "-" + value.maxValue * 100 + "%"
                                    : value.specificity * 100 + "%"}
                                </RadioPickerHintStyled>
                              </TableCellBorderSettingStyled>
                            )}
                            {advancedLabel && !matches && (
                              <TableCellBorderSettingStyled className="TableCellBorderSettingStyled">
                                <RadioPickerHintStyled className="RadioPickerHintStyled">
                                  {Number(value.infectiousSensitivity) * 100}%
                                </RadioPickerHintStyled>
                              </TableCellBorderSettingStyled>
                            )}
                            {advancedLabel && !matches && (
                              <TableCellBorderSettingStyled className="TableCellBorderSettingStyled">
                                <RadioPickerHintStyled className="RadioPickerHintStyled">
                                  {Number(value.infectiousSpecificity) * 100}%
                                </RadioPickerHintStyled>
                              </TableCellBorderSettingStyled>
                            )}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainerRootStyling>
              </Grid>
            </StepThreeTitleGridStyling>
          </StepThreeBodyGridStyling>
        </MediaMarginsBoxStyling>
      </StepThreeGridStyling>
      {referencesAndCitations != undefined && referencesAndCitations !== "" && (
        <ReferencesAndCitationsAStyled
          href={referencesAndCitations}
          className="ReferencesAndCitationsAStyled"
          target="_blank"
          rel="noopener noreferrer"
        >
          References
        </ReferencesAndCitationsAStyled>
      )}
    </Box>
  );
};

export default StepThree;

const RadioCheckedButtonStyling = styled("span")(({ theme }) => ({
  backgroundColor: "#00FFF0",
  borderRadius: "50%",

  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#1B97A4",
  },
  width: 16,
  height: 16,
  boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",

  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const RadioIconButtonStyling = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#FFFFFF",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#98B4B6",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const TableContainerRootStyling = styled(TableContainer)(({ theme }) => ({
  width: "100%",
  height: "100%",
  boxShadow: "none",
  marginTop: 5,
  [theme.breakpoints.down("md")]: {
    marginLeft: 2,
  },
}));

const StepThreeGridStyling = styled(Grid)(({ theme }) => ({
  flexFlow: "row",
  [theme.breakpoints.down("lg")]: {
    width: 708,
  },
  [theme.breakpoints.down("md")]: {
    width: 358,
    marginLeft: 8,
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 8,
    width: 294,
  },
}));
const MediaMarginsBoxStyling = styled(Box)(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    marginLeft: 16,
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 8,
    minWidth: 290,
  },
}));

const StepThreeBodyGridStyling = styled(Grid)(({ theme }) => ({
  minWidth: 646,
  [theme.breakpoints.down("lg")]: {
    minWidth: 646,
  },
  [theme.breakpoints.down("md")]: {
    minWidth: 290,
  },
  [theme.breakpoints.down("md")]: {
    minWidth: 290,
  },
}));

const StepThreeTitleGridStyling = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    minWidth: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: 290,
  },
  [theme.breakpoints.down("md")]: {
    width: 290,
  },
}));
const TopLineDivStyling = styled("div")(({ theme }) => ({
  borderStyle: "solid none none none",
  borderWidth: 2,
  [theme.breakpoints.down("md")]: {
    width: 290,
    marginLeft: 5,
  },
}));

const TableCellTestStyled = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.primary.dark,
  fontWeight: 900,
  borderColor: theme.palette.primary.dark,
  borderWidth: 1,
  paddingLeft: 0,
  paddingBottom: 0,
}));

const AvatarColorAndSizeStyled = styled(Avatar)(({ theme }) => ({
  width: "46px",
  height: "46px",
  float: "left",
  backgroundColor: "#81A2A4",
  fontWeight: 900,
  marginTop: "-5px",
  [theme.breakpoints.down("md")]: {
    width: "28px",
    height: "28px",
    fontSize: 18,
  },
}));
const StepThreeTitlePStyled = styled("p")(({ theme }) => ({
  marginBottom: 0,
  color: "#262420",
  fontSize: 24,
  marginTop: 5,
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    marginLeft: 12,
    fontSize: 14,
    marginTop: 5,
  },
}));

const DiseaseTableCellStyled = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.primary.light,
  fontWeight: 900,
  borderColor: theme.palette.primary.dark,
  borderWidth: 1,
  paddingBottom: 0,
}));

const InfectiousTableCellStyled = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.light,
  fontWeight: 900,
  borderColor: theme.palette.primary.dark,
  borderWidth: 1,
  paddingBottom: 0,
}));
const SensTextTableCellStyled = styled(TableCell)(({ theme }) => ({
  margin: 0,
  padding: "0 16px",
  color: "#6B6559",
  fontSize: 14,
  align: "left",
  borderStyle: "none",
}));
const RadioGroupIndentStyled = styled(RadioGroup)(({ theme }) => ({
  marginLeft: 8,
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  padding: 0,
  margin: 0,
  align: "left",
  borderColor: theme.palette.primary.dark,
  borderWidth: 1,
}));

const TableCellBorderSettingStyled = styled(TableCell)(({ theme }) => ({
  borderColor: theme.palette.primary.dark,
  borderWidth: 1,
}));

const RadioPickerHintStyled = styled("p")(({ theme }) => ({
  fontSize: 14,
  color: "#000000",
  align: "left",
  margin: 0,
}));
const ReferencesAndCitationsAStyled = styled("a")(({ theme }) => ({
  fontSize: 12,
  float: "right",
}));

const RadioHoverStyled = styled(Radio)(({ theme }) => ({
  marginLeft: 5,
  "&:hover": {
    backgroundColor: "transparent",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 10,
  },
}));
