import { Box, Grid } from "@material-ui/core";
import React from "react";
import "./ExplanationFragment.css";
import clsx from "clsx";
import { styled } from "@mui/material/styles";

const ExplanationFragment = (props) => {
  return (
    <SingleExplanationFragmentBoxStyled className="SingleExplanationFragmentBoxStyled">
      <OuterGridStyled container item className="OuterGridStyled">
        <LegendGridStyled
          item
          sm={2}
          md={2}
          lg={2}
          xl={2}
          className="legendGrid"
          justifycontent="center"
        >
          <img src={props.source} alt={props.alt}></img>
        </LegendGridStyled>
        <NumbersAndExplanationGridStyled
          container
          item
          sm={10}
          md={10}
          lg={10}
          xl={10}
          className={clsx("NumbersAndExplanationGridStyled", props.backgroundColor)}
        >
          <Grid item sm={12} md={12} lg={12} xl={12}>
            <NumberSizePStyled className="NumberSizePStyled">{props.number}</NumberSizePStyled>
          </Grid>
          <Grid item sm={12} md={12} lg={12} xl={12}>
            <ExplanationTextPStyled className="ExplanationTextPStyled">
              {props.explanation}
            </ExplanationTextPStyled>
          </Grid>
        </NumbersAndExplanationGridStyled>
      </OuterGridStyled>
    </SingleExplanationFragmentBoxStyled>
  );
};
export default ExplanationFragment;
const SingleExplanationFragmentBoxStyled = styled(Box)(({ theme }) => ({
  width: 188,

  [theme.breakpoints.down("xl")]: {
    height: 70,
  },
  [theme.breakpoints.down("lg")]: {
    height: 58,
    width: 159,
  },
  [theme.breakpoints.down("md")]: {
    height: 58,
    width: 159,
  },
}));
const OuterGridStyled = styled(Grid)(({ theme }) => ({
  height: "100%",
  [theme.breakpoints.down("md")]: {
    width: 160,
  },
}));

const LegendGridStyled = styled(Grid)(({ theme }) => ({
  backgroundColor: "#2F2F2F",
  justifyContent: "center",
  alignItems: "center",
  height: 58,
  display: "flex",
  width: 29,

  [theme.breakpoints.down("xl")]: {
    width: 29,
    height: 70,
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("lg")]: {
    width: 30,
    height: 58,
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("md")]: {
    width: 20,
    height: 58,
    justifyContent: "center",
    alignItems: "center",
  },
}));
const NumbersAndExplanationGridStyled = styled(Grid)(({ theme }) => ({
  height: 58,
  width: "100%",
  [theme.breakpoints.down("xl")]: {
    height: 70,
    width: 120,
    display: "flex",
    alignItems: "center",
  },

  [theme.breakpoints.down("lg")]: {
    height: 58,
    width: 139,
    display: "flex",
    alignItems: "center",
  },
  [theme.breakpoints.down("md")]: {
    height: 58,
    width: 139,
    display: "flex",
    alignItems: "center",
  },
}));
const NumberSizePStyled = styled("p")(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: "white",
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 5,
  [theme.breakpoints.down("xl")]: {
    fontSize: 23,
    marginTop: 5,
    marginLeft: 10,
    width: 40,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 23,
    marginLeft: 5,
    width: 40,
  },
}));
const ExplanationTextPStyled = styled("p")(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: "white",
  marginTop: 0,
  marginRight: 0,
  marginBottom: 5,
  marginLeft: 5,
  width: 156,
  [theme.breakpoints.down("xl")]: {
    height: 30,
    marginLeft: 5,
    fontSize: 12,
    marginTop: 5,
    width: 121,
  },
  [theme.breakpoints.down("lg")]: {
    height: 20,
    marginLeft: 5,
    fontSize: 10,
    marginTop: 5,
    width: 139,
  },
  [theme.breakpoints.down("md")]: {
    height: 20,
    marginLeft: 5,
    fontSize: 10,
    marginTop: 5,
    width: 139,
    marginBottom: 0,
  },
}));
