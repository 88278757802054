import { Box, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import LeftsideCalculator from "../LeftSideCalculator/LeftSideCalculator";
import TopSideIpad from "../TopSide/TopSideIpad";
import { useTheme } from "@mui/material/styles";
import { DBContext } from "hooks/FirebaseContext";
import TopSideMobileCalc from "../TopSide/TopSideMobileCalc";

const LeftSideIntermediate = (props) => {
  const localFirebaseContext = useContext(DBContext);

  const theme = useTheme();
  const isXlOnly = useMediaQuery((theme) => theme.breakpoints.only("xl"));
  const islgUp = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const SelectLeftSide = () => {
    if (isXlOnly) {
      return (
        <LeftsideCalculator
          isPlayground={false}
          barChartFontSize="26px"
          diseaseName={localFirebaseContext.diseaseName}
          smallTitle="CHANCE OF"
        />
      );
    } else if (isMdUp) {
      return (
        <Box>
          <TopSideIpad
            isPlayground={false}
            diseaseName={localFirebaseContext.diseaseName}
            smallTitle="CHANCE OF"
            smallerMargins={props.smallerMargins}
          ></TopSideIpad>
        </Box>
      );
    } else if (isMdDown) {
      return (
        <Box>
          <TopSideMobileCalc
            isPlayground={false}
            diseaseName={localFirebaseContext.diseaseName}
            smallTitle="CHANCE OF"
            smallerMargins={props.smallerMargins}
            diseaseId={props.diseaseId}
          />
        </Box>
      );
    }
  };

  return <Box>{SelectLeftSide()}</Box>;
};
export default LeftSideIntermediate;
