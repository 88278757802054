import { Grid, Box } from "@mui/material";
import React, { useState } from "react";
import Rightside from "../RightSide/Rightside";
import { styled } from "@mui/material/styles";
import TopSideFloaterMobile from "../TopSideFloater/TopSideFloaterMobile";
import Copywrite from "../../GenericComponents/Copywrite/Copywrite";

const PlaygroundIpad = (props) => {
  const [smallerMargins, setSmallerMargins] = useState(false);
  return (
    <PlaygroundWrapperStyling container className="outerGrid">
      <TopOuterBoxMobile className="TopOuterBoxMobile">
        <TopSideFloaterMobile smallerMargins={setSmallerMargins} {...props}></TopSideFloaterMobile>
      </TopOuterBoxMobile>
      <BottomOuterBox
        sx={{ marginTop: smallerMargins ? "180px" : "426px" }}
        className="wrapperBoxToProvideMargin"
      >
        <Rightside></Rightside>
        <CopywriteStyling item container xl={12} lg={12} md={12} sm={12}>
          <Copywrite longMessage={false} />
        </CopywriteStyling>
      </BottomOuterBox>
    </PlaygroundWrapperStyling>
  );
};

export default PlaygroundIpad;
const CopywriteStyling = styled(Grid)(({ theme }) => ({
  backgroundColor: "#dce3e3",
}));
const PlaygroundWrapperStyling = styled(Grid)(({ theme }) => ({
  maxWidth: 768,
  width: "100%",
  marginRight: "auto",
  marginLeft: "auto",
}));

const TopOuterBoxMobile = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: 0,
  maxWidth: 768,
  width: "100%",
  marginRight: "auto",
  marginLeft: "auto",
  backgroundColor: "#707070",
  zIndex: 1000,
}));
const BottomOuterBox = styled(Box)(({ theme }) => ({
  zIndex: 10,
  maxWidth: 768,
  width: "100%",
  backgroundColor: "#DCE3E3",
}));
