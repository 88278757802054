//import '../Step.scss';
import "./StepTwo.css";

import React, { useEffect, useContext, useState } from "react";
import { DBContext } from "hooks/FirebaseContext";

import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import { styled } from "@mui/material/styles";

import clsx from "clsx";

import Avatar from "@material-ui/core/Avatar";
import { FormLabel, Grid, RadioGroup } from "@material-ui/core";
import { Box } from "@mui/system";
import ProbabilityBox from "../../ProbablilityBox/ProbabilityBox";
import StateAndCounty from "../../StateAndCounty/StateAndCounty";
import { Collapse } from "@mui/material";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { ExpandLess } from "@material-ui/icons";

const StepTwo = (props) => {
  const localFirebaseContext = useContext(DBContext);
  const [risksAndSymptomsList, setRisksAndSymptomsList] = useState([]);
  const [risksList, setRisksList] = useState("");
  const [symptomsList, setSymptomsList] = useState("");
  const [prevAndIncList, setPrevAndIncList] = useState("");
  const [prevOrIncChecked, setPrevOrIncChecked] = useState("");
  const [currentCheckedPrevOrInc, setCurrentCheckedPrevOrInc] = useState("");

  const [prevalenceOrIncidencesList, setPrevalenceanceOrIncidencesList] = useState("");
  const [openCloseStepTwo, setOpenCloseStepTwo] = useState(true);
  const [prevAndIncLabel, setPrevAndIncLabel] = useState("PREVALANCE");

  useEffect(() => {
    console.log("StepTwo: localFirebaseContext.diseaseId");

    _.find(localFirebaseContext.diseases, (value) => {
      if (value.id == localFirebaseContext.diseaseId) {
        setRisksList("");
        setSymptomsList("");
        setPrevAndIncList("");
        setRisksAndSymptomsList(value["risksAndSymptoms"]);
        if (value["prevalenceOrIncidences"] != undefined) {
          setPrevalenceanceOrIncidencesList(value["prevalenceOrIncidences"]);
          setPrevAndIncLabel(value.prevalenceOrIncidence);
        } else {
          setPrevalenceanceOrIncidencesList("");
        }
      }
    });
  }, [localFirebaseContext.diseaseId]);

  useEffect(() => {
    console.log("StepTwo: risksAndSymptomsList, localFirebaseContext.advanced");

    makePriorsList();
  }, [risksAndSymptomsList, localFirebaseContext.advanced]);

  useEffect(() => {
    console.log("StepTwo: prevOrIncChecked");

    makePrevanceOrIndincesList();
  }, [prevOrIncChecked]);
  useEffect(() => {
    console.log("StepTwo: prevalenceOrIncidencesList, localFirebaseContext.advanced");

    makePrevanceOrIndincesList();
  }, [prevalenceOrIncidencesList, localFirebaseContext.advanced]);

  useEffect(() => {
    console.log("StepTwo: localFirebaseContext.manualInput");

    if (localFirebaseContext.manualInput) {
      setPrevAndIncList("");
      setRisksList("");
      setSymptomsList("");
      setPrevOrIncChecked("");
      setCurrentCheckedPrevOrInc("");
    }
  }, [localFirebaseContext.manualInput]);

  useEffect(() => {
    console.log("StepTwo: risksList, symptomsList");

    if (risksList === "" || symptomsList === "") {
      makePriorsList();
    }
  }, [risksList, symptomsList]);

  useEffect(() => {
    console.log("StepTwo: prevAndIncList");

    if (prevAndIncList === "") {
      makePrevanceOrIndincesList();
    }
  }, [prevAndIncList]);

  const handleAdditionChange = (e) => {
    localFirebaseContext.setManualInput(false);
    let checkItem = { mathElement: "PR", value: null, id: null };
    if (e.target.type === "radio") {
      checkItem = {
        mathElement: "PR",
        value: e.target.getAttribute("radiovalue"),
        id: e.target.getAttribute("checkboxid"),
      };
    } else {
      checkItem = { mathElement: "PR", value: e.target.value, id: e.target.id };
    }

    if (
      currentCheckedPrevOrInc !== "" &&
      currentCheckedPrevOrInc.id !== e.target.id &&
      e.target.getAttribute("prevorind")
    ) {
      props.uncheckedPercentBox(currentCheckedPrevOrInc);
      setCurrentCheckedPrevOrInc(checkItem);
    } else if (e.target.getAttribute("prevorind")) {
      setCurrentCheckedPrevOrInc(checkItem);
    }
    if (e.target.checked === true) {
      props.checkedPercentBox(checkItem);
    } else {
      props.uncheckedPercentBox(checkItem);
    }
  };

  const handleMultiplcationChange = (e) => {
    localFirebaseContext.setManualInput(false);

    let checkItem = { mathElement: "OR", value: null, id: null };
    if (e.target.type === "radio") {
      checkItem = {
        mathElement: "OR",
        value: e.target.getAttribute("radiovalue"),
        id: e.target.getAttribute("checkboxid"),
      };
    } else {
      checkItem = { mathElement: "OR", value: e.target.value, id: e.target.id };
    }

    if (
      currentCheckedPrevOrInc !== "" &&
      currentCheckedPrevOrInc.id !== e.target.id &&
      e.target.getAttribute("prevorind")
    ) {
      props.uncheckedORBox(currentCheckedPrevOrInc);
      setCurrentCheckedPrevOrInc(checkItem);
    } else if (e.target.getAttribute("prevorind")) {
      setCurrentCheckedPrevOrInc(checkItem);
    }

    if (e.target.checked == true) {
      props.checkedORBox(checkItem);
    } else {
      props.uncheckedORBox(checkItem);
    }
  };

  const prevOrIncCheckedChanged = (event) => {
    if (prevOrIncChecked === event.target.getAttribute("checkboxid")) {
      setPrevOrIncChecked(false);
    } else {
      setPrevOrIncChecked(event.target.getAttribute("checkboxid"));
    }
    if (event.target.getAttribute("oddsorpercent") === "OR") {
      handleMultiplcationChange(event);
    } else {
      handleAdditionChange(event);
    }
  };

  const makePrevanceOrIndincesList = () => {
    setPrevAndIncList("");
    if (prevalenceOrIncidencesList !== undefined) {
      _.forEach(prevalenceOrIncidencesList, (value) => {
        let nextListItem = "";

        if (value !== undefined) {
          let basicOrAdvanced = false;
          if (value.basicOrAdvanced === "advanced" && localFirebaseContext.advanced) {
            basicOrAdvanced = true;
          } else if (value.basicOrAdvanced === "basic") {
            basicOrAdvanced = true;
          }

          if (basicOrAdvanced) {
            nextListItem = (
              <Box key={value.id + value.id}>
                <CheckboxSymptomsFormControlLabelStyling
                  className="CheckboxSymptomsFormControlLabelStyling"
                  key={value.id}
                  control={
                    <CheckboxStyling
                      icon={<CheckboxIconSpanStyling className="CheckboxIconSpanStyling" />}
                      checkedIcon={
                        <CheckboxCheckedIconSpanStyling className="CheckboxCheckedIconSpanStyling" />
                      }
                      color="primary"
                      className="CheckboxStyling}"
                      onChange={prevOrIncCheckedChanged}
                      checked={prevOrIncChecked == value.id}
                      name={value.name}
                      id={value.id}
                      inputProps={{
                        "aria-label": "A",
                        oddsorpercent: value.oddOrPercent,
                        checkboxid: value.id,
                        prevorind: "true",
                      }}
                      value={value.probability}
                      key={value.id + value.name}
                    />
                  }
                  label={value.name}
                />

                <RadioGroup
                  aria-label="makePrevanceOrIndincesList"
                  name="makePrevanceOrIndincesList-group"
                  row
                  key={value.name + value.id}
                  className="RadioGroupIndentRadioButtons"
                  style={{
                    display: value.subRisk !== undefined ? "flex" : "none",
                  }}
                >
                  {value.subRisk !== undefined &&
                    value.subRisk.map((spec) => (
                      // eslint-disable-next-line react/jsx-key
                      <FormControlLabel
                        key={value.id + ":" + spec.id}
                        control={
                          <Radio
                            className="RadioHover"
                            disableRipple
                            checkedIcon={
                              <RadioCheckedIconSpanStyling className="RadioCheckedIconSpanStyling" />
                            }
                            icon={<RadioIconSpanStyling className="RadioIconSpanStyling" />}
                            color="default"
                            onChange={
                              value.oddOrPercent === "OR"
                                ? handleMultiplcationChange
                                : handleAdditionChange
                            }
                            style={{ paddingLeft: 4, paddingRight: 4 }}
                            name={"radio-button-" + value.id + "" + spec.id}
                            inputProps={{
                              "aria-label": "A",
                              radioid: spec.id,
                              checkboxid: value.id,
                              radiovalue: spec.probability,
                            }}
                            value={spec.id}
                            key={"" + spec.id + ":" + value.id}
                          />
                        }
                        label={
                          <span style={{ color: "#20494C", fontWeight: 600 }}>{"placeholder"}</span>
                        }
                      ></FormControlLabel>
                    ))}
                </RadioGroup>
              </Box>
            );
            setPrevAndIncList((prevAndIncList) => [...prevAndIncList, nextListItem]);
          }
        }
      });
    } else {
      const emptyList = <div></div>;
      setPrevAndIncList((prevAndIncList) => [...prevAndIncList, emptyList]);
    }
  };

  const makePriorsList = () => {
    setRisksList("");
    setSymptomsList("");
    _.forEach(risksAndSymptomsList, (value) => {
      let nextListItem = "";

      if (value !== undefined) {
        let basicOrAdvanced = false;
        if (value.basicOrAdvanced === "advanced" && localFirebaseContext.advanced) {
          basicOrAdvanced = true;
        } else if (value.basicOrAdvanced === "basic") {
          basicOrAdvanced = true;
        }

        if (basicOrAdvanced) {
          nextListItem = (
            <Box key={value.name + value.name}>
              <FormControl key={value.id + value.id}>
                {value.subRisk === undefined && (
                  <CheckboxSymptomsFormControlLabelStyling
                    className="CheckboxSymptomsFormControlLabelStyling"
                    key={value.id}
                    control={
                      <CheckboxStyling
                        icon={<CheckboxIconSpanStyling className="CheckboxIconSpanStyling" />}
                        checkedIcon={
                          <CheckboxCheckedIconSpanStyling className="CheckboxCheckedIconSpanStyling" />
                        }
                        color="primary"
                        className="CheckboxStyling"
                        onChange={
                          value.oddOrPercent === "OR"
                            ? handleMultiplcationChange
                            : handleAdditionChange
                        }
                        name={value.name}
                        id={value.id}
                        value={value.probability}
                        disabled={value.subRisk !== undefined ? true : false}
                      />
                    }
                    label={value.name}
                  />
                )}
              </FormControl>
              {value.subRisk !== undefined && (
                <FormControlSubRiskStyling
                  keu={value.id + ":" + value.id}
                  component="fieldset"
                  className="FormControlSubRiskStyling"
                >
                  <FormLabel component="legend" key={value.name + value.name}>
                    {value.name}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="makePrevanceOrIndincesList"
                    name="makePrevanceOrIndincesList-group"
                    className="RadioGroupIndentRadioButtons"
                  >
                    {value.subRisk.map((spec) => (
                      <FormControlLabel
                        key={value.id + ":" + spec.id}
                        control={
                          <Radio
                            className="RadioHover"
                            disableRipple
                            checkedIcon={
                              <RadioCheckedIconSpanStyling className="RadioCheckedIconSpanStyling" />
                            }
                            icon={<RadioIconSpanStyling className="RadioIconSpanStyling" />}
                            color="default"
                            onChange={
                              value.oddOrPercent === "OR"
                                ? handleMultiplcationChange
                                : handleAdditionChange
                            }
                            style={{ paddingLeft: 4, paddingRight: 4 }}
                            name={"radio-button-" + value.id + "" + spec.id}
                            inputProps={{
                              "aria-label": "A",
                              radioid: spec.id,
                              checkboxid: value.id,
                              radiovalue: spec.probability,
                            }}
                            value={spec.id}
                            key={"" + spec.id + ":" + value.id}
                          />
                        }
                        label={spec.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControlSubRiskStyling>
              )}
            </Box>
          );
          if (value.symptom === true) {
            setSymptomsList((symptomsList) => [...symptomsList, nextListItem]);
          } else {
            setRisksList((risksList) => [...risksList, nextListItem]);
          }
        }
      }
    });
  };

  const hideReveal = () => {
    setOpenCloseStepTwo(!openCloseStepTwo);
  };

  return (
    <StepTwoGridStyling container className="StepTwoGridStyling">
      <Grid item md={1} className="numberGrid">
        <AvatarColorAndSizeStyling className="AvatarColorAndSizeStyling">
          2
        </AvatarColorAndSizeStyling>
      </Grid>
      <MediaMarginsBoxStyling className="MediaMarginsBoxStyling">
        <StepTwoBodyGridStyling item container md={11} className="StepTwoBodyGridStyling">
          <Grid item md={12}>
            <TopLineStyling className="TopLineStyling" />
          </Grid>
          <Grid item container md={12} className="stepTwoTitleWrapperGrid">
            <StepTwoTitleGridStyling item md={8} className="StepTwoTitleGridStyling">
              <div className="stepTitleWrapper">
                <StepTwoTitlePStyling className="StepTwoTitlePStyling">
                  What is the pre-test probability?
                </StepTwoTitlePStyling>

                {openCloseStepTwo ? (
                  <ExpandLessStyling onClick={hideReveal} className="ExpandLessStyling" />
                ) : (
                  <ExpandMoreStyling onClick={hideReveal} className="ExpandMoreStyling" />
                )}
              </div>
            </StepTwoTitleGridStyling>
          </Grid>
          <Collapse in={openCloseStepTwo}>
            <Grid container>
              <Box
                display={{
                  display: localFirebaseContext.isDiseaseInfectious === true ? "Grid" : "none",
                }}
              >
                <SectionsGridWidthStyling item md={12} className="SectionsGridWidthStyling">
                  <StateAndCounty
                    updateStateAndCounty={props.updateStateAndCounty}
                    changedStateAndCounty={props.changedStateAndCounty}
                  ></StateAndCounty>
                </SectionsGridWidthStyling>
              </Box>
              <Grid item container md={6} className={clsx("stepTwoRightSideGrid")}>
                <SectionsBoxWidthStyling
                  display={{
                    display:
                      risksAndSymptomsList === undefined ||
                      localFirebaseContext.diseaseId === undefined
                        ? "none"
                        : "block",
                  }}
                  className="SectionsBoxWidthStyling"
                >
                  <Box
                    display={{
                      display:
                        localFirebaseContext.isDiseaseInfectious === false ? "block" : "none",
                    }}
                  >
                    <Grid item md={12} className="stepTwoPrevalenceGrid">
                      <div className="exposureAndSymptomTitle">
                        <p>{prevAndIncLabel}</p>
                      </div>
                      <FormControlMarginStyling
                        component="fieldset"
                        className="FormControlMarginStyling"
                      >
                        <FormGroup>{prevAndIncList}</FormGroup>
                      </FormControlMarginStyling>
                    </Grid>
                  </Box>
                  <Grid item md={12} className="stepTwoRiskFactorsGrid">
                    <div className="exposureAndSymptomTitle">
                      <p>EXPOSURE RISK</p>
                    </div>

                    <FormControlMarginStyling
                      component="fieldset"
                      className="FormControlMarginStyling"
                    >
                      <FormGroup>{risksList}</FormGroup>
                    </FormControlMarginStyling>
                  </Grid>
                </SectionsBoxWidthStyling>
              </Grid>
              <Grid item container md={6} className={clsx("stepTwoLeftSideGrid")}>
                <SectionsBoxWidthStyling
                  display={{
                    display:
                      risksAndSymptomsList === undefined ||
                      localFirebaseContext.diseaseId === undefined
                        ? "none"
                        : "block",
                  }}
                >
                  <StepTwoSymptomsSideGridStyling item md={12} className="stepTwoSymptomsSideGrid">
                    <div className="exposureAndSymptomTitle">
                      <p>SYMPTOMS</p>
                    </div>
                    <MediaShrinkSymptomsFormControlStyling
                      component="fieldset"
                      className="MediaShrinkSymptomsFormControlStyling"
                    >
                      <FormGroup>{symptomsList}</FormGroup>
                    </MediaShrinkSymptomsFormControlStyling>
                  </StepTwoSymptomsSideGridStyling>
                </SectionsBoxWidthStyling>
              </Grid>
              <Grid
                item
                md={12}
                component={Box}
                display={{
                  display: risksAndSymptomsList === undefined ? "block" : "none",
                }}
              >
                <p>Update coming soon!</p>
                <p>
                  Consider incidence/prevalence,risk factors and symptoms to estimate pretest
                  probablilty
                </p>
              </Grid>
              <Grid item md={12}>
                <ProbabilityBox
                  unCheckOnManualUpdate={props.uncheckOnManualUpdate}
                  className="Probability Box"
                ></ProbabilityBox>
              </Grid>
            </Grid>
          </Collapse>
        </StepTwoBodyGridStyling>
      </MediaMarginsBoxStyling>
    </StepTwoGridStyling>
  );
};

export default StepTwo;
const StepTwoBodyGridStyling = styled(Grid)(({ theme }) => ({
  minWidth: 646,
  [theme.breakpoints.down("xl")]: {
    width: 626,
  },
  [theme.breakpoints.down("lg")]: {
    minWidth: 496,
  },
  [theme.breakpoints.down("md")]: {
    width: 290,
    minWidth: 290,
  },
}));
const StepTwoSymptomsSideGridStyling = styled(Grid)(({ theme }) => ({
  marginLeft: 8,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 0,
  },
}));
const MediaMarginsBoxStyling = styled(Box)(({ theme }) => ({
  width: 646,

  [theme.breakpoints.down("xl")]: {
    marginLeft: 0,
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    marginLeft: 16,
    minWidth: 646,
  },
  [theme.breakpoints.down("md")]: {
    minWidth: 290,
  },
}));
const MediaShrinkSymptomsFormControlStyling = styled(FormControl)(({ theme }) => ({
  marginLeft: 16,
  [theme.breakpoints.down("xl")]: {
    width: 255,
    marginLeft: 20,
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: 8,
  },
  [theme.breakpoints.down("lg")]: {
    marginLeft: 8,
  },
}));
const StepTwoTitleGridStyling = styled(Grid)(({ theme }) => ({
  minWidth: "100%",
  [theme.breakpoints.down("lg")]: {
    minWidth: 646,
  },
  [theme.breakpoints.down("md")]: {
    width: 290,
    marginLeft: 0,
    minWidth: 290,
  },
}));
const SectionsGridWidthStyling = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: 290,
    marginLeft: 5,
  },
}));

const SectionsBoxWidthStyling = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: 290,
    marginLeft: 0,
  },
}));
const FormControlMarginStyling = styled(FormControl)(({ theme }) => ({
  margin: 0,
  marginLeft: 8,
}));
const CheckboxStyling = styled(Checkbox)(({ theme }) => ({
  paddingRight: 8,
  marginLeft: 0,
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
  },
}));
const CheckboxSymptomsFormControlLabelStyling = styled(FormControlLabel)(({ theme }) => ({
  width: 290,
  marginBottom: 5,
  fontSize: 20,
}));
const FormControlSubRiskStyling = styled(FormControl)(({ theme }) => ({
  paddingLeft: 34,
}));
const TopLineStyling = styled("div")(({ theme }) => ({
  borderStyle: "solid none none none",
  borderWidth: 2,
  [theme.breakpoints.down("lg")]: {
    minWidth: 646,
  },
  [theme.breakpoints.down("md")]: {
    minWidth: 290,
    marginLeft: 0,
  },
}));
const StepTwoGridStyling = styled(Grid)(({ theme }) => ({
  borderWidth: 2,
  flexFlow: "row",
  [theme.breakpoints.down("lg")]: {
    minWidth: 700,
  },
  [theme.breakpoints.down("md")]: {
    minWidth: 290,
    marginLeft: 5,
  },
}));
const AvatarColorAndSizeStyling = styled(Avatar)(({ theme }) => ({
  width: "46px",
  height: "46px",
  float: "left",
  backgroundColor: "#81A2A4",
  fontWeight: 900,
  marginTop: "-5px",
  [theme.breakpoints.down("md")]: {
    width: "28px",
    height: "28px",
    fontSize: 18,
  },
}));
const StepTwoTitlePStyling = styled("p")(({ theme }) => ({
  marginBottom: 0,
  color: "#262420",
  fontSize: 24,
  marginTop: 5,
  fontWeight: 700,
  float: "left",
  [theme.breakpoints.down("md")]: {
    marginLeft: 6,
    fontSize: 14,
    marginTop: 5,
  },
}));

const ExpandLessStyling = styled(ExpandLess)(({ theme }) => ({
  float: "right",
  fontSize: 40,
  [theme.breakpoints.down("md")]: { float: "right", fontSize: 24 },
}));
const ExpandMoreStyling = styled(ExpandMore)(({ theme }) => ({
  float: "right",
  fontSize: 40,
  [theme.breakpoints.down("lg")]: { float: "right", fontSize: 24 },
}));
const CheckboxIconSpanStyling = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#98B4B6",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const CheckboxCheckedIconSpanStyling = styled("span")(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.primary.light,
  },
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const RadioIconSpanStyling = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#FFFFFF",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#98B4B6",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const RadioCheckedIconSpanStyling = styled("span")(({ theme }) => ({
  borderRadius: "50%",

  backgroundColor: "#00FFF0",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#1B97A4",
  },
}));
