import React, { useContext } from "react";
import { Box, Button, Grid } from "@mui/material";
import { DBContext } from "../../../hooks/FirebaseContext";
import { styled } from "@mui/material/styles";

const ResetButton = (props) => {
  const localFirebaseContext = useContext(DBContext);

  const restCalculator = () => {
    localFirebaseContext.setReset(!localFirebaseContext.isReset);
  };

  return (
    <WrapperGridStyling item className="resetButtonWrapper">
      <BoxStyling className="boxWrapper">
        <ButtonStyling
          variant="contained"
          className="resetButton"
          onClick={restCalculator}
        >
          Reset
        </ButtonStyling>
      </BoxStyling>
    </WrapperGridStyling>
  );
};

export default ResetButton;

const BoxStyling = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
const ButtonStyling = styled(Button)(({ theme }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  fontWeight: 700,
  fontSize: 16,
  height: 44,
  width: 140,
  marginBottom: 30,
  backgroundColor: "#46443D"
}));

const WrapperGridStyling = styled(Grid)(({ theme }) => ({
  marginTop: 20,
  marginBottom: 30,
}));
