import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import Slider from '@mui/material/Slider';
import { DBContext } from '../../../hooks/FirebaseContext';
import { styled } from '@mui/material/styles';
import './SliderElement.css';
import { Typography } from '@material-ui/core';
import SliderTooltip from 'components/CalculatorComponents/ToolTips/SliderToolTips';

const SliderElement = (props) => {
    const localFirebaseContext = useContext(DBContext);
    const [localValue, setLocalValue] = useState(props.defaultValue);
    const [sliderValue, setSliderValue] = useState(props.defaultValue);

    const marks = [
        {
            value: 0,
            label: '0%'
        },
        { value: 50, label: '50%' },
        { value: 100, label: '100%' }
    ];
    useEffect(() => {
        localFirebaseContext.setReset(!localFirebaseContext.isReset);
    }, []);

    useEffect(() => {
        const startingNumber = props.defaultValue;
        const event = { target: { value: startingNumber } };
        updateValue(event);
    }, [localFirebaseContext.isReset]);

    const updateValue = (event) => {
        let manualValue = event.target.value;

        if (manualValue % 1 !== 0) {
            var regEx = manualValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            manualValue = parseFloat(regEx);
        } else {
            manualValue = parseInt(manualValue);
        }

        if (sliderValue !== manualValue) {
            setSliderValue(manualValue);
        }

        if (manualValue <= 101 && manualValue >= 0) {
            setLocalValue(manualValue);

            props.valueInput(manualValue / 100);
        }
    };
    return (
        <WrapperGridStyling container sx={{ width: '100%' }} className='SliderElement'>
            <Grid item md={11} sm={8}>
                <SliderLabelStyling>
                    {props.textBoxLabel}
                    <SliderTooltip toolTitle={props.toolTitle} />
                </SliderLabelStyling>
            </Grid>

            <Grid item lg={2} md={2}>
                <TextFieldInputStyling
                    id='standard-number'
                    type='number'
                    className='textFieldInput'
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>%</InputAdornment>
                    }}
                    value={localValue}
                    onInput={updateValue}
                    variant='outlined'
                    size='small'
                />
            </Grid>
            <Grid item lg={9} md={10} sm={12} className='GridSliderWrapper'>
                <SliderStyling
                    id='slider'
                    defaultValue={sliderValue}
                    value={sliderValue}
                    aria-label='Default'
                    valueLabelDisplay='auto'
                    marks={marks}
                    onChange={updateValue}
                />
            </Grid>
        </WrapperGridStyling>
    );
};

export default SliderElement;

const TextFieldInputStyling = styled(TextField)(({ theme }) => ({
    width: '74px',
    height: '46px',
    fontWeight: 900,

    [theme.breakpoints.down('md')]: {
        width: '72px',
        height: '38px',
        fontSize: 24
    }
}));

const SliderLabelStyling = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: '#2B4F53',
    fontWeight: 900,
    marginBottom: 10
}));
const SliderStyling = styled(Slider)(({ theme }) => ({
    borderRadius: 0,
    height: 10,
    color: '#2C4F53',
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#82A2A4'
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: 10,
        width: 'calc(100% - 10px)'
    }
}));

const WrapperGridStyling = styled(Grid)(({ theme }) => ({
    marginLeft: 80,
    marginTop: 20,
    [theme.breakpoints.down('lg')]: {
        marginLeft: 55
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: 35
    }
}));
