import { Box, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { DBContext } from "hooks/FirebaseContext";

import {
  falseNegativeImage,
  falsePositiveImage,
  trueNegativeImage,
  truePositiveImage,
} from "assets";
import { getResultBasedOnSelection } from "util/calculator";

const MatrixGrid = (props) => {
  const localFirebaseContext = useContext(DBContext);
  const trueNegImageTage = (
    <img
      src={trueNegativeImage}
      alt="true-negative"
      width={props.imageHeightAndWidth}
      height={props.imageHeightAndWidth}
    />
  );
  const truePosImageTag = (
    <img
      src={truePositiveImage}
      alt="true-positive"
      width={props.imageHeightAndWidth}
      height={props.imageHeightAndWidth}
    />
  );
  const falsePosImageTag = (
    <img
      src={falsePositiveImage}
      alt="false-positive"
      width={props.imageHeightAndWidth}
      height={props.imageHeightAndWidth}
    />
  );
  const falseNegImageTag = (
    <img
      src={falseNegativeImage}
      alt="false-negative"
      width={props.imageHeightAndWidth}
      height={props.imageHeightAndWidth}
    />
  );

  const getDotsForAll = (beforeTestProbability, resultType, test) => {
    console.log("getDotsForAll MatrixGrid.js", getResultBasedOnSelection);
    const { numTruePos, numFalsePos, numTrueNeg, numFalseNeg } = getResultBasedOnSelection({
      resultType,
      beforeTestProbability,
      ...test,
    });

    const truePosDots = Array(numTruePos).fill(truePosImageTag);
    const falsePosDots = Array(numFalsePos).fill(falsePosImageTag);
    const falseNegDots = Array(numFalseNeg).fill(falseNegImageTag);

    return {
      dotClasses: truePosDots.concat(falsePosDots, falseNegDots),
      defaultClass: trueNegImageTage,
    };
  };

  const getDotsForPosNeg = (resultType, beforeTestProbability, test) => {
    console.log("getDotsForPosNeg matrix grid", getResultBasedOnSelection);
    const calculationResults = getResultBasedOnSelection({
      resultType,
      beforeTestProbability,
      ...test,
    });
    const numMajor =
      resultType === "positive" ? calculationResults.numTrue : 100 - calculationResults.numTrue;

    const majorClass = resultType === "positive" ? truePosImageTag : falseNegImageTag;
    const minorClass = resultType === "positive" ? falsePosImageTag : trueNegImageTage;
    return {
      dotClasses: Array(numMajor).fill(majorClass),
      defaultClass: minorClass,
    };
  };

  const setResultTypeIfPlayground = () => {
    console.log("!!props.isPlayground", props.isPlayground);
    if (!!props.isPlayground) {
      return "all";
    } else {
      return localFirebaseContext.resultType;
    }
  };

  const getDots = () => {
    const beforeTestProbability = localFirebaseContext.beforeTestProbability;
    const resultType = setResultTypeIfPlayground();
    let test = {
      beforeTestProbability: localFirebaseContext.beforeTestProbability,
      sensitivity: localFirebaseContext.selectedTestSensitivity,
      specificity: localFirebaseContext.selectedTestSpecificity,
    };

    if (resultType === "all") {
      return getDotsForAll(beforeTestProbability, resultType, test);
    }
    return getDotsForPosNeg(resultType, beforeTestProbability, test);
  };

  const matrixGenerator = ({ calculationResults }) => {
    const { dotClasses, defaultClass } = getDots({
      calculationResults,
    });
    const heightOfRow = props.imageHeightAndWidth + props.imagePaddingTop;
    let matrix = [];

    for (let rowCount = 0; rowCount < 10; rowCount++) {
      let row = [];

      for (let colCount = 0; colCount < 10; colCount++) {
        const dotClass = dotClasses.shift() || defaultClass;
        row.push(
          <span
            key={`${rowCount}${colCount}`}
            style={{
              display: "inline-block",
              paddingLeft: props.imagePaddingLeft,
              paddingTop: props.imagePaddingTop,
              width: props.imageHeightAndWidth,
              height: props.imageHeightAndWidth,
            }}
          >
            {dotClass}
          </span>
        );
      }

      // Create the parent and add the children
      matrix.push(
        <div key={rowCount} className="matrix-row">
          {row}
        </div>
      );
    }
    return matrix;
  };

  const matrix = matrixGenerator(props);

  return (
    <Box>
      <Grid item xl={12} lg={12} md={12} sm={12}>
        {matrix}
      </Grid>
    </Box>
  );
};

export default MatrixGrid;
