import palette from "./palette";
import typography from "./typography";
import overrides from "./overrides";
//import breakpoints from "./breakpoints";

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette,
  typography: {
    fontFamily: '"Open Sans",Papyrus , Arial, sans-serif',
    h1: {
      color: palette.text.primary,
      fontWeight: 400,
      fontSize: '35px',
      letterSpacing: '-0.24px',
      lineHeight: '40px',
    },
    h2: {
      color: palette.text.primary,
      fontWeight: 400,
      fontSize: '29px',
      letterSpacing: '-0.24px',
      lineHeight: '32px',
    },
    h3: {
      color: palette.text.primary,
      fontWeight: 400,
      fontSize: '24px',
      letterSpacing: '-0.06px',
      lineHeight: '28px',
    },
    h4: {
      color: palette.text.primary,
      fontWeight: 400,
      fontSize: '20px',
      letterSpacing: '-0.06px',
      lineHeight: '24px',
    },
    h5: {
      color: palette.text.primary,
      fontWeight: 400,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    },
    h6: {
      color: palette.text.primary,
      fontWeight: 400,
      fontSize: '14px',
    },
    subtitle1: {
      color: palette.text.primary,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '25px',
    },
    subtitle2: {
      color: palette.text.secondary,
      fontWeight: 300,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px',
    },
    body1: {
      color: palette.text.primary,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px',
    },
    body2: {
      color: palette.text.secondary,
      fontSize: '12px',
      letterSpacing: '-0.04px',
      lineHeight: '18px',
    },
    button: {
      color: palette.text.primary,
      fontWeight: 400,
      fontSize: '14px',
    },
    caption: {
      color: palette.text.secondary,
      fontSize: '11px',
      letterSpacing: '0.33px',
      lineHeight: '13px',
    },
    overline: {
      color: palette.text.secondary,
      fontSize: '11px',
      fontWeight: 400,
      letterSpacing: '0.33px',
      lineHeight: '13px',
      textTransform: 'uppercase',
    },
  },
  overrides,
  breakpoints: {
    values: {
      xs: 0,
      sm: 390,
      md: 768,
      lg: 1024,
      xl: 1200,
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      size: "small",
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiLink: {
      underline: "hover",
      color: "textSecondary",
    },
    MuiButton: {
      disableElevation: true,
    },
  },
});

export default theme;
