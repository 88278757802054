/*
For reference:

|        | Actual + | Actual - |
|--------|----------|----------|
| Test + | a        | b        |
| Test - | c        | d        |

|        |  Actual + |  Actual - |
|--------|-----------|-----------|
| Test + | True Pos  | False Pos |
| Test - | False Neg | True Neg  |


prevalentCases = numPeopleTested * beforeTestProbability

                                                                         ↓ Calculations from constants rather than derived counts
a = numTruePos  = prevalentCases * sensitivity
b = numFalsePos = (numPeopleTested - prevalentCases) - d                 = numPeopleTested * (1 − beforeTestProbability) * (1 − specificity)
c = numFalseNeg = prevalentCases - a                                     = specificity * (numPeopleTested - prevalentCases)
d = numTrueNeg  = (numberPeopleTested - prevalentCases) * specificity

PPV = a/(a+b) =  (sensitivity * prevalentCases)/((sensitivity * prevalentCases) +(numPeopleTested(1 − beforeTestProbability)(1 − specificity)))
              =  (beforeTestProbability * sensitivity) / (beforeTestProbability * sensitivity + (1 − beforeTestProbability) * (1 − specificity))

NPV = d/(c+d) = (specificity(numPeopleTested −  prevalentCases)) / (specificity(numPeopleTested − prevalentCases) + prevalentCases(1 − sensitivity))
              = ((1 - beforeTestProbability) * specificity) / ((1 - beforeTestProbability) * specificity + beforeTestProbability * (1 - sensitivity))
*/

/**
 * Calculate 2x2 contingency table given n, prior probability, sensitivity, and specificity.
 *
 * @param {object} options
 * @param {number} options.numPeopleTested number of people tested
 * @param {number} options.beforeTestProbability beforeTestProbability probability of disease (0 - 1)
 * @param {number} options.sensitivity test sensitivity (0 - 1)
 * @param {number} options.specificity test specificity (0 - 1)
 */

export function calculateContingencyTable({
  numPeopleTested = 100,
  beforeTestProbability,
  sensitivity,
  specificity,
}) {
  // Calculate the number people who actually have the disease based on the prior probability
  const numActualPos = Math.round(numPeopleTested * beforeTestProbability);
  const numActualNeg = numPeopleTested - numActualPos;

  // If you tested numPeopleTested number of people...
  const numTruePos = Math.round(numActualPos * sensitivity);
  const numTrueNeg = Math.round(numActualNeg * specificity);

  const numFalsePos = numActualNeg - numTrueNeg;
  const numFalseNeg = numActualPos - numTruePos;

  return {
    numTruePos,
    numFalsePos,
    numTrueNeg,
    numFalseNeg,
  };
}

/**
 * Calculate the Positive Predictive Value (given testing positive, the probability of actually having disease), and the number of true and false positives if a given number of people test positive
 *
 * @param {object} options
 * @param {number} options.numPeopleTested number of people tested
 * @param {number} options.beforeTestProbability beforeTestProbability probability of disease (0 - 1)
 * @param {number} options.sensitivity test sensitivity (0 - 1)
 * @param {number} options.specificity test specificity (0 - 1)
 */
export function calculatePositivePredictiveValueAndCounts({
  numPeopleTested = 100,
  beforeTestProbability,
  sensitivity,
  specificity,
}) {
  // PPV = Positive Predictive Value = probability positive test means patient actually has disease
  let ppv =
    (beforeTestProbability * sensitivity) /
    (beforeTestProbability * sensitivity +
      (1 - beforeTestProbability) * (1 - specificity));
  console.log("ppv: ", ppv);

  ppv = isNaN(ppv) ? 0 : ppv;

  // How many true positives if `numPeopleTested` receive a POSITIVE test?
  const numTrue = Math.round(numPeopleTested * ppv);

  return {
    // Probability of actually having disease after test
    resultProbability: ppv,
    numFalse: numPeopleTested - numTrue,
    numTrue,
  };
}

/**
 * Calculate the Negative Predictive Value (given testing negative, the probability of not actually having disease), and the number of true and false negatives if a given number of people test negative
 *
 * @param {object} options
 * @param {number} options.numPeopleTested number of people tested
 * @param {number} options.beforeTestProbability beforeTestProbability probability of disease (0 - 1)
 * @param {number} options.sensitivity test sensitivity (0 - 1)
 * @param {number} options.specificity test specificity (0 - 1)
 */
export function calculateNegativePredictiveValueAndCounts({
  numPeopleTested = 100,
  beforeTestProbability,
  sensitivity,
  specificity,
}) {
  // NPV = Negative Predictive Value = probability negative test means the patient actually does not have disease
  let npv =
    ((1 - beforeTestProbability) * specificity) /
    ((1 - beforeTestProbability) * specificity +
      beforeTestProbability * (1 - sensitivity));
  console.log("npv: ", npv);

  npv = isNaN(npv) ? 0 : npv;

  // How many true negatives if `numPeopleTested` receive a NEGATIVE test?
  const numTrue = Math.round(numPeopleTested * npv);

  return {
    // Probability of actually having disease after test
    resultProbability: 1 - npv,
    numFalse: numPeopleTested - numTrue,
    numTrue,
  };
}

export function noTestSelected({
  numPeopleTested = 100,
  beforeTestProbability,
}) {
  // NPV = Negative Predictive Value = probability negative test means the patient actually does not have disease
  let npv =
    ((1 - beforeTestProbability) * 1) /
    ((1 - beforeTestProbability) * 1 + beforeTestProbability * (1 - 0));
  console.log("no test: ", npv);
  npv = isNaN(npv) ? 0 : npv;

  // How many true negatives if `numPeopleTested` receive a NEGATIVE test?
  const numTrue = Math.round(numPeopleTested * npv);

  return {
    // Probability of actually having disease after test
    resultProbability: 1 - npv,
    numFalse: numPeopleTested - numTrue,
    numTrue,
  };
}

/**
 * Calculate the positive likelihood ratio.
 *
 * @param {object} options
 * @param {number} options.sensitivity test sensitivity (0 - 1)
 * @param {number} options.specificity test specificity (0 - 1)
 */
export function positiveLikelihoodRatio({ sensitivity, specificity }) {
  return sensitivity / (1 - specificity);
}

/**
 * Calculate the negative likelihood ratio.
 *
 * @param {object} options
 * @param {number} options.sensitivity test sensitivity (0 - 1)
 * @param {number} options.specificity test specificity (0 - 1)
 */
export function negativeLikelihoodRatio({ sensitivity, specificity }) {
  return (1 - sensitivity) / specificity;
}

export function getResultBasedOnSelection({
  resultType,
  numPeopleTested = 100,
  beforeTestProbability,
  sensitivity,
  specificity,
}) {
  const test = {
    resultType: resultType,
    numPeopleTested: numPeopleTested,
    beforeTestProbability: beforeTestProbability,
    sensitivity: sensitivity,
    specificity: specificity,
  };
  console.log("test", test);
  if (resultType === "all") {
    return calculateContingencyTable({ ...test });
  } else if (resultType === "positive") {
    return calculatePositivePredictiveValueAndCounts({ ...test });
  } else if (resultType === "negative") {
    return calculateNegativePredictiveValueAndCounts({ ...test });
  } else {
    return noTestSelected((numPeopleTested = 100), beforeTestProbability);
  }
}
