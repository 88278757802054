import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import InputLabel from "@material-ui/core/InputLabel";
import clsx from "clsx";
import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { styled } from "@mui/material/styles";

import { DBContext } from "../../../hooks/FirebaseContext";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const StateAndCounty = (props) => {
  const localFirebaseContext = useContext(DBContext);
  const [listItems, setListItems] = useState("");
  const [countiesForState, setCountiesForState] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [currentStateCounty, setCurrentStateCounty] = useState([]);
  const [currentMulitplierSelected, setCurrentMulitplierSelected] = useState("2");
  const [currentStateCountyProb, setCurrentStateCountyProb] = useState(0);

  useEffect(() => {
    console.log("StateAndCounty: localFirebaseContext.manualInput");

    if (localFirebaseContext.manualInput) {
      setSelectedState("");
      setSelectedCounty("");
      setCurrentStateCounty([]);
    }
  }, [localFirebaseContext.manualInput]);

  useEffect(() => {
    console.log("StateAndCounty: localFirebaseContext.diseaseId");

    setSelectedState("");
    setSelectedCounty("");
    setCurrentStateCounty([]);
  }, [localFirebaseContext.diseaseId]);

  useEffect(() => {
    console.log("StateAndCounty: localFirebaseContext.getStateAndCountyData");

    setListItems("");
    const stateAndCountyData = localFirebaseContext.getStateAndCountyData;
    let localStates = [];
    if (stateAndCountyData !== undefined && stateAndCountyData.length > 0) {
      stateAndCountyData.map((k) => {
        let stateInList = false;
        _.find(localStates, (o) => {
          if (o.State == k.State) {
            stateInList = true;
          }
        });
        if (!stateInList) {
          localStates = [...localStates, k];
        }
      });
    }
    _.forEach(localStates, (value) => {
      const nextListItem = <option key={value.State}>{value.State}</option>;
      setListItems((listItems) => [...listItems, nextListItem]);
    });
  }, [localFirebaseContext.getStateAndCountyData]);

  const selectCounty = (event) => {
    if (event.target.value === "") {
      props.changedStateAndCounty(currentStateCounty);
      setCurrentStateCounty([]);
    }
    if (selectedState !== "") {
      props.changedStateAndCounty(currentStateCounty);
      setSelectedCounty("");
    }
    setCountiesForState("");
    const stateAndCountyData = localFirebaseContext.getStateAndCountyData;
    setSelectedState(event.target.value);

    _.forEach(stateAndCountyData, (v) => {
      if (v.State === event.target.value) {
        const nextListItem = (
          <option
            id={v["County Name"]}
            key={v["County Name"]}
            value={"" + v.countyFIPS + "|" + v.population}
          >
            {v["County Name"]}
          </option>
        );
        setCountiesForState((countiesForState) => [...countiesForState, nextListItem]);
      }
    });
  };

  const calculateRisk = (event) => {
    if (event.target.value === "") {
      props.changedStateAndCounty([currentStateCounty]);
      setSelectedCounty("");
    } else {
      setSelectedCounty(event.target.value);
      const FPISAndPopulation = event.target.value.split("|");
      let localProbability = 0;
      let confirmedStartDay = 0;
      let confirmedDaySeven = 0;
      if (localFirebaseContext.hasSevenDayData && localFirebaseContext.hasCovidData) {
        const dayOneData = localFirebaseContext.currentDataCovidData;
        const daySevenData = localFirebaseContext.sevenDayData;
        _.forEach(dayOneData, (value) => {
          if (FPISAndPopulation[0] == value.FIPS) {
            confirmedStartDay = value.Confirmed;
            return false;
          }
        });
        _.forEach(daySevenData, (value) => {
          if (FPISAndPopulation[0] == value.FIPS) {
            confirmedDaySeven = value.Confirmed;
            return false;
          }
        });
      }

      if (confirmedStartDay !== 0 && confirmedDaySeven !== 0) {
        const difference = confirmedStartDay - confirmedDaySeven;
        localProbability = difference / parseInt(FPISAndPopulation[1]);
      }
      if (currentStateCounty.length === undefined) {
        props.changedStateAndCounty(currentStateCounty);
      }
      const checkItem = {
        mathElement: "ST",
        value: localProbability * currentMulitplierSelected,
        id: FPISAndPopulation[0],
      };
      setCurrentStateCountyProb(localProbability);
      setCurrentStateCounty(checkItem);
      props.updateStateAndCounty(checkItem);
    }
  };

  const multiplierToStateAndCountyData = (event) => {
    setCurrentMulitplierSelected(event.target.value);
    const FPISAndPopulation = selectedCounty.split("|");

    const checkItem = {
      mathElement: "ST",
      value: currentStateCountyProb * Number(event.target.value),
      id: FPISAndPopulation[0],
    };
    setCurrentStateCounty(checkItem);
    props.updateStateAndCounty(checkItem);
  };

  return (
    <div>
      <div className="stateCounty">
        <FormControlSelectCountyStyling
          variant="outlined"
          className="FormControlSelectCountyStyling"
        >
          <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel>
          <Select native value={selectedState} onChange={selectCounty} label="State">
            <option aria-label="None" value="" />
            {listItems}
          </Select>
        </FormControlSelectCountyStyling>

        <FormControlCountiesForStateStyling
          variant="outlined"
          className="FormControlCountiesForStateStyling"
        >
          <InputLabel htmlFor="outlined-age-native-simple">County</InputLabel>
          <Select native value={selectedCounty} onChange={calculateRisk} label="County">
            <option aria-label="None" value="" />
            {countiesForState}
          </Select>
        </FormControlCountiesForStateStyling>
      </div>
      <div
        className={
          localFirebaseContext.advanced ? "multiplierPercetageBox" : "multiplierPercetageBoxBasic"
        }
        style={{ display: localFirebaseContext.advanced ? "grid" : "none" }}
      >
        <div className="multiplier ">
          <div style={{ display: localFirebaseContext.advanced ? "grid" : "none" }}>
            <div>
              <Tooltip
                sx={{ maxWidth: 75, backgroundColor: "#20494c80" }}
                arrow
                placement="left"
                title="The CDC estimates true rates of COVID are 2-10 times higher than reported, consider multiplying"
              >
                <InfoIcon color="primary" fontSize="small"></InfoIcon>
              </Tooltip>
              <p className="estimateMultiplier">Correct for undertesting of COVID-19</p>
            </div>

            <FormControl
              component="fieldset"
              style={{
                display: localFirebaseContext.advanced ? "grid" : "none",
              }}
            >
              <RadioGroup
                row
                aria-label="multiplier"
                name="multiplier1"
                value={currentMulitplierSelected}
                onChange={multiplierToStateAndCountyData}
              >
                <FormControlRadioLeftStyling
                  className="FormControlRadioLeftStyling"
                  labelPlacement="bottom"
                  value="2"
                  control={
                    <RadioStyling
                      className="RadioStyling"
                      disableRipple
                      color="default"
                      style={{ paddingBottom: 4 }}
                      checkedIcon={<RadioCheckedSpanStyling className="RadioCheckedSpanStyling" />}
                      icon={<RadioIconSpanStyling className="RadioIconSpanStyling" />}
                    />
                  }
                  label="2x"
                />
                <FormControlRadioCenterStyling
                  className="FormControlRadioCenterStyling"
                  labelPlacement="bottom"
                  value="5"
                  control={
                    <RadioStyling
                      className="RadioStyling"
                      disableRipple
                      color="default"
                      checkedIcon={<RadioCheckedSpanStyling className="RadioCheckedSpanStyling" />}
                      icon={<RadioIconSpanStyling className="RadioIconSpanStyling" />}
                    />
                  }
                  label="5x"
                />
                <FormControlRadioCenterStyling
                  className="FormControlRadioCenterStyling"
                  labelPlacement="bottom"
                  value="10"
                  control={
                    <RadioStyling
                      className="RadioStyling"
                      disableRipple
                      color="default"
                      style={{ paddingBottom: 4 }}
                      checkedIcon={<RadioCheckedSpanStyling className="RadioCheckedSpanStyling" />}
                      icon={<RadioIconSpanStyling className="RadioIconSpanStyling" />}
                    />
                  }
                  label="10x"
                />
                <FormControlRadioRightStyling
                  className="FormControlRadioRightStyling"
                  labelPlacement="bottom"
                  value="1"
                  control={
                    <RadioStyling
                      className="RadioStyling"
                      disableRipple
                      color="default"
                      style={{ paddingBottom: 4 }}
                      checkedIcon={<RadioCheckedSpanStyling className="RadioCheckedSpanStyling" />}
                      icon={<RadioIconSpanStyling className="RadioIconSpanStyling" />}
                    />
                  }
                  label="None"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StateAndCounty;

const FormControlSelectCountyStyling = styled(FormControl)(({ theme }) => ({
  marginTop: 20,
  marginLeft: 0,
  backgroundColor: "#FFFFFF",
  borderRadius: 4,
  minWidth: 195,
  [theme.breakpoints.down("lg")]: {
    minWidth: 154,
  },
  [theme.breakpoints.down("md")]: {
    minWidth: 270,
    marginLeft: 0,
  },
}));
const FormControlCountiesForStateStyling = styled(FormControl)(({ theme }) => ({
  marginTop: 20,
  backgroundColor: "#FFFFFF",
  borderRadius: 4,
  marginLeft: 10,
  minWidth: 386,
  [theme.breakpoints.down("xl")]: {
    minWidth: 344,
  },
  [theme.breakpoints.down("lg")]: {
    minWidth: 340,
  },
  [theme.breakpoints.down("md")]: {
    minWidth: 270,
    marginLeft: 0,
  },
}));
const FormControlRadioLeftStyling = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 41,
  borderRadius: 4,
  [theme.breakpoints.down("lg")]: {
    marginRight: 32,
  },
}));
const FormControlRadioCenterStyling = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 41,
  marginRight: 41,
  borderRadius: 4,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 32,
    marginRight: 32,
  },
}));
const FormControlRadioRightStyling = styled(FormControlLabel)(({ theme }) => ({
  marginRight: 0,
  marginLeft: 41,

  borderRadius: 4,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 32,
  },
}));
const RadioStyling = styled(Radio)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
}));
const RadioCheckedSpanStyling = styled("span")(({ theme }) => ({
  backgroundColor: "#20494C",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#1B97A4",
  },
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));
const RadioIconSpanStyling = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#FFFFFF",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#98B4B6",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));
